import BookOffer from "./bookOffer";
export default function GymHeader({ garden, spot, day, hour, minute, percent, wholeDate }) {
  return (
    <>
      <div className="div">
        <div className="div-gym-2">
          <div className="div-center">
            <a href="#" className="div-gym-3">{percent || 35}  % OFF</a>

          </div>
          <span className="div-gym-4"> &nbsp;ON OUR GYM MEMBERSHIP</span>
        </div>
        <div className="div-gym-5">
          <div className="div-gym-6">
            <a href="#" className="limited-time-offer">LIMITED TIME OFFER</a>
            <span className="div-gym-7"> !!</span>
          </div>
        </div>
        <div className="divImage">
          <img
            loading="lazy"
            src={garden}
            className="img-gym"
          />
        </div>
        <BookOffer spot={spot} day={day} hour={hour} minute={minute} percent={percent} wholeDate={wholeDate} buttonString={'BOOK YOUR SPOT IN THIS CRAZY OFFER'} />
      </div >
      <style jsx>{`
        .div {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .divImage {
          align-self: center;
          display: flex;
          justify-content: center; /* Center items horizontally */
          align-items: center; /* Center items vertically */
          margin-top: 50px;
        }
        @media (max-width: 991px) {
          .divImage {
            height: auto; 
            margin: 15px;
          }
        }
        .div-gym-2 {
          align-self: center;
          display: flex;
          margin-top: 30px;
          max-width: 1100px;
        }
        
        @media (max-width: 991px) {
          .div-center {
           min-width:100%;
           text-align: center;
          }
          .div-gym-2 {
            max-width: 100%;
            align-items: center;
            flex-wrap: wrap;
          }
        }
        
        .div-gym-3 {
          color: #15c;
          justify-content: center;
          text-decoration: none;
          text-align: center;
          font: 700 47px/62px Inter, -apple-system, Roboto, Helvetica, sans-serif;
        }
        .div-gym-4 {
          justify-content: center;
          text-decoration: none;
          text-align: center;
          font: 700 47px/62px Inter, -apple-system, Roboto, Helvetica, sans-serif;
        }
        
        .div-gym-3:hover {
          border-bottom: 5px solid #15c;
        }
        
        @media (max-width: 991px) {
          .div-gym-3,
          .div-gym-4 {
            max-width: 100%;
            font-size: 40px;
            line-height: 59px;
          }
        }
        
        .div-gym-5 {
          align-self: center;
          margin-top: 12px;
          width: 600px;
          max-width: 100%;
          gap: 17px;
        }
        @media (max-width: 991px) {
          .div-gym-5 {
            flex-wrap: wrap;
          }
        }
        .div-gym-6 {
          justify-content: center;
          color: #15c;
          text-align: center;
          text-decoration: none;
          font: 700 47px/62px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gym-6 {
            max-width: 100%;
            font-size: 40px;
            line-height: 59px;
          }
        }
        // .div-gym-6:hover {
        //     border-bottom: 5px solid #15c;
        // }
        .limited-time-offer {
          justify-content: center;
          color: #15c;
          text-align: center;
          text-decoration: none;
          font: 700 47px/62px Inter, -apple-system, Roboto, Helvetica, sans-serif;
        }
        
        @media (max-width: 991px) {
          .limited-time-offer {
            max-width: 100%;
            font-size: 40px;
            line-height: 59px;
          }
        }
        
        .limited-time-offer:hover {
          border-bottom: 5px solid #15c;
        }
        
        .div-gym-7 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          font: 700 48px/62px Inter, -apple-system, Roboto, Helvetica, sans-serif;
        }
        
        @media (max-width: 991px) {
          .div-gym-7 {
            font-size: 40px;
            line-height: 58px;
          }
        }
        
        .img-gym {
          object-fit: contain;
          object-position: center;
          width: 60%;
          max-width: 100%;
          margin: 0; /* Adjust margin to center vertically */
        }
        
        @media (max-width: 991px) {
          .img-gym {
            width: 100%;
            margin: 40px 0; /* Adjust margin for smaller screens */
          }
        }
      `}</style>
    </>
  );
}


