import Form from './form';
const CustomButton = ({ onClick }) => (
    <button
        onClick={onClick}
        className="justify-center text-white text-center text-xl font-bold leading-7 whitespace-nowrap items-center border shadow-sm bg-orange-500 self-center w-56 max-w-full  px-4 py-3 rounded border-solid border-black border-opacity-20 no-underline"
    >
        START FREE TRIAL
    </button>
);
export default function Community({ type, img1, img2, img3, img4 }) {
    return (
        <div className="justify-center items-center bg-gray-200 self-stretch flex w-full flex-col -mr-5 pt-12 pb-10 max-md:max-w-full">
            <div className="flex w-[745px] max-w-[95%] flex-col">
                <h2 className="justify-center text-orange-500 text-center text-3xl-9  leading-10 self-center whitespace-nowrap">
                    COMMUNITY IN A GYM
                </h2>
                <div className="self-stretch mt-6 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[51%] max-md:w-full max-md:ml-0">
                            <img
                                loading="lazy"
                                src={img1}
                                className="aspect-[1.6] object-contain object-center w-full overflow-hidden grow max-md:mt-10"
                            />
                        </div>
                        <div className="flex flex-col items-stretch w-[49%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex flex-col mt-2.5 max-md:mt-10">
                                <div className="justify-center text-zinc-800 text-center text-base leading-5 self-stretch">
                                    You will feel that you belong from the 1st day.
                                </div>
                                <div className="justify-center text-zinc-800 text-center text-base leading-5 self-stretch  mt-1.5">
                                    Each group consists of 10-12 athletes led by
                                </div>
                                <div className="justify-center text-zinc-800 text-center text-base leading-5 self-center  mt-2">
                                    AOS coach; bringing the motivation and
                                </div>
                                <div className="justify-center text-zinc-800 text-center text-base leading-5 self-stretch  mt-2">
                                    positive vibes of group session inside the gym
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className="justify-center text-orange-500 text-center text-3xl-9 font-bold leading-10 self-center  mt-9">
                    NEVER GET BORED
                </h2>
                <div className="self-stretch mt-6 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[51%] max-md:w-full max-md:ml-0">
                            <img
                                loading="lazy"
                                src={img2}
                                className="aspect-[1.54] object-contain object-center w-full overflow-hidden grow max-md:mt-10"
                            />
                        </div>
                        <div className="flex flex-col items-stretch w-[49%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex flex-col items-stretch mt-1.5 max-md:mt-10">
                                <div className="justify-center text-zinc-800 text-center text-base leading-5 ">
                                    Our training program is updated every month.
                                </div>
                                <div className="justify-center text-zinc-800 text-center text-base leading-5  mt-2">
                                    You get to train different program to achieve
                                </div>
                                <div className="justify-center text-zinc-800 text-center text-base leading-5  mt-2">
                                    progress and make sure you never plateaux
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className="justify-center text-orange-500 text-center text-3xl-9 font-bold leading-10 self-center  mt-9">
                    TIME EFFICIENCY
                </h2>
                <div className="self-stretch mt-6 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[51%] max-md:w-full max-md:ml-0">
                            <img
                                loading="lazy"
                                src={img3}
                                className="object-contain object-center w-full overflow-hidden grow max-md:mt-10"
                            />
                        </div>
                        <div className="flex flex-col items-stretch w-[49%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex flex-col mt-1.5 max-md:mt-10">
                                <div className="justify-center text-zinc-800 text-center text-base leading-5 self-stretch ">
                                    Who said that you need to train for 2 hours to
                                </div>
                                <div className="justify-center text-zinc-800 text-center text-base leading-5 self-stretch mt-2">
                                    see results? All you need is 1 hour to reap the
                                </div>
                                <div className="justify-center text-zinc-800 text-center text-base leading-5 self-stretch mt-2">
                                    maximum results. All workouts are timed and
                                </div>
                                <div className="justify-center text-zinc-800 text-center text-base leading-5 self-center  mt-3">
                                    extremely structured
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className="justify-center text-orange-500 text-center text-3xl-9 font-bold leading-10 self-center  mt-9">
                    PROGRESS ASSESSMENT
                </h2>
                <div className="self-stretch mt-6 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[51%] max-md:w-full max-md:ml-0">
                            <img
                                loading="lazy"
                                src={img4}
                                className="aspect-[1.61] object-contain object-center w-full overflow-hidden grow max-md:mt-10"
                            />
                        </div>
                        <div className="flex flex-col items-stretch w-[49%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex flex-col items-stretch mt-1.5 max-md:mt-10">
                                <div className="justify-center text-zinc-800 text-center text-base leading-5 ">
                                    Measuring your progress is an essential part of
                                </div>
                                <div className="justify-center text-zinc-800 text-center text-base leading-5 self-center whitespace-nowrap mt-2">
                                    setting an effective goal
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex w-[581px] max-w-full pl-0 flex-col items-stretch mt-16 pr-px self-end ">
                    <h2 className="justify-center text-orange-500 text-center text-3xl-9 font-bold leading-10 self-center whitespace-nowrap mt-9">
                        VALUE FOR MONEY
                    </h2>
                    <div className=" justify-between gap-5 mb-10 max-md:max-w-full max-md:flex-wrap max-md:mt-10 md:flex">
                        <div className="justify-center text-orange-500 text-center text-27xl-8 font-bold leading-[94px]object-contain object-center w-full overflow-hidden grow max-md:mt-10 md:text-left">
                            $
                        </div>
                        <div className="self-stretch flex grow basis-[0%] flex-col items-stretch">
                            <div className="justify-center text-zinc-800 text-center text-xl font-bold leading-8 ">
                                You get to train up to 5 days per
                            </div>
                            <div className="self-center flex w-[289px] max-w-full items-stretch gap-2 max-md:justify-center">
                                <div className="justify-center text-zinc-800 text-center text-xl font-bold leading-8 flex-1">
                                    week with
                                </div>
                                <div className="justify-center text-orange-500 text-center text-xl font-bold leading-8 self-start">
                                    70% less
                                </div>
                                <div className="justify-center text-zinc-800 text-center text-xl font-bold leading-8 ">
                                    than any
                                </div>
                            </div>
                            <div className="justify-center text-zinc-800 text-center text-xl font-bold leading-8 self-center whitespace-nowrap">
                                GYM’s Private membership
                            </div>
                        </div>
                    </div>
                </div>
                <Form type={type} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
            </div>
        </div>
    );
}


