
import Header from "../components/header2";
import Confused from "../components/confused";
import CrazyOffer from "../components/crazyOffer4";
import ConceptHeader from "../components/conceptHeader";
import BookOffer3 from "../components/bookOffer3";
import { FAQSection3 } from "../components/faq"
import TheyDid from "../components/theydid";
import Say from "../components/say3";
import WhyShred from "../components/whyshred";
import WhyShred2 from "../components/whyShred2";
import Shred from "../components/shred3"
import React, { useState, useEffect } from 'react';
const Shred30 = () => {
  const color = 'red';
  const [imageGardenUrl, setImageGardenUrl] = useState('');
  const [imageYoussefUrl, setImageYoussefUrl] = useState('');
  const [imageGymUrl, setImageGymUrl] = useState('');
  const [imageAssesmentUrl, setImageAssesmentUrl] = useState('');
  const [imageJumpUrl, setImageJumpUrl] = useState('');
  const [imageAllPeopleUrl, setImageAllPeopleUrl] = useState('');
  const [imageGym1Url, setImageGym1Url] = useState('');
  const [imageReviews1Url, setImageReviews1Url] = useState('');
  const [imageCrazyOffer1Url, setImageCrazyOffer1Url] = useState('');
  const [enteredSpot, setEnteredSpot] = useState(0);
  const [gymDay, setGymDay] = useState(0);
  const [gymHours, setGymHours] = useState(0);
  const [gymMinutes, setGymMinutes] = useState(0);
  const [wholeDate, setWholeDate] = useState(null);
  const [youtubeUrl1, setYoutubeUrl1] = useState('');
  const [youtubeUrl2, setYoutubeUrl2] = useState('');
  const [youtubeUrl3, setYoutubeUrl3] = useState('');
  const [youtubeUrl4, setYoutubeUrl4] = useState('');
  const [youtubeUrl5, setYoutubeUrl5] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl1 = `https://www.aos.fit/api/getalldata`;
        let allData = await fetch(apiUrl1);
        const allDataResult = await allData.json();
        setEnteredSpot(allDataResult['shredSpot'])
        setImageYoussefUrl(`https://www.aos.fit/api/public/${allDataResult['shred5']}`)
        setImageGardenUrl(`https://www.aos.fit/api/public/${allDataResult['youssef']}`)
        setImageGymUrl(`https://www.aos.fit/api/public/${allDataResult['shred6']}`)
        setImageAssesmentUrl(`https://www.aos.fit/api/public/${allDataResult['shred3']}`)
        setImageAllPeopleUrl(`https://www.aos.fit/api/public/${allDataResult['shred1']}`)
        setImageGym1Url(`https://www.aos.fit/api/public/${allDataResult['shred4']}`)
        setImageJumpUrl(`https://www.aos.fit/api/public/${allDataResult['shred2']}`)
        setImageCrazyOffer1Url(`https://www.aos.fit/api/public/${allDataResult['shredcrazyOffer1']}`)
        setImageReviews1Url(`https://www.aos.fit/api/public/${allDataResult['shredreviews1']}`)
        setYoutubeUrl1(`${allDataResult['shredyoutubeUrl1']}`)
        setYoutubeUrl2(`${allDataResult['shredyoutubeUrl2']}`)
        setYoutubeUrl3(`${allDataResult['shredyoutubeUrl3']}`)
        setYoutubeUrl4(`${allDataResult['shredyoutubeUrl4']}`)
        setYoutubeUrl5(`${allDataResult['shredyoutubeUrl5']}`)
        setGymDay(allDataResult['shredDay'])
        setGymHours(allDataResult['shredHour'])
        setGymMinutes(allDataResult['shredMinute'])
        setWholeDate(allDataResult['shredTimestamp'])

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="div-c">
        <Header />
        <ConceptHeader type={'shred30'} color='rgb(243, 20, 0);' />
        <div className="videogroup">
          <iframe src={youtubeUrl5} frameBorder="0" allowFullScreen="" wmode="opaque" id="fitvid146605"></iframe>
          <BookOffer3 type={'shred30'} buttonString='true' spot={enteredSpot} wholeDate={wholeDate} />
        </div>
        <WhyShred />
        <WhyShred2 />
        <Shred type={'shred30'} />
        <TheyDid buttonString='true' type={'shred30'} img1={imageAllPeopleUrl} img2={imageJumpUrl} img3={imageAssesmentUrl} img4={imageGym1Url} img5={imageYoussefUrl} img6={imageGymUrl} />
        <Say video1={youtubeUrl1} video2={youtubeUrl2} video3={youtubeUrl3} video4={youtubeUrl4} review={imageReviews1Url} />
        <FAQSection3 />
        <CrazyOffer img1={imageCrazyOffer1Url} type={'shred30'} />
        <div className="bg-[url('./images/backgroundConcept.jpg')]  grid place-items-center py-4">
          <BookOffer3 type={'shred30'} spot={enteredSpot} wholeDate={wholeDate} />
          <br />
          <br />
          <br />
        </div>
        <Confused color={color} type={'shred30'} />
      </div >
      <style jsx>{`
        .div-c {
          display: flex;
          flex-direction: column;
        }
        .videogroup {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // height: 100vh; 
            margin-bottom: 20px;
            margin-top: 20px;
          }
          
          .videogroup iframe {
            width: 500px;
            height: 300px;
          }
          @media only screen and (max-width: 767px) {
            .videogroup iframe {
              width: 90%; 
              height: 300px; /* Preserve the aspect ratio */
            }
          }
      `}</style>
    </>
  );
};

export default Shred30;
