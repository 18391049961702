
import Header from "../components/header2";
import Form from '../components/form';
const CustomButton = ({ onClick, buttonString }) => (
    <button onClick={onClick} className="justify-center text-white text-center text-lg font-bold leading-7 uppercase whitespace-nowrap items-center border shadow-sm bg-blue-800 self-center w-[168px] max-w-full mt-6 px-2 py-2  hover:cursor-pointer rounded border-solid border-black border-opacity-20">
        contact us
    </button>

);
export default function ContactForm(props) {
    return (
        <div className="flex flex-col items-stretch ">
            <Header />
            <div className="justify-center text-zinc-800 text-center text-11xl-4 font-bold leading-10 self-center max-w-[670px] mt-8 max-md:max-w-full">
                Hi, Please fill this form and our <br /> Experts will get back to you
                in less than a day :)
            </div>
            <Form type='Home' buttonComponent={(toggleModal) => <CustomButton buttonString="BOOK your 35 % OFFER" onClick={toggleModal} />} />
        </div>
    );
}
