import './header2.css'
export default function Header2(props) {
  return (
    <div className="div-header2">
      <div className="div-header2-2">

        <img
          loading="lazy"
          src="./logo.png"
          className="img-header2"
        />

        <div className="div-header2-3">
          <a href="/" style={{ margin: '25px', textDecoration: 'none', color: 'white', font: '11px', fontSize: '11px', }}>
            PROGRAMS
          </a>
          <a href="/contact-us1" style={{ textDecoration: 'none', color: 'white', fontSize: '11px', }}>
            CONTACT US
          </a>
        </div>
      </div>
    </div>
  );
}