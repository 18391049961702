import Form from './form';
const CustomButton = ({ onClick }) => (
    <button
        onClick={onClick}
        className="justify-center text-white text-center text-xl font-bold leading-7 whitespace-nowrap items-center border shadow-sm bg-orange-500 self-center w-56 max-w-full  px-4 py-3 rounded border-solid border-black border-opacity-20 no-underline"
    >
        START FREE TRIAL
    </button>
);
export default function Say({ type, youtubeUrl2 }) {
    return (
        <section className="justify-center items-center bg-gray-200 self-stretch flex w-full flex-col -mr-5 pt-12 pb-10 max-md:max-w-full">
            <div className="flex w-[857px] max-w-[95%] flex-col items-stretch">
                <h1 className="justify-center text-black text-center text-14xl-3 font-bold max-md:max-w-full">
                    WHAT OUR ATHLETES HAVE TO SAY ABOUT US
                </h1>

                <div className="justify-center items-stretch bg-black self-center flex w-[550px] max-w-full flex-col mb-6 mt-4 max-md:mt-10">
                    <div className="bg-black flex flex-col items-stretch max-md:max-w-full">
                        <iframe
                            src={youtubeUrl2}
                            allowFullScreen=""
                            wmode="opaque"
                            id="fitvid334380"
                            style={{ width: "100%", height: "350px" }} // Adjust the height as needed
                        />
                    </div>
                </div>
                <Form type={type} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
            </div>
        </section>
    );

}