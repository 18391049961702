import React, { useState } from 'react';
import './confused.css'
import Form from './form';
const CustomButton = ({ onClick, color }) => (
  < button onClick={onClick}  className="button-pre" > BOOK YOUR FREE CONSULTATION</button >
);

export default function Confused({ color, type }) {
  return (
    <>
      <div className="divpre-109">
        <div className="divpre-110">
          <br />
          <a href="#" className="divpre-111">STILL CONFUSED ?</a>
          <a href="#" className="divpre-112">LET AOS TEAM HELP YOU</a>
          <a href="#" className="divpre-113">
            Claim Your FREE No-Obligation 30-Minute Consultation
          </a>
          <div className="divpre-114">
            During this no-obligation call, our experts will discuss your
            specific situation and
          </div>
          <div className="divpre-115">
            how we can help you achieve desired Goal in the shortest time
            possible.
          </div>
          <br />

          <Form type={type} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} color={color} />} />
          <br />
        </div>
      </div>
      <style jsx>{`
      .divpre-109 {
    justify-content: center;
    align-items: center;
    background-color: rgb(166,166,166);
    align-self: stretch;
    display: flex;
    width: 100%;
    flex-direction: column;
}

@media (max-width: 991px) {
    .divpre-109 {
        max-width: 100%;
    }
}

.divpre-110 {
    display: flex;
    width: 580px;
    max-width: 100%;
    flex-direction: column;
}

.divpre-111 {
    justify-content: center;
    color: rgb(82, 139, 161);
    text-align: center;
    align-self: center;
    white-space: nowrap;
    font: 700 30px/140% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
    text-decoration: none;
    transition: border-color 0.3s;
    border-bottom: 1px solid transparent;
}

.divpre-111:hover {
    border-bottom: 3px solid  rgb(82, 139, 161);
}

@media (max-width: 991px) {
    .divpre-111 {
        white-space: initial;
    }
}

.divpre-112 {
    justify-content: center;
    color: #fff;
    text-align: center;
    align-self: center;
    margin-top: 40px;
    white-space: nowrap;
    font: 500 22px Inter, sans-serif;
    text-decoration: none;
    transition: border-color 0.3s;
    border-bottom: 1px solid transparent;
}

.divpre-112:hover {
    border-bottom: 2px solid rgb(255, 255, 255);
}

@media (max-width: 991px) {
    .divpre-112 {
        margin-top: 40px;
        white-space: initial;
    }
}

.divpre-113 {
    justify-content: center;
    color: #fff;
    text-align: center;
    align-self: stretch;
    white-space: nowrap;
    font: 500 22px Inter, sans-serif;
    text-decoration: none;
    transition: border-color 0.3s;
    border-bottom: 1px solid transparent;
}

@media (max-width: 991px) {
    .divpre-113 {
        max-width: 100%;
        white-space: initial;
    }
}

.divpre-113:hover {
    border-bottom: 2px solid rgb(255, 255, 255);
}

.divpre-114 {
    justify-content: center;
    color: #fff;
    text-align: center;
    align-self: stretch;
    margin-top: 42px;
    white-space: nowrap;
    font: 400 15px/149% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divpre-114 {
        max-width: 100%;
        margin-top: 40px;
        white-space: initial;
    }
}

.divpre-115 {
    justify-content: center;
    color: #fff;
    text-align: center;
    align-self: center;
    white-space: nowrap;
    font: 400 15px/150% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divpre-115 {
        max-width: 100%;
        white-space: initial;
    }
}

.divpre-116 {
    justify-content: center;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    align-items: center;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
    background-color: #00e;
    align-self: center;
    margin-top: 12px;
    width: 366px;
    max-width: 100%;
    padding: 19px 0px;
    font: 700 19px/154% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

        .button-pre  {
          cursor: pointer;
          justify-content: center;
          color: #fff;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          border-radius: 3px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
          background-color: rgb(82, 139, 161);
          align-self: center;
          margin-top: 26px;
          max-width: 100%;
          padding: 10px 20px;
          font: 700 17px/29px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .button-pre  {
            white-space: initial;
          }
        }
@media (max-width: 991px) {
    .divpre-116 {
        white-space: initial;
    }
}` }</style>
    </>
  );
}