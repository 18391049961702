import React, { useState } from 'react';

export default function Component() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const toggleAccordion2 = () => {
    setIsOpen2(!isOpen2);
  };
  const toggleAccordion3 = () => {
    setIsOpen3(!isOpen3);
  };
  const toggleAccordion4 = () => {
    setIsOpen4(!isOpen4);
  };
  const toggleAccordion5 = () => {
    setIsOpen5(!isOpen5);
  };
  const toggleAccordion6 = () => {
    setIsOpen6(!isOpen6);
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center fade-in">
            <div className="h-h2">YOU'VE GOT QUESTIONS,</div>
            <div className="h-h2">WE'VE GOT ANSWERS.</div>
          </div>

          <div className="col-lg-10">
            <dl className="accordions">
              <div className={`accordion ${isOpen ? 'accordion__active' : ''}`}>
                <dt className="accordion__intro" onClick={toggleAccordion}>
                  <h1>Is the Coach Certified?</h1>
                  <span>{isOpen ? '-' : '+'}</span>
                </dt>
                <dd className="accordion__content" style={{ opacity: isOpen ? 1 : 0, maxHeight: isOpen ? '282px' : '0px' }}>
                  <div className="accordion__text">
                    <p className="p-p3">
                      Yes, Youssef Salem is certified with several prestigious certificates, such as the National Academy
                      of Sports Medicine certificate (NASM), CF L1 Certificate, Weightlifting Certificate, and many others.
                    </p>
                  </div>
                </dd>
              </div>
            </dl>
          </div>

          <div className="col-lg-10">
            <dl className="accordions">
              <div className={`accordion ${isOpen6 ? 'accordion__active' : ''}`}>
                <dt className="accordion__intro" onClick={toggleAccordion6}>
                  <h1>How much weight am I going to lose?</h1>
                  <span>{isOpen6 ? '-' : '+'}</span>
                </dt>
                <dd className="accordion__content" style={{ opacity: isOpen6 ? 1 : 0, maxHeight: isOpen6 ? '282px' : '0px' }}>
                  <div className="accordion__text">
                    <p className="p-p3">
                      It really differs from person to person. You will be able to answer this question accurately after
                      watching the nutrition seminar. But to ease your mind, you will see a significant difference when
                      you join the program. We guarantee it.
                    </p>
                  </div>
                </dd>
              </div>
            </dl>
          </div>

          <div className="col-lg-10">
            <dl className="accordions">
              <div className={`accordion ${isOpen2 ? 'accordion__active' : ''}`}>
                <dt className="accordion__intro" onClick={toggleAccordion2}>
                  <h1>Why is this program different??</h1>
                  <span>{isOpen2 ? '-' : '+'}</span>
                </dt>
                <dd className="accordion__content" style={{ opacity: isOpen2 ? 1 : 0, maxHeight: isOpen2 ? '282px' : '0px' }}>
                  <div className="accordion__text">
                    <p className="p-p3">
                    Because we educate you. It is designed to be a course that helps you understand the science behind getting fit, toned, and even shredded. It is not only a workout and nutrition program. Trust us, the effort put into creating the educational content was far greater than that required for the training program itself.
                    </p>
                  </div>
                </dd>
              </div>
            </dl>
          </div>

          <div className="col-lg-10">
            <dl className="accordions">
              <div className={`accordion ${isOpen2 ? 'accordion__active' : ''}`}>
                <dt className="accordion__intro" onClick={toggleAccordion3}>
                  <h1> What equipment is needed?</h1>
                  <span>{isOpen3 ? '-' : '+'}</span>
                </dt>
                <dd className="accordion__content" style={{ opacity: isOpen3 ? 1 : 0, maxHeight: isOpen3 ? '282px' : '0px' }}>
                  <div className="accordion__text">
                    <p className="p-p3">
                    It is better to be subscribed to a gym. However, if you have access to a squat rack, bar, plates, dumbbells, and a bench, you will be able to perform the program. If you don’t have any equipment at all we will give you access to our bodyweight program.
                    </p>
                  </div>
                </dd>
              </div>
            </dl>
          </div>

          <div className="col-lg-10">
            <dl className="accordions">
              <div className={`accordion ${isOpen4 ? 'accordion__active' : ''}`}>
                <dt className="accordion__intro" onClick={toggleAccordion4}>
                  <h1> What if I can’t do a certain workout?</h1>
                  <span>{isOpen4 ? '-' : '+'}</span>
                </dt>
                <dd className="accordion__content" style={{ opacity: isOpen4 ? 1 : 0, maxHeight: isOpen4 ? '282px' : '0px' }}>
                  <div className="accordion__text">
                    <p className="p-p3">
                    Don’t worry, each workout has an alternative. If you can’t do the alternative, just let us know, and we will provide you with another option. we will be on what’s app with you every step in the program
                    </p>
                  </div>
                </dd>
              </div>
            </dl>
          </div>

          <div className="col-lg-10">
            <dl className="accordions">
              <div className={`accordion ${isOpen5 ? 'accordion__active' : ''}`}>
                <dt className="accordion__intro" onClick={toggleAccordion5}>
                  <h1> What if I want to continue after the program?</h1>
                  <span>{isOpen5 ? '-' : '+'}</span>
                </dt>
                <dd className="accordion__content" style={{ opacity: isOpen5 ? 1 : 0, maxHeight: isOpen5 ? '282px' : '0px' }}>
                  <div className="accordion__text">
                    <p className="p-p3">
                    You will be able to continue training with us after the program ends.
                    </p>
                  </div>
                </dd>
              </div>
            </dl>
          </div>


          
        </div>
      </div>

      <style jsx>{`
        .container {
          box-sizing: border-box;
          width: 100%;
          padding: 80px 15px 0;
          position: relative;
          z-index: 1;
        }
        .row {
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin: 0 -15px;
        }
        .col-12 {
          box-sizing: border-box;
          position: relative;
          width: 100%;
          padding: 0 15px;
          max-width: 100%;
          text-align: center;
          animation: fade-in 1s ease;
        }
        .h-h2 {
          box-sizing: border-box;
          line-height: 0.94;
          font-family: 'Futura LT Condensed', sans-serif;
          font-weight: 400;
          text-transform: uppercase;
          margin: 20px 0 3rem;
        }
        .col-lg-10 {
          box-sizing: border-box;
          position: relative;
          width: 100%;
          padding: 0 15px;
          flex: 0 0 83.333%;
          max-width: 83.333%;
        }
        .accordions {
          box-sizing: border-box;
          margin: 0 0 1rem;
        }
        .accordion {
          box-sizing: border-box;
          overflow: hidden;
          width: 100%;
          margin-top: 19px;
        }
        .accordion__intro {
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
          background-color: rgb(242, 242, 242);
          font-weight: 600;
          padding: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .accordion__content {
          box-sizing: border-box;
          margin-bottom: 0.5rem;
          margin-left: 0;
          overflow: hidden;
          transition: all 0.4s ease-out;
          will-change: max-height;
          color: rgb(0, 0, 0);
          font-size: 1.8rem;
          padding: 20px;
        }
        .accordion__text {
          box-sizing: border-box;
          margin-bottom: 50px;
        }
        .p-p3 {
          box-sizing: border-box;
          margin: 0 0 4.3rem;
        }

        @media (max-width: 768px) {
          .h-h2 {
            font-size: 2rem;
          }
          .p-p3 {
            font-size: 1.5rem;
          }
          h1 {
            font-size: 1.5rem;
          }
        .container {
          padding: 0px 15px 0;
          position: relative;
        }
        }
        @media (min-width: 768px) {
          h1 {
            font-size: 2.5rem;
          }
          .h-h2 {
            font-size: 4rem;
          }
          .p-p3 {
            font-size: 2rem;
          }
        }
      `}</style>
    </>
  );
}
