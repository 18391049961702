import React from "react";
import Form from '../form';
const CustomButton2 = ({ onClick, buttonString }) => (
    <button className='fut-button95' onClick={onClick}>{buttonString}</button>
);

export default function Component({ buttonString, spot, type, wholeDate }) {
  return (
    <>
      <div className="helptext">
        <p className="familyf98 text-margin">
          <br/>
          I Was Once In Your Shoes,
          <br/>
          And I Simply Want To Help
        </p>

        <p className="center-text bold-text text-large">
          <strong>Dear Frustrated Friend,</strong>
        </p>

        <p className="center-text text-margin text-large text-width">
          If you've ever wanted to lose weight and get fit without struggling with restrictive diets and workout injuries, then 
          <strong> this will be the most important message you'll ever read.</strong>
        </p>

        <p className="center-text bold-text text-margin text-width text-large">
          I used to train for 3 to 4 hours a day, starving myself, and trying every trend that came along, like Keto and intermittent fasting. I was exhausting my body with so many misconceptions. Sometimes, I would lose weight only to gain it all back.
        </p>

        <p className="center-text bold-text text-margin text-width text-large">
          I thought that the more you train and the stricter you are with your diet, the better the results you will achieve. But I realized that this is far from the truth. You can achieve much faster results with 50% less effort than you think.
        </p>
      </div>

      <style jsx>{`
        .helptext {
          width: 100%;
          background-color: rgb(240, 240, 240);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        .fut-button95 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 350px;
          height: 100px;
          margin-top: 28px;
          margin-bottom: 50px;
          background-color: rgb(47, 110, 186);
          color: white;
          font-family: "Futura LT Condensed", sans-serif;
          font-weight: 900;
          font-size: 3rem;
          border: none;
          cursor: pointer;
        }

        .text-margin {
          margin-bottom: 3rem;
        }

        .center-text {
          text-align: center;
        }

        .bold-text {
          font-weight: 600;
        }

        .text-large {
          font-size: 2rem;
        }

        .text-width {
          width: 45%;
          max-width: 1000px;
        }

        .familyf98 {
          font-size: 4rem;
          color: rgb(47,110,186);
          font-weight: 900;
          font-family: "Futura LT Condensed", sans-serif;
          text-transform: uppercase;
          line-height: 0.94;
        }

        @media (max-width: 768px) {
          .fut-button95 {
            width: 100%;
            height: 60px;
            font-size: 1rem;
          }

          .text-large {
            font-size: 1.4rem;
          }
          .text-margin {
          margin-bottom: 2rem;
          }

          .text-width {
          width: 80%;
          max-width: 1000px;
        }

          .familyf98 {
            font-size: 1.9rem;
            margin-bottom: 2rem;\
            width: 95%;
          }
        }
      `}</style>
    </>
  );
}
