import * as React from "react";

export default function ConceptHeader({ color }) {
  if (!color) {
    color = 'rgba(255, 0, 203, 0.85)';
  }
  console.log(color, 'where are here');
  return (
    <>
      <div>
        <div className="divTitle">
          <div className="container divTitle">
            <span style={{ color: "rgba(45,45,45,1);" }}>LOSE </span>
            <a
              href="#"
              className="atag"
            >
              MORE THAN 10% OF
            </a>
          </div>
        </div>
        <div className="divTitle">
          <div className="container divTitle">
            <span style={{ color: "rgba(45,45,45,1);" }}>YOUR BODYWEIGHT </span>
            <a
              href="#"
              className="atag"
            >
              {color === 'rgb(255, 110, 0);' ? 'IN RAMADAN ' : 'IN JUST 4 WEEKS'}
            </a>
          </div>
        </div>
      </div >

      <div className="divConcept1">
        <div className="container divConcept1">
          AND YOU KNOW WHAT !
        </div>
      </div>
      <div className="divConcept">
        <div className="container divConcept">
          <div className="divConcept2">IF YOU DO IT, YOU GET YOUR MONEY BACK!</div>
          <div className="divConcept3">
            YES THIS CRAZY LIMITED OFFER IS TOO GOOD TO BE TRUE
          </div>
        </div>
        <br />
      </div>
      <style jsx>{`
        .divTitle {
            text-align: center;
            align-self: center;
            margin-top: 24px;
            font: 500 33px/44px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
            font-weight: bold;
        }
        @media (max-width: 991px) {
            .divTitle {
                max-width: 100%;
            }
        }
        .atag {
            color: ${color};
            text-decoration: none;
        }
        .atag:hover {
                border-bottom: 4px solid  ${color};
        }

        .divConcept {
          align-items: center;
          background-color: var(--www-aos-fit-mine-shaft, #2d2d2d);
          display: flex;
          width: 100%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .divConcept {
            max-width: 100%;
            padding: 0 0px;
          }
        }
        .divConcept1 {
            color: var(--www-aos-fit-nero, #fff);
            text-align: center;
            white-space: nowrap;
            align-items: center;
            // align-self: stretch;
            background-color:  ${color};
            margin-top: 15px;
            width: 100%;
            font: 700 29.5px/42px Inter, -apple-system, Roboto, Helvetica,
              sans-serif;
          }
          @media (max-width: 991px) {
            .divConcept1 {
              white-space: initial;
              max-width: 100%;
            }
          }
        .divConcept2 {
          color: var(--www-aos-fit-nero, #fff);
          text-align: center;
          white-space: nowrap;
          align-items: center;
          background-color: var(--www-aos-fit-mine-shaft, #2d2d2d);
          width: 1170px;
          max-width: 100%;
          margin-top: 25px;
          padding: 0px 0px;
          font: 500 26px/36px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .divConcept2 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .divConcept3 {
          color: ${color};
          text-align: center;
          white-space: nowrap;
          margin-top: 20px;
          font: 700 18px/29px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .divConcept3 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .container {
          width: 100%;
          margin: 0 auto;
        }
        
        @media only screen and (max-width: 767px) {
          .container {
            width: 95%;
          }
        }
      `}</style>
    </>
  );
}


