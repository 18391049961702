import React from "react";
import Form from "../form";
import { useState, useEffect } from 'react';
const CustomButton2 = ({ onClick, buttonString }) => (
  <button className="fut-button23" onClick={onClick}>
    {buttonString}
  </button>
);

export default function Component({ image1, image2, image3, image4, type, wholeDate }) {

  const array1 = [image1, image2, image3, image4]
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [wholeDates, setWholeDates] = useState(0);
  useEffect(() => {
    console.log(wholeDate, 'whole date222222')
    const currentDate = new Date();
    const differenceInMilliseconds = wholeDate - currentDate;
    let day = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    let hour = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minute = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    day = Math.max(day, 0);
    hour = Math.max(hour, 0);
    minute = Math.max(minute, 0);
    console.log(day, hour, minute)
    setDays(day)
    setHours(hour)
    setMinutes(minute)
    setWholeDates(wholeDate)
  }, [wholeDate]);
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setMinutes(prevMinutes => {
        if (prevMinutes > 0) {
          return prevMinutes - 1;
        } else {
          setHours(prevHours => {
            if (prevHours > 0) {
              setMinutes(59);
              return prevHours - 1;
            } else {
              setDays(prevDays => {
                if (prevDays > 0) {
                  setHours(23);
                  setMinutes(59);
                  return prevDays - 1;
                } else {
                  clearInterval(timerInterval);
                  return 0;
                }
              });
            }
          });
        }
      });
    }, 60000);

    return () => clearInterval(timerInterval);
  }, [days, hours, minutes]);
  return (
    <>
    
      <div> 
        <br/>
        <div className="timer1">
        <span>{days >= 10 ? days : `0${days || 0}`}</span> : <span>{hours >= 10 ? hours : `0${hours || 0}`}</span> : <span>{minutes >= 10 ? minutes : `0${minutes || 0}`}</span>
        </div>

        <div className="center-content">
          <Form
            type={type}
            buttonComponent={(toggleModal) => (
              <CustomButton2 buttonString="BOOK YOUR SPOT" onClick={toggleModal} />
            )}
          />
        </div>
      </div>

      <div className="helptext96">
        <div className="helptext2">
          <p className="familyf123 subheading">ONCE YOU REGISTER</p>
          {[
            "1- Our team will call you and brief you on the program.",
            "within 24 hours.",
            "2- You will be added to the Challenge WhatsApp group",
            "3- Receive our life-changing Nutrition Seminar",
            "4- Receive the Program Calendar",
          ].map((text, index) => (
            <p key={index} className="goal-text">
              <strong>{text}</strong>
            </p>
          ))}
        </div>
      </div>

      <div>
        <br/>
        <p className="familyf123">IF THEY COULD DO IT, SO CAN YOU</p>
          <br/>
        <div className="image-container1">
          {
            array1.map((src, index) => (
              <img key={index} src={src} alt={`Image ${index + 1}`} className="image-item1" />
            ))}
        </div>
        <br/>
        

        <div className="timer1">
        <span>{days >= 10 ? days : `0${days || 0}`}</span> : <span>{hours >= 10 ? hours : `0${hours || 0}`}</span> : <span>{minutes >= 10 ? minutes : `0${minutes || 0}`}</span>
        </div>

        <div className="center-content">
          <Form
            type={type}
            buttonComponent={(toggleModal) => (
              <CustomButton2 buttonString="BOOK YOUR SPOT" onClick={toggleModal} />
            )}
          />
        </div>
        
      </div>

      <style jsx>{`
        .timer1 {
          text-align: center;
          font-family: "Futura LT Condensed", sans-serif;
          font-size: 4rem;
          color: rgb(47, 110, 186);
          padding: 10px;
          font-weight: 900;
        }

        .center-content {
          display: flex;
          justify-content: center;
          margin: 20px 0;
        }

        .fut-button23 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 350px;
          height: 100px;
          background-color: rgb(47, 110, 186);
          color: white;
          font-family: "Futura LT Condensed", sans-serif;
          font-weight: 900;
          font-size: 3rem;
          border: none;
          cursor: pointer;
          margin: -10px 0;
        }

        .helptext96 {
          width: 100%;
          background-color: rgb(240, 240, 240);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 20px 0;
        }

        .helptext2 {
          width: 70%;
          background-color: rgb(240, 240, 240);
          text-align: center;
        }

        .subheading {
          font-size: 4rem !important;
        }

        .image-container1 {
          width: 80%; /* Take 80% of the parent container's width */
          margin: 0 auto; /* Center the container */
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 15px;
        }

        .image-item1 {
          width: calc(25% - 15px); /* Each image takes up 25% of the container width minus the gap */
          height: auto;
        }

        .familyf123 {
          width: 80%;
          margin: 0 auto;
          text-align: center;
          font-size: 4rem;
          color: rgb(47, 110, 186);
          font-family: "Futura LT Condensed", sans-serif;
          text-transform: uppercase;
          line-height: 0.94;
          font-weight: 900;
          margin-top: 0;
          margin-bottom: 2rem;
        }
        @media (max-width: 768px) {
          .timer1 {
            font-size: 2rem;
            font-weight: 700;
          }

          .fut-button23 {
            width: 80%;
            height: 60px;
            font-size: 1rem;
          }

          .image-container1 {
            flex-direction: column;
            align-items: center;
          }

          .image-item1 {
            width: 80%; /* Make each image take 80% of the container width on smaller screens */
          }

          .familyf123 {
            font-size: 2rem !important;
          }
        }
      `}</style>
    </>
  );
}
