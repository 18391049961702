import React from "react";
import Form from "../form";
import { useState, useEffect } from 'react';
const CustomButton2 = ({ onClick, buttonString }) => (
  <button className="fut-button97" onClick={onClick}>
    {buttonString}
  </button>
);

export default function Component({ buttonString, spot, type, wholeDate }) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [wholeDates, setWholeDates] = useState(0);
  useEffect(() => {
    console.log(wholeDate, 'whole date222222')
    const currentDate = new Date();
    const differenceInMilliseconds = wholeDate - currentDate;
    let day = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    let hour = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minute = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    day = Math.max(day, 0);
    hour = Math.max(hour, 0);
    minute = Math.max(minute, 0);
    console.log(day, hour, minute)
    setDays(day)
    setHours(hour)
    setMinutes(minute)
    setWholeDates(wholeDate)
  }, [wholeDate]);
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setMinutes(prevMinutes => {
        if (prevMinutes > 0) {
          return prevMinutes - 1;
        } else {
          setHours(prevHours => {
            if (prevHours > 0) {
              setMinutes(59);
              return prevHours - 1;
            } else {
              setDays(prevDays => {
                if (prevDays > 0) {
                  setHours(23);
                  setMinutes(59);
                  return prevDays - 1;
                } else {
                  clearInterval(timerInterval);
                  return 0;
                }
              });
            }
          });
        }
      });
    }, 60000);

    return () => clearInterval(timerInterval);
  }, [days, hours, minutes]);
  return (
    <>
      <div className="helptext98">
      <div className="helptext2">
  <p className="familyf">REACH YOUR GOAL 10X<br />FASTER, BETTER & SMARTER</p>

  <p className="heading-text">
    <strong>Right now, you have two choices:</strong>
  </p>
  <div className="content-container">
    <p className="description-text">
      You can either continue struggling with failed weight loss journeys, feeling frustrated by extreme diets and exhausting workouts that don't bring lasting results, and missing out on the health and happiness you deserve.
    </p>

    <p className="description-text">
      Or you can take action today, right now, by Joining in the Real Change Program. Start making a real transformation without gaining the weight back, while eating everything you want. Achieve your dream body with 50% less effort, feel committed to your fitness journey with ease, and gain the most precious thing in life: your health. The choice is yours.
    </p>

    <p className="description-text">
      Imagine waking up every day, feeling motivated and satisfied with your progress. Picture yourself enjoying your favorite foods without guilt, working out efficiently, and seeing continuous improvements. No more extreme diets or exhausting routines; just a healthy, happy you, fully transformed and confident in your journey.
    </p>

    <p className="description-text">
      You can start solving this problem immediately by taking action and joining the Real Change Transformation Program.
    </p>

    <p className="description-text">
      So sign up today, right now, while it's still hot on your mind. And be prepared to achieve your dream body with unparalleled motivation and satisfaction!
    </p>

    <div className="sig">
      <p className="sincerely-text">Sincerely</p>
      <div className="signature-container">
        <img src="./sig.png" alt="Signature" className="signature-image" />
      </div>
    </div>
  </div>
</div>

        <p className="title-text">REAL CHANGE PROGRAM</p>
        <p className="title-text">REGISTRATION ENDS IN</p>

        <div className="timer1">
        <span>{days >= 10 ? days : `0${days || 0}`}</span> : <span>{hours >= 10 ? hours : `0${hours || 0}`}</span> : <span>{minutes >= 10 ? minutes : `0${minutes || 0}`}</span>
        </div>

        <div className="form-container">
          <Form
            type={type}
            buttonComponent={(toggleModal) => (
              <CustomButton2 buttonString="HIT THE BOTTOM" onClick={toggleModal} />
            )}
          />
        </div>
      </div>

      <style jsx>{`
        .helptext98 {
          width: 100%;
          background-color: rgb(240, 240, 240);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

          .content-container {
           width: 70%;
            max-width: 1000px;
          text-align: center;
            }


        .helptext2 {
          width: 70%;
          background-color: rgb(240, 240, 240);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 20px;
        }

        .familyf {
          font-size: 4rem;
          color: rgb(47, 110, 186);
          font-weight: 900;
          font-family: "Futura LT Condensed", sans-serif;
          text-transform: uppercase;
          text-align: center;
          margin-bottom: 2rem;
        }

        .heading-text {
          font-size: 2.5rem;
          font-weight: 600;
          margin-bottom: 4.3rem;
          text-align: center;
        }

        .description-text {
          box-sizing: border-box;
          max-width: 1000px;
          margin-bottom: 4.3rem;
          text-align: center;
          font-size: 2rem;
        }

        .sig {
         text-align: left;
          width: 100%;
        }

        .sincerely-text {
          font-size: 2rem;
          margin-bottom: 0.5rem;
          text-align: left;
          display: inline-block;
        }

        .signature-container {
          display: flex;
          justify-content: flex-start;
        }

        .signature-image {
          width: 300px;
          height: auto;
          margin-top: 0.2rem;
        }

        .title-text {
          font-size: 3rem;
          font-weight: 900;
          text-transform: uppercase;
          letter-spacing: -0.08em;
          margin-bottom: -0.5rem;
        }

        .timer1 {
          font-weight: 700;
          margin-bottom: 20px;
        }

        .form-container {
          display: flex;
          justify-content: center;
        }

        .fut-button97 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 350px;
          height: 100px;
          margin-top: -10px;
          margin-bottom: 50px;
          background-color: rgb(47, 110, 186);
          color: white;
          font-family: "Futura LT Condensed", sans-serif;
          font-weight: 900;
          font-size: 3rem;
          border: none;
          cursor: pointer;
        }

        @media (max-width: 768px) {
          .helptext2 {
            width: 100%;
          }

          .fut-button97 {
            width: 100%;
            height: 60px;
            font-size: 1rem;
          }

          .familyf {
          width: 100%;
            font-size: 2rem;
          }

          .heading-text,
          .sincerely-text {
            font-size: 2rem;
          }

          .description-text {
            width: 100%;
            font-size: 1.5rem;
          }

          .title-text {
            font-size: 2rem;
          }

          .timer1 {
            font-size: 2rem;
            font-weight: 700;
          }

          .signature-image {
            max-width: 250px;
            margin-bottom: 50px;
          }
        }
      `}</style>
    </>
  );
}
