
import Header from "../components/header2";
import Header2 from "../components/WeightLossProgram/Header";
import Faq from "../components/WeightLossProgram/faq"
import Text from "../components/WeightLossProgram/text"
import Confused from "../components/confused";
import Text2 from "../components/WeightLossProgram/text2"
import Text3 from "../components/WeightLossProgram/text3"
import Text4 from "../components/WeightLossProgram/text4"
import Text5 from "../components/WeightLossProgram/text5"
import Text6 from "../components/WeightLossProgram/text6"
import Text7 from "../components/WeightLossProgram/text7"
import B1 from "../components/WeightLossProgram/button"
import React, { useState, useEffect } from 'react';
const Youssef = () => {
  const [youtubeUrl1, setYoutubeUrl1] = useState('');

  const [gymDay, setGymDay] = useState(0);
  const [gymHours, setGymHours] = useState(0);
  const [gymMinutes, setGymMinutes] = useState(0);
  const [wholeDate, setWholeDate] = useState(null);

  const [gymDay1, setGymDay1] = useState(0);
  const [gymHours1, setGymHours1] = useState(0);
  const [gymMinutes1, setGymMinutes1] = useState(0);
  const [wholeDate1, setWholeDate1] = useState(null);

  const [gymDay2, setGymDay2] = useState(0);
  const [gymHours2, setGymHours2] = useState(0);
  const [gymMinutes2, setGymMinutes2] = useState(0);
  const [wholeDate2, setWholeDate2] = useState(null);

  const [gymDay3, setGymDay3] = useState(0);
  const [gymHours3, setGymHours3] = useState(0);
  const [gymMinutes3, setGymMinutes3] = useState(0);
  const [wholeDate3, setWholeDate3] = useState(null);


  const [gymDay4, setGymDay4] = useState(0);
  const [gymHours4, setGymHours4] = useState(0);
  const [gymMinutes4, setGymMinutes4] = useState(0);
  const [wholeDate4, setWholeDate4] = useState(null);

  const [imageGardenUrl, setImageGardenUrl] = useState('');
  const [imageYoussefUrl, setImageYoussefUrl] = useState('');
  const [imageGymUrl, setImageGymUrl] = useState('');
  const [imageAssesmentUrl, setImageAssesmentUrl] = useState('');
  const [imageJumpUrl, setImageJumpUrl] = useState('');
  const [imageAllPeopleUrl, setImageAllPeopleUrl] = useState('');
  const [imageGym1Url, setImageGym1Url] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl1 = `https://www.aos.fit/api/getalldata`;
        let allData = await fetch(apiUrl1);
        const allDataResult = await allData.json();
        setImageGym1Url(`https://www.aos.fit/api/public/${allDataResult['youssef']}`)
        setImageAssesmentUrl(`https://www.aos.fit/api/public/${allDataResult['youssef1']}`)
        setImageAllPeopleUrl(`https://www.aos.fit/api/public/${allDataResult['youssef2']}`)
        setImageJumpUrl(`https://www.aos.fit/api/public/${allDataResult['youssef3']}`)
        setImageGardenUrl(`https://www.aos.fit/api/public/${allDataResult['youssef4']}`)
        setImageYoussefUrl(`https://www.aos.fit/api/public/${allDataResult['youssef5']}`)
        setImageGymUrl(`https://www.aos.fit/api/public/${allDataResult['youssef6']}`)



        setYoutubeUrl1(`${allDataResult['youssefyoutubeUrl5']}`)
        
        setGymDay(allDataResult['youssefDay'])
        setGymHours(allDataResult['youssefHour'])
        setGymMinutes(allDataResult['youssefMinute'])
        setWholeDate(allDataResult['youssefTimestamp'])

        setGymDay1(allDataResult['youssefDay1'])
        setGymHours1(allDataResult['youssefHour1'])
        setGymMinutes1(allDataResult['youssefMinute1'])
        setWholeDate1(allDataResult['youssefTimestamp1'])

        setGymDay2(allDataResult['youssefDay2'])
        setGymHours2(allDataResult['youssefHour2'])
        setGymMinutes2(allDataResult['youssefMinute2'])
        setWholeDate2(allDataResult['youssefTimestamp2'])

        setGymDay3(allDataResult['youssefDay3'])
        setGymHours3(allDataResult['youssefHour3'])
        setGymMinutes3(allDataResult['youssefMinute3'])
        setWholeDate3(allDataResult['youssefTimestamp3'])

        setGymDay4(allDataResult['youssefDay4'])
        setGymHours4(allDataResult['youssefHour4'])
        setGymMinutes4(allDataResult['youssefMinute4'])
        setWholeDate4(allDataResult['youssefTimestamp4'])

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [])

  return (
    <>
      <div className="div-cd">

        <Header />
<div
  style={{
    backgroundColor:  "rgb(58, 56, 56)",  
  }}
/>
        <Header2  url={youtubeUrl1}/>
        <br/>
        <br/>
        <Text wholeDate={wholeDate}/>
        <Text2/>
        <Text3 image1={imageGym1Url} image2={imageAssesmentUrl} image3={imageAllPeopleUrl}/>
        <Text4 wholeDate={wholeDate}/>
        <br/>
        <Text6/>
        <Text7/>
        <B1 image1={imageJumpUrl} image2={imageGardenUrl} image3={imageYoussefUrl} image4={imageGymUrl} wholeDate={wholeDate}/>
        <Text5  wholeDate={wholeDate}/>
        <Faq/>
        <Confused color={'blue'} type={'Youssef'} />
      </div>

      <style jsx>{`
        .div-cd {
          box-sizing: border-box;
            text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  font-family: Inter, sans-serif;
  font-weight: 400;
  user-select: none;
  font-size: 62.5%;
          display: flex;
          flex-direction: column;
          background-color:'gray';
        }
        .videogroup {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // height: 100vh; 
            margin-bottom: 20px;
            margin-top: 20px;
          }
          
          .videogroup iframe {
            width: 500px;
            height: 300px;
          }
          @media only screen and (max-width: 767px) {
            .videogroup iframe {
              width: 90%; 
              height: 300px; /* Preserve the aspect ratio */
            }
          }
      `}</style>
    </>
  );
};

export default Youssef;
