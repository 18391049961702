import React from "react";

function HowItWorksComponent(props) {
    return (
        <div className="justify-center items-center bg-neutral-600 flex flex-col px-5">
            <div className="flex w-full max-w-full flex-col items-center mt-12 mb-7 max-md:mt-10">
                <div className="justify-center text-stone-300 text-center text-5xl-6 font-bold leading-10 ">
                    HOW IT WORKS ?
                </div>
                <div className="flex items-start justify-between gap-10 mt-11 max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:mt-10">
                    <div className="w-full md:w-[30%]">
                        <div className="justify-center text-orange-500 text-center text-5xl-6 font-bold leading-10 whitespace-nowrap">
                            STEP 1
                        </div>
                        <div className="justify-center text-stone-300 text-center text-mini-5 font-bold leading-5 whitespace-nowrap mt-6">
                            WE TRAIN YOU
                        </div>
                    </div>
                    <div className="w-full md:w-[30%]">
                        <div className="justify-center text-orange-500 text-center text-5xl-6 font-bold leading-10 self-stretch whitespace-nowrap">
                            STEP 2
                        </div>
                        <div className="justify-center text-stone-300 text-center text-mini-5 font-bold leading-5 self-stretch whitespace-nowrap mt-6">
                            WE CUSTOMIZE A
                        </div>
                        <div className="justify-center text-stone-300 text-center text-mini-5 font-bold leading-5 self-stretch whitespace-nowrap mt-1.5">
                            MEAL PLAN FOR
                        </div>
                        <div className="justify-center text-stone-300 text-center text-sm font-bold leading-5 self-center whitespace-nowrap">
                            YOU
                        </div>
                    </div>
                    <div className="w-full md:w-[30%]">
                        <div className="justify-center text-orange-500 text-center text-5xl-6 font-bold leading-10 whitespace-nowrap">
                            STEP 3
                        </div>
                        <div className="justify-center text-stone-300 text-center text-mini-5 font-bold leading-5 self-stretch whitespace-nowrap mt-6">
                            WE EDUCATE YOU
                        </div>
                    </div>
                    <div className="w-full md:w-[30%]">
                        <div className="justify-center text-orange-500 text-center text-5xl-6 font-bold leading-10 whitespace-nowrap">
                            STEP 4
                        </div>
                        <div className="justify-center text-stone-300 text-center text-mini-5 font-bold leading-5 self-stretch whitespace-nowrap mt-6">
                            WE MEASURE
                        </div>
                        <div className="justify-center text-stone-300 text-center text-mini-5 font-bold leading-5 self-stretch whitespace-nowrap mt-2">
                            YOUR PROGRESS
                        </div>
                    </div>
                    <div className="w-full md:w-[30%]">
                        <div className="justify-center text-orange-500 text-center text-5xl-6 font-bold leading-10 whitespace-nowrap">
                            STEP 5
                        </div>
                        <div className="justify-center text-stone-300 text-center text-mini-5 font-bold leading-5 self-stretch whitespace-nowrap mt-6">
                            WE FOLLOW UP
                        </div>
                    </div>
                    <div className="w-full md:w-[30%]">
                        <div className="justify-center text-orange-500 text-center text-5xl-6 font-bold leading-10 whitespace-nowrap">
                            STEP 6
                        </div>
                        <div className="justify-center text-stone-300 text-center text-mini-5 font-bold leading-5 self-stretch whitespace-nowrap mt-6">
                            YOU TRANSFORM
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default HowItWorksComponent;