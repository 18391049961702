export default function HowItWorks(props) {
  return (
    <section className="justify-center items-center bg-neutral-600 self-stretch flex w-full flex-col -mr-5 pt-6 pb-6 max-md:max-w-full">
      <div className="justify-center text-stone-300 text-center text-11xl font-bold whitespace-nowrap  md:mb-10 max-md:max-w-full">
        HOW IT WORKS ?
      </div>
      <div className="flex flex-col md:flex-row md:ml-20 max-w-full">
        <div className="flex flex-col items-left max-md:w-full max-md:ml-0">
          <div className="flex flex-col items-center max-md:mt-10">
            <div className="justify-center text-orange-500 text-center text-11xl font-bold leading-10 whitespace-nowrap">
              1
            </div>
            <h2 className="justify-center text-stone-300 text-center text-base font-bold leading-5 self-stretch whitespace-nowrap ">
              FILL THE FORM
            </h2>
          </div>
        </div>
        <div className="flex flex-col items-right ml-5 max-md:w-full max-md:ml-0">
          <div className="flex grow flex-col items-stretch max-md:max-w-full  md:ml-20 max-md:mt-1">
            <div className="flex flex-col items-center self-center">
              <div className="justify-center text-orange-500 text-center text-11xl font-bold leading-10">
                2
              </div>
              <div className="items-center self-center">
                <h2 className="text-stone-300 text-center text-base font-bold leading-5 self-center  max-w-[300px]">
                  WE WILL CALL YOU TO SET AN APPOINTMENT FOR A FREE TRIAL
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>



    </section >
  );
}
