import * as React from "react";

export default function PricelessOffers(props)  {
  return (
    <>
        <div className="div-35">
          <div className="div-36">
            <div className="div-37">
              Why are we giving this priceless offer for free ?
            </div>
            <div className="div-38">
              Simply we want to help you change your life!
            </div>
            <div className="div-39">
               We believe that most of the gym industry in Egypt is exploiting
              the people by requiring them to subscribe to the gym and then take
              private
            </div>
            <div className="div-40">
              sessions to receive the first tip on what they need to do. We
              believe this is not how the industry should work. At AOS, we want
              to break this
            </div>
            <div className="div-41">
              mold we want to earn your trust before even subscribing.
            </div>
            <div className="div-42">
              So we decided to create this invaluable offer that takes off the
              risk on any one who wants to train. Best case scenario you do the
              Free
            </div>
            <div className="div-43">
              assessment and we help you change your life. Worst case scenario
              you get all this free info &quot;That we beleive it's pricelss
              information&quot; and
            </div>{" "}
            <div className="div-44">you go change your life </div>
          </div>
        </div>
 
      <style jsx>{`
        .div-35 {
          background-color: #e8e8e8;
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          margin: 20px 0px 0 0;
          padding: 27px 0px 13px 0px;
        }
        @media (max-width: 991px) {
          .div-35 {
            max-width: 100%;
          }
        }
        .div-36 {
          display: flex;
          width: 1066px;
          max-width: 100%;
          flex-direction: column;
        }
        .div-37 {
          justify-content: center;
          color: #2d2d2d;
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 700 28px/137% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-37 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .div-38 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          align-self: center;
          margin-top: 10px;
          white-space: nowrap;
          font: 400 16px/149% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-38 {
            white-space: initial;
          }
        }
        .div-39 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          align-self: stretch;
          margin-top: 25px;
          font: 400 16px/149% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-39 {
            max-width: 100%;
          }
        }
        .div-40 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          align-self: stretch;
          white-space: nowrap;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-40 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .div-41 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 400 16px/149% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-41 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .div-42 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          align-self: center;
          margin-top: 25px;
          white-space: nowrap;
          font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-42 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .div-43 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          align-self: stretch;
          white-space: nowrap;
          font: 400 16px/149% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-43 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .div-44 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 400 16px/149% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-44 {
            white-space: initial;
          }
        }

        .hover-link {
          color: rgb(0, 0, 238);
          text-decoration: none;
          transition: border-color 0.3s;
          border-bottom: 1px solid transparent;
        }
        
        .hover-link:hover {
          border-bottom: 4px solid rgb(0, 0, 238);
        }
      `}</style>
    </>
  );
}
