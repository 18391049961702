import React from "react";
import Form from './form';
const CustomButton = ({ onClick }) => (
    <button
        onClick={onClick}
        className="justify-center cursor-pointer text-white text-center text-xl font-bold leading-7 uppercase items-center border shadow-sm bg-orange-500 w-[330px] max-w-full mt-14 px-4 py-3 rounded border-solid border-black border-opacity-20  max-md:mt-10"
    >
        BOOK YOUR SPOT FOR FREE
    </button>
);
export default function FitnessComponent({ type, imageReviews1Url }) {
    return (
        <div className="justify-center items-center bg-black flex flex-col px-5">
            <div className="flex w-[946px] max-w-full flex-col items-center mt-14 mb-10 max-md:mt-10">
                <div className="self-stretch max-md:max-w-full">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[41%] max-md:w-full max-md:ml-0">
                            <img
                                loading="lazy"
                                src="./circuit.png"
                                className="aspect-[4.86] object-contain object-center overflow-hidden my-auto max-md:mt-10"
                            />
                        </div>
                        <div className="flex flex-col items-stretch w-[59%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex grow flex-col max-md:max-w-full max-md:mt-10">
                                <div className="justify-center text-stone-300 text-center text-sm leading-5 self-center ">
                                    Need guidance?
                                </div>
                                <div className="justify-center text-stone-300 text-center text-base leading-5 self-center mt-2">
                                    Bored of training alone?
                                </div>
                                <div className="justify-center text-stone-300 text-center text-sm leading-5 self-center ">
                                    Privates are very expensive?
                                </div>
                                <div className="justify-center text-stone-300 text-center text-base leading-5 self-center">
                                    Don’t know from where or how to start?
                                </div>
                                <div className="justify-center text-stone-300 text-center text-sm leading-5 self-center  mt-1">
                                    Want to keep track of your performance?
                                </div>
                                <div className="justify-center text-stone-300 text-center text-base leading-5 self-center ">
                                    Wasting too much time and not seeing results?
                                </div>
                                <div className="justify-center text-stone-300 text-center text-sm leading-5 self-center">
                                    Paid gym membership and only went for couple of times?
                                </div>
                                <a href="#" className="justify-center text-orange-500 text-center text-sm-8 font-bold leading-5 self-stretch mt-5 max-md:max-w-full no-underline hover:underline">
                                    WELL, OUR REVOLUTIONARY CONCEPT GOT ALL THESE POINTS COVERED!
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Form type={type} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
            <img
                loading="lazy"
                src={imageReviews1Url}
                className=" object-contain object-center w-[75%] h-[80%] overflow-hidden mt-10 mb-10 max-md:max-w-full"
            />
        </div>
    );
}