import React from "react";
import Header from "../components/header2";
export default function NotFoundPage(props) {
    return (
        <>
            <div className="flex flex-col items-stretch  bg-gray-100">
                <Header />
                <div className="bg-white self-center flex w-[720px] max-w-full flex-col items-center py-12">
                    <div className="flex w-[300px] max-w-full flex-col items-stretch mt-8">
                        <img
                            loading="lazy"
                            src="/aosError.png"
                            className="aspect-[2.63] object-contain object-center w-[150px] overflow-hidden self-center max-w-full"
                        />
                        <div className="justify-center text-blue-800 text-center text-13xl font-bold leading-10 whitespace-nowrap mt-8 max-md:mt-10">
                            PAGE NOT FOUND !
                        </div>
                        <a href="/" className="justify-center text-white text-center text-xs font-semibold leading-4 whitespace-nowrap items-center border shadow-sm bg-blue-800 self-center w-[161px] max-w-full mt-8 px-5 py-4 rounded border-solid border-black border-opacity-20 no-underline hover:cursor-pointer">
                            GO TO HOMEPAGE
                        </a>
                    </div>
                </div>
                <div className="justify-center text-neutral-800 text-center text-base leading-6 self-center whitespace-nowrap mt-9">
                    Copyright - AOS
                </div>
            </div>   <style jsx>{`
            html {
                background-color: #F1F4F8;
                /* color: #2f2f2f; */
            }
      `}</style>
        </>
    );
}