import React from "react";
import Form from '../form';

const CustomButton2 = ({ onClick, buttonString }) => (
  <button className='fut-button98' onClick={onClick}>{buttonString}</button>
);

export default function Component({ buttonString, spot, type, wholeDate }) {
  return (
    <>
      <div className="helptext23">
        <p className="familyf23" style={{ marginBottom: '20px' }}>
          <br />
          WHY REAL CHANGE IS DIFFERENT FROM
          OTHER ONLINE PROGRAMS
        </p>
        <br />
        <div className="container102">
          <div className="box">Nutrition Plan</div>
          <div className="box">Training Program</div>
          <div className="box">Training Calendar</div>
          <div className="box">Educational Content</div>
          <div className="box">Online Live Discussion Sessions</div>
          <div className="box">Whatsapp Group For Ongoing Support</div>
        </div>
        <br /><br />
        <p className="box1">REAL CHANGE IS NOT JUST A</p>
        <p className="box1"><strong>TRAINING PROGRAM, IT IS A 360</strong></p>
        <p className="box1">HOLISTIC APPROACH.</p>
        <br /><br />
      </div>

      <style jsx>{`
        .helptext23 {
          background-color: rgb(58, 56, 56);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        .container102 {
          width: 50%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          
        }

        .box {
          width: 30%;
          height: 200px;
          background-color: rgb(47, 110, 186);
          border-radius: 10px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "Futura LT Condensed", sans-serif;
          font-weight: 900;
          font-size: 18px;
          text-align: center;
          margin-bottom: 20px;
        }

        .box1 {
          font-size: 30px;
          font-weight: bold;
          color: white;
          text-align: center;
          margin-bottom: 0px;
        }

        .fut-button98 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 350px;
          height: 100px;
          background-color: rgb(47, 110, 186);
          color: white;
          font-family: "Futura LT Condensed", sans-serif;
          font-weight: 900;
          font-size: 3rem;
          border: none;
          cursor: pointer;
          margin: 28px 0 50px;
        }

        @media (max-width: 768px) {
          .container102 {
            width: 100%;
            align-items: center;
          justify-content: center;
          text-align: center;
          }

          .box {
            align-items: center;
            width: 80%;
            height: 100px;
          }

          .box1 {
            font-size: 15px;
          }

          .fut-button98 {
            width: 100%;
            height: 60px;
            font-size: 1rem;
          }
        }

        .familyf23 {
        width: 95%;
          font-size: 2rem;
          color: rgb(255, 255, 255);
          font-family: "Futura LT Condensed", sans-serif;
          font-weight: 900;
          text-transform: uppercase;
          text-align: center;
          line-height: 0.94;
          margin-bottom: 2rem;
        }

        @media (min-width: 768px) {
          .familyf23 {
                 width: 55%;
            font-size: 4rem;
            letter-spacing: -0.04em;
          }
        }
      `}</style>
    </>
  );
}
