import React from "react";
import Form from '../form';
import { useState, useEffect } from 'react';

const CustomButton2 = ({ onClick, buttonString }) => (
  <button className='fut-button96' onClick={onClick}>{buttonString}</button>
);

export default function Component({ whole, spot, type, wholeDate }) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [wholeDates, setWholeDates] = useState(0);
  useEffect(() => {
    console.log(wholeDate, 'whole date222222')
    const currentDate = new Date();
    const differenceInMilliseconds = wholeDate - currentDate;
    let day = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    let hour = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minute = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    day = Math.max(day, 0);
    hour = Math.max(hour, 0);
    minute = Math.max(minute, 0);
    console.log(day, hour, minute)
    setDays(day)
    setHours(hour)
    setMinutes(minute)
    setWholeDates(wholeDate)
  }, [wholeDate]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setMinutes(prevMinutes => {
        if (prevMinutes > 0) {
          return prevMinutes - 1;
        } else {
          setHours(prevHours => {
            if (prevHours > 0) {
              setMinutes(59);
              return prevHours - 1;
            } else {
              setDays(prevDays => {
                if (prevDays > 0) {
                  setHours(23);
                  setMinutes(59);
                  return prevDays - 1;
                } else {
                  clearInterval(timerInterval);
                  return 0;
                }
              });
            }
          });
        }
      });
    }, 60000);

    return () => clearInterval(timerInterval);
  }, [days, hours, minutes]);
  return (
    <>
      <div className="helptext99">
        <div className="helptext2">
          <p className="familyf" style={{ marginBottom: '20px' }}>
            But Don't Take My Word For It. Here's What Our Members Have To Say About REAL CHANGE
          </p>
          <div className="video-container18">
            <div className="video">
              <video controls>
                <source src='/Mira-Testemonial.mp4' type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video">
              <video controls>
                <source src='/Youssef-hawash-Testemonial.mp4' type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video">
              <video controls>
                <source src='/Reem-Testemonial.mp4' type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="timer1" style={{ fontWeight: 700 }}>
          <span>{days >= 10 ? days : `0${days || 0}`}</span> : <span>{hours >= 10 ? hours : `0${hours || 0}`}</span> : <span>{minutes >= 10 ? minutes : `0${minutes || 0}`}</span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Form
              type={type}
              buttonComponent={(toggleModal) => (
                <CustomButton2 buttonString="BOOK YOUR SPOT" onClick={toggleModal} />
              )}
            />
          </div>
        </div>
      </div>

      <style jsx>{`
        .timer1 {
          text-align: center;
          font-family: "Futura LT Condensed", sans-serif;
          font-size: 4rem;
          color: rgb(47, 110, 186);
          padding: 10px;
          font-weight: 900;
        }

        @media (max-width: 768px) {
          .timer1 {
            font-size: 2rem;
            font-weight: 700;
          }
        }

        .fut-button96 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 350px;
          height: 100px;
          text-align: center;
          margin-top: -10px;
          margin-bottom: 50px;
          background-color: rgb(47, 110, 186);
          color: white;
          font-family: "Futura LT Condensed", sans-serif;
          font-weight: 900;
          font-size: 3rem;
          border: none;
          cursor: pointer;
        }

        .helptext99 {
          width: 100%;
          background-color: rgb(240, 240, 240);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        .helptext2 {
          width: 70%;
          background-color: rgb(240, 240, 240);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        @media (max-width: 768px) {
          .helptext99 {
            padding: 20px 0px 0px 0px;
          }

          .fut-button96 {
            width: 100%;
            height: 60px;
            font-size: 1rem;
          }
        }

.video-container18 {
  display: flex;
  gap: 20px;
 margin:  25px 0 25px 0;
  justify-content: center; /* Center videos horizontally */
} 

.video {
  background-color: black;
  padding: 0;
  text-align: center;
  border: 2px solid #333;
  display: flex;
  justify-content: center; /* Center video content */
  align-items: center; /* Center video content */
  flex-direction: column;
  width: 100%;
  max-width: 500px; /* Maximum width constraint */
  height: auto; /* Let height adjust automatically */
}

.video video {
  width: 100%;
  height: 300px; /* Automatically adjust height */

}

/* Desktop view */
@media (min-width: 768px) {
  .video {
    width: 45%;
    height: 300px;
  }
}

/* Mobile view */
@media (max-width: 767px) {
  .video {
    width: 90%; /* Adjust to 90% width for mobile */
    height: 300px; /* Height adjusts automatically */
    margin-bottom: 20px;
  }

  .video-container18 {
        flex-wrap: wrap;
  }

  .video video {
    height: 300px
  }
}


        .familyf {
          font-size: 3rem;
          color: rgb(47, 110, 186);
          box-sizing: border-box;
          margin-top: 0;
          font-weight: 900;
          font-family: "Futura LT Condensed", sans-serif;
          text-transform: uppercase;
          text-align: center;
          line-height: 0.94;
          margin-bottom: 2rem;
        }

        @media (min-width: 768px) {
          .familyf {
            font-size: 4rem;
            letter-spacing: -0.04em;
          }
        }
      `}</style>
    </>
  );
}
