import React, { useState, useEffect } from 'react';
import Header from "../components/header2";
import FitnessEntity from "../components/fitnessEntity"
import Gym1 from "../components/gym";
import Confused from "../components/confused";
import CrazyOffer from "../components/crazyOffer";
import GymHeader from "../components/gymHeader";
const Gym = () => {
  const color = 'rgb(243, 20, 0)';
  const [imageGardenUrl, setImageGardenUrl] = useState('');
  const [imageYoussefUrl, setImageYoussefUrl] = useState('');
  const [imageGymUrl, setImageGymUrl] = useState('');
  const [imageAssesmentUrl, setImageAssesmentUrl] = useState('');
  const [imageJumpUrl, setImageJumpUrl] = useState('');
  const [imageAllPeopleUrl, setImageAllPeopleUrl] = useState('');
  const [imageGym1Url, setImageGym1Url] = useState('');
  const [imageReviews1Url, setImageReviews1Url] = useState('');
  const [imageCrazyOffer1Url, setImageCrazyOffer1Url] = useState('');
  const [enteredPercentage, setEnteredPercentage] = useState(0);
  const [enteredSpot, setEnteredSpot] = useState(0);
  const [gymDay, setGymDay] = useState(0);
  const [gymHours, setGymHours] = useState(0);
  const [gymMinutes, setGymMinutes] = useState(0);
  const [wholeDate, setWholeDate] = useState(null);
  const [youtubeUrl1, setYoutubeUrl1] = useState('');
  const [youtubeUrl2, setYoutubeUrl2] = useState('');
  const [youtubeUrl3, setYoutubeUrl3] = useState('');
  const [youtubeUrl4, setYoutubeUrl4] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl1 = `https://www.aos.fit/api/getalldata`;
        let allData = await fetch(apiUrl1);
        const allDataResult = await allData.json();
        console.log(allDataResult)
        setEnteredSpot(allDataResult['gymSpot'])
        setEnteredPercentage(allDataResult['gymPercentage'])
        setImageYoussefUrl(`https://www.aos.fit/api/public/${allDataResult['youssef']}`)
        setImageGardenUrl(`https://www.aos.fit/api/public/${allDataResult['garden']}`)
        setImageGymUrl(`https://www.aos.fit/api/public/${allDataResult['gym']}`)
        setImageAssesmentUrl(`https://www.aos.fit/api/public/${allDataResult['assesment']}`)
        setImageAllPeopleUrl(`https://www.aos.fit/api/public/${allDataResult['allPeople']}`)
        setImageGym1Url(`https://www.aos.fit/api/public/${allDataResult['gym1']}`)
        setImageJumpUrl(`https://www.aos.fit/api/public/${allDataResult['jump']}`)
        setImageReviews1Url(`https://www.aos.fit/api/public/${allDataResult['reviews1']}`)
        setImageCrazyOffer1Url(`https://www.aos.fit/api/public/${allDataResult['crazyOffer1']}`)
        setGymDay(allDataResult['gymDay'])
        setGymHours(allDataResult['gymHour'])
        setGymMinutes(allDataResult['gymMinute'])
        setWholeDate(allDataResult['gymTimestamp'])
        setYoutubeUrl1(`${allDataResult['youtubeUrl1']}`)
        setYoutubeUrl2(`${allDataResult['youtubeUrl2']}`)
        setYoutubeUrl3(`${allDataResult['youtubeUrl3']}`)
        setYoutubeUrl4(`${allDataResult['youtubeUrl4']}`)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="div-c">
        <Header />
        <GymHeader garden={imageGardenUrl} spot={enteredSpot} day={gymDay} hour={gymHours} minute={gymMinutes} percent={enteredPercentage} wholeDate={wholeDate} />
        <FitnessEntity youssef={imageYoussefUrl} gym={imageGymUrl} />
        <Gym1 type={'Gym'} percent={enteredPercentage} allPeople={imageAllPeopleUrl} gym1={imageGym1Url} assesment={imageAssesmentUrl} jump={imageJumpUrl}
          reviews={imageReviews1Url} youtubeUrl1={youtubeUrl1} youtubeUrl2={youtubeUrl2} youtubeUrl3={youtubeUrl3} youtubeUrl4={youtubeUrl4} />
        <CrazyOffer garden={imageGardenUrl} spot={enteredSpot} day={gymDay} hour={gymHours} minute={gymMinutes} percent={enteredPercentage} wholeDate={wholeDate} crazyOffer={imageCrazyOffer1Url} />
        <Confused color={color} type={'Gym'} />
      </div >
      <style jsx>{`
        .div-c {
          display: flex;
          flex-direction: column;
        }
      `}</style>
    </>
  );
};

export default Gym;
