import Form from './form';
import React, { useState, useEffect } from 'react';
const CustomButton = ({ onClick, buttonString, percent }) => (

  <button onClick={onClick} className="div-gym-28">{`BOOK your ${percent || 0} % OFFER`}</button>
  // <button
  //     onClick={onClick}
  //     className="justify-center text-white text-center text-xl font-bold leading-7 uppercase whitespace-nowrap items-center border shadow-sm bg-red-600 self-center w-[284px] max-w-full mt-8 px-5 py-4 rounded border-solid border-black border-opacity-20 hover:cursor-pointer"
  // >
  //     BOOK your 35% OFFER
  // </button>
);

const CustomButton2 = ({ onClick, buttonString }) => (
  <button onClick={onClick} className="div-gym-29">{buttonString}</button>
  // <button
  //     onClick={onClick}
  //     className="justify-center text-white text-center text-xl font-bold leading-7 uppercase whitespace-nowrap items-center border shadow-sm bg-red-600 self-center w-[284px] max-w-full mt-8 px-5 py-4 rounded border-solid border-black border-opacity-20 hover:cursor-pointer"
  // >
  //     BOOK your 35% OFFER
  // </button>
);

export default function BookOffer({ buttonString, spot, percent, wholeDate }) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [wholeDates, setWholeDates] = useState(0);

  useEffect(() => {
    console.log(wholeDate, 'whole date')
    const currentDate = new Date();
    const differenceInMilliseconds = wholeDate - currentDate;
    let day = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    let hour = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minute = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    day = Math.max(day, 0);
    hour = Math.max(hour, 0);
    minute = Math.max(minute, 0);
    setDays(day)
    setHours(hour)
    setMinutes(minute)
    setWholeDates(wholeDate)
  }, [wholeDate]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setMinutes(prevMinutes => {
        if (prevMinutes > 0) {
          return prevMinutes - 1;
        } else {
          setHours(prevHours => {
            if (prevHours > 0) {
              setMinutes(59);
              return prevHours - 1;
            } else {
              setDays(prevDays => {
                if (prevDays > 0) {
                  setHours(23);
                  setMinutes(59);
                  return prevDays - 1;
                } else {
                  clearInterval(timerInterval);
                  return 0;
                }
              });
            }
          });
        }
      });
    }, 60000);

    return () => clearInterval(timerInterval);
  }, [days, hours, minutes]);


  return (
    <>
      <div className="div-gym-8">OFFER ENDS IN</div>
      <div className="div-gym-9">
        <div className="div-gym-10">
          <div className="div-gym-11">
            <div className="div-gym-12">{days >= 10 ? days : `0${days || 0}`}</div>
            <div className="div-gym-13">:</div>
          </div>
          <div className="div-gym-14">Days</div>
        </div>
        <div className="div-gym-10">
          <div className="div-gym-11">
            <div className="div-gym-12">{hours >= 10 ? hours : `0${hours || 0}`}</div>
            <div className="div-gym-13">:</div>
          </div>
          <div className="div-gym-14">Hours</div>
        </div>
        <div className="div-gym-10">
          <div className="div-gym-11">
            <div className="div-gym-12">{minutes >= 10 ? minutes : `0${minutes || 0}`}</div>
          </div>
          <div className="div-gym-14">Minutes</div>
        </div>
      </div>
      <div className="div-gym-23">
        <div className="div-gym-24">ONLY</div>
        <a href="#" className="div-gym-25">{spot || 0} SPOTS</a>
        <div className="div-gym-26">AVAILABLE</div>
        <div className="div-gym-27"> !</div>
      </div >

      {buttonString === 'CRAZYOFFER' ? (
        <Form buttonComponent={(toggleModal) => <CustomButton2 buttonString="BOOK YOUR SPOT IN THIs CRAZY OFFER" onClick={toggleModal} />} />
      ) : (
        <Form buttonComponent={(toggleModal) => <CustomButton percent={percent} buttonString="BOOK your 35 % OFFER" onClick={toggleModal} />} />
      )}
      <style jsx>{`
        .div-gym-8 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          align-self: center;
          margin-top: 28px;
          white-space: nowrap;
          font: 700 15.5px/23px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gym-8 {
            white-space: initial;
          }
        }
        .div-gym-9 {
          text-align: center;
          align-self: center;
          display: flex;
          margin-left: 58px;
          max-width: 100%;
          justify-content: space-between;
        }

        @media (max-width: 300px) {
          .div-gym-9 {
            margin-left: 0px;
          }
        }
        .div-gym-10 {
          display: flex;
          flex-basis: 0%;
          flex-direction: column;
          margin-right: 15px;
        }
        .div-gym-11 {
          display: flex;
          justify-content: space-between;
          gap: 20px;
        }
        .div-gym-12 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          font: 700 22px/39px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gym-13 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          align-self: start;
          white-space: nowrap;
          font: 700 27px/39px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gym-13 {
            white-space: initial;
          }
        }
        .div-gym-14 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          text-transform: uppercase;
          margin: 15px 25px 0px -3px;
          white-space: nowrap;
          font: 400 10px/14px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gym-14 {
            white-space: initial;
          }
        }
        .div-gym-15 {
          display: flex;
          flex-basis: 0%;
          flex-direction: column;
        }
        .div-gym-16 {
          display: flex;
          align-items: start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-gym-16 {
            justify-content: center;
          }
        }
        .div-gym-17 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          align-self: stretch;
          font: 700 22px/39px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gym-18 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          font: 700 27px/39px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gym-19 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          align-self: stretch;
          white-space: nowrap;
          font: 700 22px/39px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gym-19 {
            white-space: initial;
          }
        }
        .div-gym-20 {
          display: flex;
          margin-top: 15px;
          justify-content: space-between;
          gap: 23px;
        }
        .div-gym-21 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          text-transform: uppercase;
          margin: 0px 25px 0px -5px;
          font: 400 10px/14px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gym-22 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          margin: 0px -5px 0px 0px;
          font: 400 10px/14px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gym-22 {
            white-space: initial;
          }
        }
        .div-gym-23 {
          align-self: center;
          display: flex;
          margin-top: 24px;
          width: 275px;
          max-width: 100%;
          gap: 6px;
        }
        @media (max-width: 991px) {
          .div-gym-23 {
            justify-content: center;
          }
        }
        .div-gym-24 {
          justify-content: center;
          color: #000;
          text-align: center;
          font: 700 18px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gym-25 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          padding-right: 1px;
          text-decoration: none;
          font: 700 19px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gym-25:hover {
            border-bottom: 2px solid #f31400;;
        }
        .div-gym-26 {
          justify-content: center;
          color: #000;
          text-align: center;
          font: 700 19px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gym-27 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          white-space: nowrap;
          font: 700 20px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gym-27 {
            white-space: initial;
          }
        }
        .div-gym-28 {
          cursor: pointer;
          justify-content: center;
          color: #fff;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          border-radius: 3px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
          background-color: #f31400;
          align-self: center;
          margin-top: 26px;
          width: 284px;
          max-width: 100%;
          padding: 10px 20px;
          font: 700 19px/29px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gym-28 {
            white-space: initial;
          }
        }
        .div-gym-29 {
          cursor: pointer;
          justify-content: center;
          color: #fff;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          border-radius: 3px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
          background-color: #f31400;
          align-self: center;
          margin-top: 26px;
          width: 420px;
          max-width: 100%;
          padding: 10px 20px;
          font: 700 19px/29px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gym-29 {
            white-space: initial;
          }
        }
      `}</style>
    </>
  );
}


