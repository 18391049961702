import React from "react";
import Form from '../form'; 

const CustomButton2 = ({ onClick, buttonString }) => (
    <button className='fut-button99' onClick={onClick}>{buttonString}</button>
);
export default function Component({ buttonString, spot, type, wholeDate }) {
  return (
    <>
      <div className="helptext97">
        <p className="familyf main-heading">
          <br />
          REAL CHANGE
          <br />
        </p>
        
        <p className="familyf sub-heading">
          TRANSFORMATION PROGRAM
        </p>

        <p className="familyf sub-heading2 ">
          IF YOUR GOAL IS TO
        </p>
        <div style={{width:'90%', alignItems:'center'}}>
        {[
          "Understand why you are doing what you are doing",
          "Lose weight while enjoying your favorite foods",
          "Get fit without killing yourself in workouts",
          "Never get injured again",
          "Transform your body and mindset for lasting change",
          "Maintain your dream body without gaining the weight back",
          "Avoid years of mistakes and fast-track your fitness success",
          "Belong to a supportive community that constantly motivates you"
        ].map((text, index) => (
          <p key={index} className="goal-text">
            <strong>{text}</strong>
          </p>
        ))}
        </div>

        <p className="familyf sub-heading">
        THEN REAL CHANGE IS YOUR GO TO
          </p>
      </div> <style jsx>{`/* Container and layout styles */
.helptext97 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.main-heading {
  margin-bottom: 20px;
}

.sub-heading {
  font-size: 30px !important;
  margin-top: 10px;
  margin-bottom: 35px;
}

       .sub-heading2 {
       font-family: arial,sans-serif !important;
    font-size: 30px !important;
    font-weight: 400 !important;
    }

@media only screen and (max-width: 767px) {
   .sub-heading {
    font-size: 1.3rem !important;
    font-weight: 500 !important;
    }

       .sub-heading2 {
    font-size: 1px !important;
    font-weight: 400 !important;
    }

    .goal-text {
        font-size: 1.3rem !important;
}
}

/* Text styling for goals */
.goal-text {
  box-sizing: border-box;
  margin-top: 0px;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2.5rem;
}

.goal-text strong {
  font-weight: 600;
}

/* Button styles */
.fut-button99 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 100px;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 50px;
  background-color: rgb(47, 110, 186);
  color: white;
  font-family: "Futura LT Condensed", sans-serif;
  font-weight: 900;
  font-size: 3
`}</style>
    </> 
  );
}
