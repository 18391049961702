import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "./form.css";

export default function Modal({ buttonComponent, type }) {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    location: "",
    age: "",
    gender: "",
  });
  console.log(
    'type', type
  )
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [fbclid, setFbclid] = useState(null);

  const toggleModal = () => {
    if (!modal) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setModal(!modal);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const fbclidParam = urlParams.get('fbclid');
    setFbclid(fbclidParam);
  }, []); // Run this effect once when the component mounts

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(fbclid);

    try {
      formData.custom_type = type;
      formData.fbclid = fbclid;
      // Use the fbclid value in your API request if needed
      const response = await axios.post(`https://www.aos.fit/api/savecontact`, formData);

      if (response.data.error) {
        setError(error.response?.data?.error || "An error occurred. Please try again.");
      } else {
        setSubmitted(true);
        toggleModal();
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setError(error.response?.data?.error || "An error occurred. Please try again.");
    }
  };

  if (submitted) {
    navigate('/thank-you-page1669060964003');
  }

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  return (
    <>
      {buttonComponent && buttonComponent(toggleModal)}

      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <div className="logoContainer">
              <div className="logoContainer">
                <img
                  src="/aos1.png"
                  alt="AOS Logo"
                  width="100"
                />
              </div>

              <form onSubmit={handleSubmit}>
                <div className="formRow">
                  <input
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    className="inputField"
                    required
                    minLength={3}
                    maxLength={20}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="last_name"
                    className="inputFieldLeft"
                    required
                    minLength={3}  // Set the minimum length (change as needed)
                    maxLength={20} // Set the maximum length (change as needed)
                    onChange={handleChange}
                  />
                </div>

                <div className="formRow">
                  <input
                    type="tel"
                    placeholder="Mobile Number"
                    name="phone"
                    className="inputField"
                    required
                    maxLength={15} // Set the maximum length for mobile number
                    pattern="^\+?[0-9]+$"   // Accept only numeric values
                    onChange={handleChange}
                  />
                  <input type="email" placeholder="Email Address" name="email" className="inputFieldLeft" required onChange={handleChange} />
                </div>
                <div className="formRow inputRow">
                  <input
                    type="number"
                    placeholder="Age"
                    name="age"
                    min={3}
                    max={99}
                    className="inputField-3"
                    required
                    onChange={handleChange}
                  />
                  <select name="location" className="inputField-2" required onChange={handleChange}>
                    <option value="" disabled selected>
                      Location
                    </option>
                    <option value="Sheikh Zayed">Sheikh Zayed</option>
                    <option value="6 October">6 October</option>
                    <option value="Mohandessin">Mohandessin</option>
                    <option value="Zamalek">Zamalek</option>
                    <option value="Haram">Haram</option>
                    <option value="Other">Other</option>
                  </select>
                  <select name="gender" className="inputField-4" required onChange={handleChange}>
                    <option value="" disabled selected>
                      Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div className="submitButton">
                  {error && <div style={{ color: "red" }}>{error}</div>}
                  <br />
                  <button type="submit" className="submitBtn">Submit</button>
                </div>
              </form>

              <div className="closeModalBtn" id="closeModalBtn" onClick={() => toggleModal()}>
                <img src="/closemodal.png" alt="Close Modal" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
