export default function WhyConcept2(props) {
    return (
        <>
            <div className="div-shred">
                <img
                    loading="lazy"
                    src="/backgroundConcept.jpg"
                    className="img-shred"
                />
                <div className="div-shred-2">
                    <div className="div-shred-3">
                        <img
                            loading="lazy"
                            src="/concepther.png"
                            className="img-shred-2"
                        />
                        <div className="div-shred-4">
                            <div className="div-shred-5">
                                <a href="#" className="atag1">
                                    STEP 1
                                </a>
                                <span style={{ color: "rgba(47,47,47,1);" }}> </span>
                            </div>
                            <div className="div-shred-6">WE EDUCATE YOU</div>
                        </div>
                        <div className="div-shred-7">
                            <div className="div-shred-8">
                                <a href="#" className="atag1">
                                    STEP 2
                                </a>
                            </div>
                            <div className="div-shred-9">WE TRAIN YOU</div>
                        </div>
                        <div className="div-shred-10">
                            <div className="div-shred-11">
                                <a href="#" className="atag1">
                                    STEP 3
                                </a>
                            </div>
                            <div className="div-shred-12">
                                WE customize a meal plan
                                <br />
                                for you
                            </div>
                        </div>
                        <div className="div-shred-13">
                            <div className="div-shred-11">
                                <a href="#" className="atag1">
                                    STEP 4
                                </a>
                            </div>
                            WE measure your progress</div>
                        <div className="div-shred-14">
                            <div className="div-shred-11">
                                <a href="#" className="atag1">
                                    STEP 5
                                </a>
                            </div>
                            WE follow up with you</div>
                        <img
                            loading="lazy"
                            src="./pink.png"
                            className="img-shred-3"
                        />
                        <div className="div-shred-15">YOU ARE MOTIVATED</div>
                        <div className="div-shred-16">YOU SEE PROGRESS</div>
                        <div className="div-shred-17">YOU ARE COMMITTED</div>
                        <div className="div-shred-18">YOU BELONG TO A COMMUNITY</div>
                        <img
                            loading="lazy"
                            src="./pink.png"
                            className="img-shred-4"
                        />
                        <div className="div-shred-19">YOU GET RESULTS</div>
                    </div>
                    <div className="div-shred-20">VS.</div>
                    <div className="div-shred-21">
                        <div className="div-shred-22">
                            OTHER
                            <br />
                            PROGRAMS
                        </div>
                        <div className="div-shred-23">YOU TRAIN</div>
                        <img
                            loading="lazy"
                            src="./pink.png"
                            className="img-shred-5"
                        />
                        <div className="div-shred-24">AFTER 1 WEEK </div>
                        <div className="div-shred-25">NO MOTIVATION </div>
                        <div className="div-shred-26">NO PROGRESS</div>
                        <div className="div-shred-27">YOU GET BORED </div>
                        <div className="div-shred-28">TRAIN ALONE </div>
                        <div className="div-shred-29">LOSE COMMITMENT </div>
                        <img
                            loading="lazy"
                            src="./pink.png"
                            className="img-shred-6"
                        />
                        <div className="div-shred-30">NO RESULTS</div>
                    </div>
                </div>
            </div >
            <style jsx>{`
        .div-shred {
          disply: flex;
          flex-direction: column;
          align-items: center;
          overflow: hidden;
          position: relative;
          display: flex;
          min-height: 795px;
          padding: 0 20px;
        }
        .img-shred {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
        .div-shred-2 {
          position: relative;
          display: flex;
          width: 866px;
          max-width: 100%;
          align-items: end;
          justify-content: space-between;
          gap: 20px;
          margin: 49px 0 40px;
        }
        @media (max-width: 991px) {
          .div-shred-2 {
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 40px;
          }
        }
        .div-shred-3 {
          display: flex;
          margin-top: 21px;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
        }
        .img-shred-2 {
          aspect-ratio: 4.25;
          object-fit: contain;
          object-position: center;
          width: 255px;
          max-width: 255px;
          overflow: hidden;
          align-self: center;
        }
        .div-shred-4 {
          align-items: center;
          border-radius: 15px;
          border: 2px solid var(--www-aos-fit-black-70, rgba(0, 0, 0, 0.7));
          box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
          background-color: var(--www-aos-fit-nero-02, rgba(255, 255, 255, 0));
          align-self: stretch;
          display: flex;
          margin-top: 20px;
          flex-direction: column;
          padding: 7px 64px 7px 65px;
        }
        @media (max-width: 991px) {
          .div-shred-4 {
            padding: 0 20px;
          }
        }
        .atag1 {
            color: rgba(255, 0, 203, 0.85);
            text-decoration: none;
        }
        .atag1:hover {
                border-bottom: 2px solid  rgba(255, 0, 203, 0.85);
        }
        .div-shred-5 {
          color: var(--www-aos-fit-red, #e00);
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          font: 700 15px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-5 {
            white-space: initial;
          }
        }
        .div-shred-6 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          text-transform: uppercase;
          align-self: stretch;
          white-space: nowrap;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-6 {
            white-space: initial;
          }
        }
        .div-shred-7 {
          align-items: center;
          border-radius: 15px;
          border: 2px solid var(--www-aos-fit-black-70, rgba(0, 0, 0, 0.7));
          box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
          background-color: var(--www-aos-fit-nero-02, rgba(255, 255, 255, 0));
          align-self: stretch;
          display: flex;
          margin-top: 15px;
          flex-direction: column;
          padding: 7px 75px 7px 76px;
        }
        @media (max-width: 991px) {
          .div-shred-7 {
            padding: 0 20px;
          }
        }
        .div-shred-8 {
          color: var(--www-aos-fit-red, #e00);
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-8 {
            white-space: initial;
          }
        }
        .div-shred-9 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          text-transform: uppercase;
          align-self: stretch;
          white-space: nowrap;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-9 {
            white-space: initial;
          }
        }
        .div-shred-10 {
          align-items: center;
          border-radius: 15px;
          border: 2px solid var(--www-aos-fit-black-70, rgba(0, 0, 0, 0.7));
          box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
          background-color: var(--www-aos-fit-nero-02, rgba(255, 255, 255, 0));
          align-self: stretch;
          display: flex;
          margin-top: 15px;
          flex-direction: column;
          padding: 7px 22px 7px 23px;
        }
        @media (max-width: 991px) {
          .div-shred-10 {
            padding: 0 20px;
          }
        }
        .div-shred-11 {
          color: var(--www-aos-fit-red, #e00);
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-11 {
            white-space: initial;
          }
        }
        .div-shred-12 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          text-transform: uppercase;
          align-self: stretch;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-shred-13 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          border-radius: 15px;
          border: 2px solid var(--www-aos-fit-black-70, rgba(0, 0, 0, 0.7));
          box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
          background-color: var(--www-aos-fit-nero-02, rgba(255, 255, 255, 0));
          align-self: stretch;
          margin-top: 10px;
          padding: 10px 17px 7px;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-13 {
            white-space: initial;
          }
        }
        .div-shred-14 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          border-radius: 15px;
          border: 2px solid var(--www-aos-fit-black-70, rgba(0, 0, 0, 0.7));
          box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
          background-color: var(--www-aos-fit-nero-02, rgba(255, 255, 255, 0));
          align-self: stretch;
          margin-top: 9px;
          padding: 10px 20px 7px;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-14 {
            white-space: initial;
          }
        }
        .img-shred-3 {
          aspect-ratio: 0.27;
          object-fit: contain;
          object-position: center;
          width: 15px;
          max-width: 100%;
          overflow: hidden;
          align-self: center;
          margin-top: 10px;
        }
        .div-shred-15 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          align-self: center;
          margin-top: 9px;
          white-space: nowrap;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-15 {
            white-space: initial;
          }
        }
        .div-shred-16 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-16 {
            white-space: initial;
          }
        }
        .div-shred-17 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-17 {
            white-space: initial;
          }
        }
        .div-shred-18 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-18 {
            white-space: initial;
          }
        }
        .img-shred-4 {
          aspect-ratio: 0.27;
          object-fit: contain;
          object-position: center;
          width: 15px;
          max-width: 100%;
          overflow: hidden;
          align-self: center;
          margin-top: 10px;
        }
        .div-shred-19 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          align-self: center;
          margin-top: 15px;
          white-space: nowrap;
          font: 700 18px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-19 {
            white-space: initial;
          }
        }
        .div-shred-20 {
          color: rgba(255, 0, 203, 0.85);
          text-align: center;
          align-self: center;
          margin: auto 40px;
          font: 500 29px/42px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
            .div-shred-20 {
              width: 100%;
              margin: auto 0px;
              white-space: initial;
            }
          }
        .div-shred-21 {
          align-self: start;
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
        }
        .div-shred-22 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          font: 700 30px/42px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-shred-23 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          border-radius: 15px;
          border: 2px solid var(--www-aos-fit-black-70, rgba(0, 0, 0, 0.7));
          box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
          background-color: var(--www-aos-fit-nero-02, rgba(255, 255, 255, 0));
          align-self: stretch;
          margin-top: 39px;
          padding: 7px 20px;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-23 {
            white-space: initial;
          }
        }
        .img-shred-5 {
          aspect-ratio: 0.27;
          object-fit: contain;
          object-position: center;
          width: 15px;
          max-width: 100%;
          overflow: hidden;
          align-self: center;
          margin-top: 40px;
        }
        .div-shred-24 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          align-self: center;
          margin-top: 39px;
          white-space: nowrap;
          font: 700 15px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-24 {
            white-space: initial;
          }
        }
        .div-shred-25 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          align-self: center;
          margin-top: 19px;
          white-space: nowrap;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-25 {
            white-space: initial;
          }
        }
        .div-shred-26 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-26 {
            white-space: initial;
          }
        }
        .div-shred-27 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-27 {
            white-space: initial;
          }
        }
        .div-shred-28 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-28 {
            white-space: initial;
          }
        }
        .div-shred-29 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 700 14px/19.5px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-29 {
            white-space: initial;
          }
        }
        .img-shred-6 {
          aspect-ratio: 0.27;
          object-fit: contain;
          object-position: center;
          width: 15px;
          max-width: 100%;
          overflow: hidden;
          align-self: center;
          margin-top: 40px;
        }
        .div-shred-30 {
          color: var(--www-aos-fit-mine-shaft, #2f2f2f);
          text-align: center;
          align-self: center;
          margin-top: 40px;
          white-space: nowrap;
          font: 700 19px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-shred-30 {
            white-space: initial;
          }
        }
      `}</style>
        </>
    );
}