
import Header from "../components/header2";
import RevivalHeader from "../components/revivalHeader";
import BookOffer3 from '../components/bookRevival'
import RevivalBody from "../components/revivalBody"
import React, { useState, useEffect } from 'react';
const Revival = () => {
  const color = 'red';
  const [enteredSpot, setEnteredSpot] = useState(0);
  const [wholeDate, setWholeDate] = useState(null);
  const [revivalData, setRevivalData] = useState(null);
  const [revivalTitle, setRevivalTitle] = useState(null);
  const [revivalProgramName, setRevivalProgramName] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl1 = `https://www.aos.fit/api/getalldata`;
        let allData = await fetch(apiUrl1);
        const allDataResult = await allData.json();
        setEnteredSpot(allDataResult['revivalSpot'])
        setWholeDate(allDataResult['revivalTimestamp'])
        setRevivalData(allDataResult['revivalData'])
        setRevivalTitle(allDataResult['revivalTitle'])
        setRevivalProgramName(allDataResult['revivalProgramName'])

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="div-c">
        <Header />
        <RevivalHeader color='rgb(243, 20, 0);' revivalTitle={revivalTitle} revivalProgramName={revivalProgramName} />
        <br />
        <RevivalBody data={revivalData} />
        <BookOffer3 type={'revival'} spot={enteredSpot} wholeDate={wholeDate} />
      </div >
      <style jsx>{`
        .div-c {
          display: flex;
          flex-direction: column;
        }
        .videogroup {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // height: 100vh; 
            margin-bottom: 20px;
            margin-top: 20px;
          }
          
          .videogroup iframe {
            width: 500px;
            height: 300px;
          }
          @media only screen and (max-width: 767px) {
            .videogroup iframe {
              width: 90%; 
              height: 300px; /* Preserve the aspect ratio */
            }
          }
      `}</style>
    </>
  );
};

export default Revival;
