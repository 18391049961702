import React, { useEffect, useRef, useState } from 'react';

export default function Component({url}) {
    const videoRef = useRef(null);
    const [transform, setTransform] = useState([-12,0]);
    const [marginBottom, setMarginBottom] = useState(calculateMarginBottom(window.innerWidth));
    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.scrollY; 
        const isMobile = window.matchMedia("(max-width: 767px)").matches;
        let degree = ((scrollPosition) / 320) * 12 - 12;
        degree = Math.max(Math.min(degree, 0), -12);
        let scale = isMobile ? 1 : 1 + ((scrollPosition) / 320) * 0.5;
        scale = Math.max(Math.min(scale, 1.5), 1);
        setTransform([degree, scale]);
      };
    
      window.addEventListener('scroll', handleScroll);
    
      return () => window.removeEventListener('scroll', handleScroll);

      
    }, []);
    useEffect(() => {
      // Update the marginBottom whenever the window is resized
      const handleResize = () => {
        setMarginBottom(calculateMarginBottom(window.innerWidth));
      };
  
      window.addEventListener("resize", handleResize);
  
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    function calculateMarginBottom(width) {
      // Map the width range 300 to 1690 to the margin range 0 to -400
      const minMargin = 0;
      const maxMargin = -400;
      const minWidth = 300;
      const maxWidth = 1690;
  
      if (width <= minWidth) return minMargin;
      if (width >= maxWidth) return maxMargin;
  
      // Linear interpolation to find the margin for the current width
      return ((maxWidth - width) / (maxWidth - minWidth)) * (minMargin - maxMargin) + maxMargin;
    }
  return (
    <>
      <section
        className="text-light content-wysiwyg content-textarea content-code next-has-stripes has-stripes"
        style={{
          boxSizing: "border-box",

          overflow: "visible",
          position: "relative",
          padding: "0px 0px",
          lineHeight: 1.2,
          fontWeight: 300,
          paddingBottom: "0px",
          color: "rgb(248, 249, 250)",

        }}
      >
                <div className="slanted-bg">
        <div className="background-video">
          <video autoPlay loop muted playsInline>
            <source src="final.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="video-overlay"></div>
        </div>
        </div>
        <div
          className="stripes"
          style={{
            boxSizing: "border-box",
            width: "100%",
            marginLeft: "0px",
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gridTemplateRows: "repeat(10, 1fr)",
            transformOrigin: "0px center",
            position: "absolute",
            bottom: "0px",
            transform: "skewY(-11deg)",
            height: "31vw",
            marginBottom: "-15.5vw",
          }}
        >
          <div className="stripes-bg" style={{ boxSizing: "border-box" }} />

        </div>
 
        <div
          className="container01"
          style={{
            boxSizing: "border-box",
            width: "100%",
            paddingRight: "15px",
            paddingLeft: "15px",
            marginRight: "auto",
            marginLeft: "auto",
            maxWidth: "1366px",
            position: "relative",
            
          }}
        >
          <div
            className="row justify-content-center"
            style={{
              boxSizing: "border-box",
              display: "flex",
              flexWrap: "wrap",
              marginRight: "-15px",
              marginLeft: "-15px",
              WebkitBoxPack: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="col-12 col-md-10 col-lg-10"
              style={{
                boxSizing: "border-box",
                paddingTop: "90px",
                width: "100%",
                minHeight: "1px",
                paddingRight: "15px",
                paddingLeft: "15px",
                flex: "0 0 83.333%",
                maxWidth: "83.333%",
              }}
            >
              <p
                className="nheader"
              >
            LOSE UP TO 10% OF YOUR BODYWEIGHT IN JUST 21 DAYS !
                <span
                  style={{
                    color: 'rgb(47,110,186)'
                  }}
                >
                   FOR FREE
                </span>
              </p>
            </div>
            <div
              className="col-12 col-md-10 col-lg-9 col-xl-8 mb-5 text-center"
              style={{
                boxSizing: "border-box",
                position: "relative",
                width: "100%",
                minHeight: "1px",
                paddingRight: "15px",
                paddingLeft: "15px",
                flex: "0 0 66.667%",
                WebkitBoxFlex: "0",
                maxWidth: "66.667%",
                marginBottom: "4rem",
                textAlign: "center",
                fontWeight: 800
              }}
            >
              <p
                className="mb-0"
                style={{
                  boxSizing: "border-box",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
      Our Free Program Delivers More Value Than Any Paid Option!
              </p>
            </div>

            <div
              className="col-12 null"
              style={{
                boxSizing: "border-box",
                position: "relative",
                width: "100%",
                minHeight: "1px",
                paddingRight: "15px",
                paddingLeft: "15px",
                flex: "0 0 100%",
                WebkitBoxFlex: "0",
                maxWidth: "100%",
                
              }}
            >
              <div
                className="hero-video"
                style={{
                  boxSizing: "border-box",
                  height: "0px",
                  width: "100%",
                  position: "relative",
                  paddingBottom: "56.25%",
                  marginTop: "4rem",
                  marginBottom: `${marginBottom}px` ,
                }}
              >

                <br />
                <div
                  // className="wistia-embed"
                  style={{
                    boxSizing: "border-box",
                    cursor: "pointer",
                    transition: "width 0s ease 0s, min-width 0s ease 0s",
                    position: "absolute",
                    top: "0px",
                    left: "50%",
                    transform: "translate(-50%)",
                    maxWidth: "100%",
                    width: "653px",
                  }}
                >
                  <br />
                  <div
                    // className="wistia-embed__inner"
                    style={{
                      boxSizing: "border-box",
                      height: "0px",
                      width: "100%",
                      paddingBottom: "56.25%",
                      overflow: "hidden",
                      transition:
                        "transform 0s ease 0s, -webkit-transform 0s ease 0s",
                      position: "relative",
                      boxShadow:
                        "rgba(0, 0, 0, 0.2) 0px 15px 30px, rgba(0, 0, 0, 0.2) 0px 5px 10px",
                      borderRadius: "20px",
                      transform: `skewY(${transform[0]}deg) scale(${transform[1]}) `,
                    }}
                  >
                  {/* <video controls>
                    <source src='Landing-Page-Video.mp4' type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> */}
                    {/* <video
                    controls
                      src="Landing-Page-Video.mp4"
                      allowfullscreen
                      style={{
                        boxSizing: "border-box",
                        background: "rgb(0, 0, 0)",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        objectFit: "cover",
                        marginBottom: "-10px",
                        transition: "opacity 0.6s ease 0s",
                        opacity: 1,
                      }}
                      /> */}
<iframe
  src={url}
  allowFullScreen
  style={{
    boxSizing: "border-box",
    background: "rgb(0, 0, 0)",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0px",
    left: "0px",
    objectFit: "cover",
    marginBottom: "-10px",
    transition: "opacity 0.6s ease 0s",
    opacity: 1,
    border: "none"
  }}
/>

                    <div
                      className="wistia-embed__time"
                      style={{
                        boxSizing: "border-box",
                        transition: "opacity 0.3s ease 0s",
                        fontFamily: '"Courier New", Courier, sans-serif',
                        fontSize: "22px",
                        left: "0px",
                        position: "absolute",
                        textAlign: "center",
                        width: "100%",
                        top: "62%",
                        color: "rgb(255, 255, 255)",
                        pointerEvents: "none",
                        opacity: 0,
                      }}
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <style
      >{`

                  .h1 {
            font-size: 4rem;
          }
      @media (max-width: 768px) {
          .h1 {
            font-size: 4rem;
          }
}
html {
  box-sizing: border-box;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  font-family: Inter, sans-serif;
  font-weight: 400;
  user-select: none;
  font-size: 62.5%;
}

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1;
  }
        .container {
          box-sizing: border-box;
          width: 100%;
          padding: 80px 15px 0;
          position: relative;
          z-index: 0;
        }
        .background-video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          overflow: hidden;
        }

        .background-video video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .slanted-bg {
          position: absolute;
          top: -110px;
          left: 0;
          width: 100%;
          height: 900px;
          background-color: white;
          transform: skewY(-8deg);
          z-index: -1; /* Behind content */
        }

body {
  box-sizing: border-box;
  margin: 0px;
  color: rgb(0, 0, 0);
  text-align: left;
  background-color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 3.2rem;
  overflow-x: hidden;
}
      .nheader {
        width: 75%;
        text-align: center;
        font-family: "Futura LT Condensed", sans-serif;
        font-size: 4rem;
        padding: 10px;
        font-weight: 900;
        color: rgb(248, 249, 250);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      @media (max-width: 768px) {
            .nheader { 
            width: 100%;
              font-size: 27px;
            }
                      .slanted-bg {
          top: -30px;
          height: 600px;
        }
      } 
      
      `} </style> 
    </>
  );
}


