import React from 'react';
import Form from '../form';
import { useState, useEffect } from 'react';
const CustomButton = ({ onClick, buttonString, percent }) => (
<button  onClick={onClick}  className="cta-button">BOOK YOUR SPOT</button>
);

export default function Video1({url, wholeDate}) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [wholeDates, setWholeDates] = useState(0);
  useEffect(() => {
    console.log(wholeDate, 'whole date33333333')
    const currentDate = new Date();
    const differenceInMilliseconds = wholeDate - currentDate;
    let day = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    let hour = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minute = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    day = Math.max(day, 0);
    hour = Math.max(hour, 0);
    minute = Math.max(minute, 0);
    console.log(day, hour, minute)
    setDays(day)
    setHours(hour)
    setMinutes(minute)
    setWholeDates(wholeDate)
  }, [wholeDate]);


  useEffect(() => {
    const timerInterval = setInterval(() => {
      setMinutes(prevMinutes => {
        if (prevMinutes > 0) {
          return prevMinutes - 1;
        } else {
          setHours(prevHours => {
            if (prevHours > 0) {
              setMinutes(59);
              return prevHours - 1;
            } else {
              setDays(prevDays => {
                if (prevDays > 0) {
                  setHours(23);
                  setMinutes(59);
                  return prevDays - 1;
                } else {
                  clearInterval(timerInterval);
                  return 0;
                }
              });
            }
          });
        }
      });
    }, 60000);

    return () => clearInterval(timerInterval);
  }, [days, hours, minutes]);
  return (
    <>
      <section className="promo-section1">

            <br/>
            <br/>
            <h2 className="promo-subtitle1">WHAT OUR PILOT GROUP </h2>
            <h2 className="promo-subtitle1">HAVE TO SAY ABOUT THE PROGRAM </h2>
            <br/>

          <div className="video-wrapper">
            <div className="video-container">
              <iframe
                className="video-player"
                src={url}
                title="YouTube video"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className="countdown">
            <div className="timer">
            <span>{days >= 10 ? days : `0${days || 0}`}</span> : <span>{hours >= 10 ? hours : `0${hours || 0}`}</span> : <span>{minutes >= 10 ? minutes : `0${minutes || 0}`}</span>
            </div>
          </div>

          <Form  type={'rockClimbing'}  buttonComponent={(toggleModal) => <CustomButton buttonString="BOOK YOUR SPOT IN THIs CRAZY OFFER" onClick={toggleModal} />} />

          <br/>
          <br/>
 

        <div className="slanted-bg"></div>
      </section>

      <style>{`
        .promo-section1 {
          overflow: hidden;
          text-align: center;
          color: white;
          font-family: 'Calibri', sans-serif;
        }

        .colored-spacer {
          height: 0.3rem;
        }


        .promo-subtitle1 {
              color: #ff6a00;
              font-size: 2.5rem;
              font-weight: 600;
              text-align: center;
              margin-bottom: 2rem;
              font-family: 'Futura XBlk BT', sans-serif;
              margin: 0;
        }

        .promo-tagline {
          font-size: 2rem;
          margin: 0px 0;
          font-weight: bold;
          font-family: 'Siro-Bold', sans-serif;
        }


       .video-wrapper {
          margin: 20px auto;
          max-width: 800px;
          width: 100%;
        }

       .video-container {
          position: relative;
          width: 100%;
          padding-bottom: 56.25%; /* 16:9 aspect ratio */
          height: 0;
          overflow: hidden;
        }

     .video-container iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .countdown {
          font-family: 'Siro-Bold', sans-serif;
          color: black;
          font-weight: bold;
          font-size: 1.5rem;
          margin: 20px 0;
        }

        .countdown p {
          font-family: 'Calibri', sans-serif;
        }

        .timer {
          font-size: 2rem;
          color: #ff6a00;
        }

        .timer span {
          margin: 0 5px;
        }

        .cta-button {
          background-color: #ff6a00;
          color: white;
          padding: 15px 30px;
          border: none;
          font-weight: bold;
          font-size: 1.5rem;
          cursor: pointer;
          font-family: 'Calibri', sans-serif;
        }

        .cta-button:hover {
          background-color: #e65a00;
        }

        @media (max-width: 1024px) {
          .video-container {
            max-width: 600px; 
          }
        }

        @media (max-width: 768px) {
        .promo-subtitle1 {
                font-size: 1.5rem;
              margin-bottom: 0.5rem;
          }

          .promo-tagline {
            font-size: 1rem;
          }

          // .timer {
          //   font-size: 1.5rem;
          // }

          .video-container {
            max-width: 90%;
            padding-bottom: 56.25%;
              margin: 0 auto;
          }

          .cta-button {
            font-size: 1.2rem;
            padding: 10px 20px;
          }
        }
      `}</style>
    </>
  );
}
