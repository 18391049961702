import Form from './form';
const CustomButton = ({ onClick }) => (
  <button onClick={onClick} className="button">BOOK YOUR FREE ASSESMENT</button>
  // <button
  //     onClick={onClick}
  //     className="justify-center text-white text-center text-xl font-bold leading-7 uppercase whitespace-nowrap items-center border shadow-sm bg-red-600 self-center w-[284px] max-w-full mt-8 px-5 py-4 rounded border-solid border-black border-opacity-20 hover:cursor-pointer"
  // >
  //     BOOK your 35% OFFER
  // </button>
);
export default function BodyAnalysis({ type, youtubeUrl1, spot }) {
  return (
    <>
      <div className="blackText">
        <br />
        <div >Start your</div>
        <a href="#" className="hover-link">TRANSFORMATION</a>
        <div className="blackText">with Our</div>
        <div className="div-6">
          <a href="#" className="hover-link">FREE  </a>
          <div className="blackText">-</div>
          <a href="#" className="hover-link" > 360 Body Analysis</a>

        </div>
      </div>

      <div className="div-10">
        <div className="div-14">Unlock&nbsp; </div>
        <a href="#" className="hover-link-2">YOUR</a>
        <div className="div-14">
          &nbsp;Fitness Blueprint in Just 30 Minutes: Avoid
        </div>
      </div>
      <div className="div-14">
        injuries, Kill the boredom and stay on track !
      </div>
      <div className="div-15">
        <div className="div-16">Create&nbsp; </div>
        <div href="#" className="hover-link-2 ">YOUR</div>
        <div className="div-18">
          &nbsp;Personal Roadmap to Body Transformation.
        </div>
      </div>
      <div className="div-19">
        <iframe
          src={youtubeUrl1}
          allowFullScreen
          id="fitvid987288"
          style={{ width: '100%', height: '100%', top: '0', left: '0' }}
        ></iframe>
      </div>
      <div className="div-29">
        <div className="div-30">ONLY</div>
        <a href="#" className="hover-link-2">{spot || 0} SPOTS</a>
        <div className="div-32">AVAILABLE</div>
        <a href="#" className="hover-link-2">!</a>
      </div>
      <br />
      <Form type={type} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
      {/* <button className="button">BOOK YOUR FREE ASSESMENT</button> */}
      <style jsx>{`
        .div {
          display: flex;
          flex-direction: column;
        }
        .img {
          aspect-ratio: 2.69;
          object-fit: contain;
          object-position: center;
          width: 70px;
          overflow: hidden;
          max-width: 100%;
        }
        .blackText {
          justify-content: center;
          color: #2d2d2d;
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 700 30px/135% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-3 {
            white-space: initial;
          }
        }
        .div-4 {
          justify-content: center;
          color: #00e;
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 500 30px/131% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-4 {
            white-space: initial;
          }
        }
        .div-5 {
          justify-content: center;
          color: #2d2d2d;
          text-align: center;
          align-self: center;
          margin-top: 7px;
          white-space: nowrap;
          font: 500 29px/133% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-5 {
            white-space: initial;
          }
        }
        .div-6 {
          align-self: center;
          display: flex;
          // margin-top: 17px;
          width: 355px;
          max-width: 100%;
          align-items: start;
          gap: 7px;
        }
        @media (max-width: 991px) {
          .div-6 {
            justify-content: center;
          }
        }
        .div-7 {
          justify-content: center;
          color: #00e;
          text-align: center;
          font: 500 30px/130% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-8 {
          justify-content: center;
          color: #2d2d2d;
          text-align: center;
          align-self: center;
          margin: auto 0;
          font: 500 30px/130% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-9 {
          justify-content: center;
          color: #00e;
          text-align: center;
          align-self: stretch;
          white-space: nowrap;
          font: 500 29px/134% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-9 {
            white-space: initial;
          }
        }
        .div-10 {
          align-self: center;
          display: flex;
          margin-top: 18px;
          width: 525px;
          max-width: 100%;
          gap: 1px;
        }
        @media (max-width: 991px) {
          .div-10 {
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .div-11 {
          justify-content: center;
          color: #2d2d2d;
          text-align: center;
          align-self: start;
          font: 700 20px/130% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        // .div-12 {
        //   justify-content: center;
        //   color: #00e;
        //   text-align: center;
        //   align-self: start;
        //   font: 700 18px/142% Inter, -apple-system, Roboto, Helvetica,
        //     sans-serif;
        // }
        .hover-link-2 {
          justify-content: center;
          color: #00e;
          text-align: center;
          align-self: start;
          font: 700 19px/142% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
          color: rgb(0, 0, 238);
          text-decoration: none;
          transition: border-color 0.3s;
          border-bottom: 1px solid transparent;
        }
        
        .hover-link-2:hover {
          border-bottom: 3px solid rgb(0, 0, 238);
        }
        .div-13 {
          justify-content: center;
          color: #2d2d2d;
          text-align: center;
          white-space: nowrap;
          font: 700 19px/135% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-13 {
            white-space: initial;
          }
        }
        .div-14 {
          justify-content: center;
          color: #2d2d2d;
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 700 19px/135% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-14 {
            white-space: initial;
          }
        }
        .div-15 {
          align-self: center;
          display: flex;
          margin-top: 27px;
          width: 528px;
          max-width: 100%;
          gap: 1px;
        }
        @media (max-width: 991px) {
          .div-15 {
            flex-wrap: wrap;
            justify-content: center;
          }
        }
        .div-16 {
          justify-content: center;
          color: #2d2d2d;
          text-align: center;
          align-self: start;
          font: 700 20px/130% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-18 {
          justify-content: center;
          color: #2d2d2d;
          text-align: center;
          white-space: nowrap;
          font: 700 19px/135% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-18 {
            white-space: initial;
          }
        }
        .div-19 {
          justify-content: center;
          background-color: #000;
          align-self: center;
          display: flex;
          margin-top: 27px;
          width: 590px;
          height: 330px;
          max-width: 100%;
          flex-direction: column;
        }
        .div-29 {
          align-self: center;
          display: flex;
          margin-top: 20px;
          width: 280px;
          max-width: 100%;
          gap: 6px;
        }
        @media (max-width: 991px) {
          .div-29 {
            justify-content: center;
          }
        }
        .div-30 {
          justify-content: center;
          color: #000;
          text-align: center;
          font: 700 18px/145% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-32 {
          justify-content: center;
          color: #000;
          text-align: center;
          font: 700 19px/138% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .hover-link {
          color: rgb(0, 0, 238);
          text-decoration: none;
          transition: border-color 0.3s;
          border-bottom: 1px solid transparent;
        } 
        @media (max-width: 331px) {
          .blackText {
            width: 280px;
            font:700 20px/135% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
          }
        }
        
        .hover-link:hover {
          border-bottom: 4px solid rgb(0, 0, 238);
        }
        .button {
          flex: 1;
          cursor: pointer;
          justify-content: center;
          color: #fff;
          text-align: center;
          text-transform: uppercase;
          align-items: center;
          border-radius: 3px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
          background-color: #00e;
          align-self: center;
          margin-top: 6px;
          width: 360px;
          max-width: 100%;
          padding: 10px 0px;
          font: 700 17px/154% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
      `}</style>
    </>
  );
}
