import * as React from "react";
import './aos.css'
export default function AOS({ img1, img2 }) {
  return (
    <>
      <div className="div-87">
        <h1 className="div-88">
          <b>
            ART OF SPORTS &quot;AOS&quot; is not just a fitness entity it's a
            journey
          </b>
        </h1>
        <div className="div-89">
          <div className="div-90">
            <div className="column-10">
              <img
                loading="lazy"
                src={img1}
                className="img-15"
              />
            </div>
            <div className="column-11">
              <img
                loading="lazy"
                srcSet={img2}
                className="img-16"
              />
            </div>
          </div>
        </div>
        <div className="div-91">
          AOS was established in 2016. Our service is the main reason that
          built our entity from nothing more than a pull-up bar  to become the most influential fitness entity in Sheikh Zayed.
        </div>
      </div>
    </>
  );
}