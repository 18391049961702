export default function Say({ video1, video2, video3, video4, review }) {
    return (
        <>
            <div className="justify-center text-zinc-800 text-center text-7xl-1 font-bold leading-[52px] self-center  mt-10 max-md:max-w-full max-md:mt-10">
                WHAT OUR ATHELETS HAVE TO SAY ABOUT US
            </div>
            <div className="self-center  w-[95%]  max-w-full mt-10 md:w-[1100px]">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="justify-center items-stretch  flex-grow flex-col w-full mx-auto max-md:max-w-full max-md:mt-10">
                            <div className=" flex flex-col items-stretch max-md:max-w-full">
                                <div className="flex-col overflow-hidden relative flex min-h-[295px] w-full  max-md:max-w-full">
                                    <iframe
                                        src={video1}
                                        className="w-full h-full"
                                        style={{ minHeight: '300px', minWidth: '362px', width: '100%' }}
                                        allowFullScreen=""
                                        wmode="opaque"
                                        id="fitvid254987"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="justify-center items-stretch  flex grow flex-col w-full mx-auto max-md:max-w-full max-md:mt-10">
                            <div className=" flex flex-col items-stretch max-md:max-w-full">
                                <div className="flex-col overflow-hidden relative flex min-h-[295px] w-full  max-md:max-w-full">
                                    <iframe
                                        src={video2}
                                        style={{ minHeight: '250px', minWidth: '362px', width: '100%' }}
                                        className="w-full h-full" allowFullScreen="" wmode="opaque" id="fitvid703407"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="self-center  w-[95%]  max-w-full mt-10 md:w-[1100px]">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="justify-center items-stretch  flex grow flex-col w-full mx-auto max-md:max-w-full max-md:mt-10">
                            <div className=" flex flex-col w-full items-stretch max-md:max-w-full">
                                <div className="flex-col overflow-hidden relative flex min-h-[295px] w-full  max-md:max-w-full">
                                    <iframe
                                        src={video3}
                                        style={{ minHeight: '250px', minWidth: '362px', width: '100%' }}
                                        className="w-full h-full" allowFullScreen="" wmode="opaque" id="fitvid63661"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="justify-center items-stretch  flex grow flex-col w-full mx-auto max-md:max-w-full max-md:mt-10">
                            <div className=" flex flex-col items-stretch max-md:max-w-full">
                                <div className="flex-col overflow-hidden relative flex min-h-[295px] w-full  max-md:max-w-full">
                                    <iframe
                                        src={video4}
                                        style={{ minHeight: '250px', minWidth: '362px', width: '100%' }} allowFullScreen="" className="w-full h-full" wmode="opaque" id="fitvid219531"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img
                loading="lazy"
                src={review}
                className="aspect-[1.98] object-contain object-center  w-[95%] overflow-hidden self-center max-w-full mt-20 mb-20  md:w-[1100px] max-md:mt-10"
            />
        </>
    );
}


