const LandingPage = () => {
    return (
      <>
        <div className="landing-container">
          <div className="content-box">
            <h1 className="subtitle23">TRAIN FOR AIM</h1>
            
            <div className="questions-container">
              <p className="question-text">No Motivation to start Working out?</p>
              <p className="question-text">Stuck in life Routine?</p>
              <p className="question-text">Bored of Regular Workouts?</p>
              <p className="question-text">Craving More Excitement in Your Life<br />
                but Don't Know Where or How to Start?</p>
              <p className="question-text">Intimidated by new physical activities<br />
                due to lack of experience or confidence?</p>
              <p className="question-text">Want to be part of healthy minded community<br />
                Who share their passion for fitness and adventure?</p>
            </div>
          </div>
        </div>
        <style>
          {`
            .landing-container {
              background-color: #e2e4e9;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 2rem;
            }
            
            .content-box {
              padding: 0rem 0rem 2rem  0;
              max-width: 1000px;
              width: 100%;
            }
            
            .main-title {
              color: #ff6a00;
              font-size: 3.5rem;
              font-weight: bold;
              text-align: center;
              margin: 0;
              font-family: 'Futura XBlk BT', sans-serif;
            }
            
            .subtitle23 {
              color: #ff6a00;
              font-size: 2.5rem;
              font-weight: 600;
              text-align: center;
              margin-bottom: 2rem;
              font-family: 'Futura XBlk BT', sans-serif;
              margin: 0;
            }
            
            .question-text {
              color: #000;
              font-size: 1.5rem;
              text-align: center;
              margin: 3px;
              font-weight: 600;
              font-family: 'Siro-Bold', sans-serif;
            }
            
            .questions-container {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              max-width: 800px;
              margin: 0 auto;
            }
  
            @media (max-width: 768px) {
              .main-title {
                font-size: 2rem;
              }
              .content-box {
              padding: 0rem;

            }

            .subtitle23 {
              font-size: 1.5rem;
              margin-bottom: 0.5rem;
            }
            
              
              .subtitle {
                font-size: 2rem;
              }
              
              .question-text {
                font-size: 1rem;
              }
            }
          `}
        </style>
      </>
    );
  };
  
  export default LandingPage;