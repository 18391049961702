import Header from "../components/header2";
export default function ThankYou(props) {
    return (
        <>
            <div className="flex flex-col items-stretch  bg-gray-100">
                <Header />
                <div className="bg-white self-center flex w-[720px] max-w-full flex-col items-center md:px-5 md:py-12">
                    <div className="flex w-[300px] max-w-full flex-col items-stretch mt-8">
                        <img
                            loading="lazy"
                            src="/aosError.png"
                            className="aspect-[2.63] object-contain object-center w-[150px] overflow-hidden self-center max-w-full"
                        />
                        <div className="justify-center text-blue-800 text-center text-3xl font-bold leading-10 whitespace-nowrap mt-12 max-md:max-w-full max-md:mt-10">
                            THANK YOU FOR SIGNING UP !
                        </div>
                        <div className="justify-center text-zinc-800 text-opacity-70 text-center text-lg font-bold leading-6 self-center whitespace-nowrap mt-14 max-md:mt-10">
                            OUR TEAM WILL CONTACT YOU SHORTLY
                        </div>
                    </div>
                </div>
                <div className="justify-center text-neutral-800 text-center text-base leading-6 self-center whitespace-nowrap mt-9">
                    Copyright - AOS
                </div>
            </div>      <style jsx>{`
            html {
                background-color: #F1F4F8;
                /* color: #2f2f2f; */
            }
      `}</style>
        </>
    );
}