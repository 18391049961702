
import Header from "../components/header2";
import CircuitHeader from "../components/circuit";
import Confused from "../components/confused";
import HowItWorks from "../components/howItWorks";
import DidIt from "../components/didIt";
import FreeTrial from '../components/freeTrial';
import Community from '../components/community'
import Story from "../components/story";
import Say from "../components/say2";
import { FAQSection } from "../components/faq"
import CrazyOffer from "../components/crazyOffer2";
import FitnessComponent from "../components/fintnessComponent"
import HowItWorksComponent from "../components/howItWorks2"
import TimerComponent from "../components/gwlpTimer"
import React, { useState, useEffect } from 'react';
const GWLP = () => {
    const [imageUrl1, setImageUrl1] = useState('');
    const [imageUrl2, setImageUrl2] = useState('');
    const [imageUrl3, setImageUrl3] = useState('');
    const [imageUrl4, setImageUrl4] = useState('');
    const [imageGardenUrl, setImageGardenUrl] = useState('');
    const [imageYoussefUrl, setImageYoussefUrl] = useState('');
    const [imageGymUrl, setImageGymUrl] = useState('');
    const [imageAssesmentUrl, setImageAssesmentUrl] = useState('');
    const [imageJumpUrl, setImageJumpUrl] = useState('');
    const [imageAllPeopleUrl, setImageAllPeopleUrl] = useState('');
    const [imageGym1Url, setImageGym1Url] = useState('');
    const [imageReviews1Url, setImageReviews1Url] = useState('');
    const [imageCrazyOffer1Url, setImageCrazyOffer1Url] = useState('');
    const [gymDay, setGymDay] = useState(0);
    const [gymHours, setGymHours] = useState(0);
    const [gymMinutes, setGymMinutes] = useState(0);
    const [wholeDate, setWholeDate] = useState(null);
    const [youtubeUrl1, setYoutubeUrl1] = useState('');
    const [youtubeUrl2, setYoutubeUrl2] = useState('');
    const [youtubeUrl3, setYoutubeUrl3] = useState('');
    const [youtubeUrl4, setYoutubeUrl4] = useState('');
    const [youtubeUrl5, setYoutubeUrl5] = useState('');
    const [youtubeUrl6, setYoutubeUrl6] = useState('');
    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiUrl1 = `https://www.aos.fit/api/getalldata`;
                let allData = await fetch(apiUrl1);
                const allDataResult = await allData.json();
                setImageYoussefUrl(`https://www.aos.fit/api/public/${allDataResult['gwlp10']}`)
                setImageGardenUrl(`https://www.aos.fit/api/public/${allDataResult['gwlp1']}`)
                setImageGymUrl(`https://www.aos.fit/api/public/${allDataResult['gwlp11']}`)
                setImageAssesmentUrl(`https://www.aos.fit/api/public/${allDataResult['gwlp8']}`)
                setImageAllPeopleUrl(`https://www.aos.fit/api/public/${allDataResult['gwlp6']}`)
                setImageGym1Url(`https://www.aos.fit/api/public/${allDataResult['gwlp9']}`)
                setImageJumpUrl(`https://www.aos.fit/api/public/${allDataResult['gwlp7']}`)
                setImageUrl1(`https://www.aos.fit/api/public/${allDataResult['gwlp2']}`)
                setImageUrl2(`https://www.aos.fit/api/public/${allDataResult['gwlp3']}`)
                setImageUrl3(`https://www.aos.fit/api/public/${allDataResult['gwlp4']}`)
                setImageUrl4(`https://www.aos.fit/api/public/${allDataResult['gwlp5']}`)
                setImageCrazyOffer1Url(`https://www.aos.fit/api/public/${allDataResult['gwlpcrazyOffer1']}`)
                setImageReviews1Url(`https://www.aos.fit/api/public/${allDataResult['gwlpreviews1']}`)
                setYoutubeUrl1(`${allDataResult['gwlpyoutubeUrl1']}`)
                setYoutubeUrl2(`${allDataResult['gwlpyoutubeUrl2']}`)
                setYoutubeUrl3(`${allDataResult['gwlpyoutubeUrl3']}`)
                setYoutubeUrl4(`${allDataResult['gwlpyoutubeUrl4']}`)
                setYoutubeUrl5(`${allDataResult['gwlpyoutubeUrl5']}`)
                setYoutubeUrl6(`${allDataResult['gwlpyoutubeUrl6']}`)
                setGymDay(allDataResult['gwlpDay'])
                setGymHours(allDataResult['gwlpHour'])
                setGymMinutes(allDataResult['gwlpMinute'])
                setWholeDate(allDataResult['gwlpTimestamp'])
                console.log(allDataResult['gwlpTimestamp'], 'gwlpTimestamp')
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    let color = 'rgb(249 115 22)'
    return (
        <>
            <div className="div-c">
                <Header />
                <CircuitHeader isGlwp={true} type={'Glwp'} youtubeUrl1={youtubeUrl1} wholeDate={wholeDate} />

                < HowItWorksComponent />
                <Story youtubeUrl3={youtubeUrl2} />
                <TimerComponent type={'Glwp'} wholeDate={wholeDate} />
                <div className="justify-center items-center bg-black self-stretch flex w-full flex-col -mr-5 max-md:max-w-full">
                    <img
                        loading="lazy"
                        src={imageGardenUrl}
                        className="aspect-[1.73] object-contain object-center w-[1000px] overflow-hidden max-w-full mt-10 mb-10"
                    />
                </div>
                <Community type={'Glwp'} img1={imageUrl1} img2={imageUrl2} img3={imageUrl3} img4={imageUrl4} />
                <DidIt type={'Glwp'} img1={imageAllPeopleUrl} img2={imageJumpUrl} img3={imageAssesmentUrl} img4={imageGym1Url} img5={imageYoussefUrl} img6={imageGymUrl} />
                <Say youtubeUrl3={youtubeUrl3} youtubeUrl4={youtubeUrl4} youtubeUrl5={youtubeUrl5} youtubeUrl6={youtubeUrl6} wholeDate={wholeDate} />
                <FitnessComponent imageReviews1Url={imageReviews1Url} type={'Glwp'} />
                <CrazyOffer type={'Glwp'} imageCrazyOffer1Url={imageCrazyOffer1Url} />
                <FAQSection type={'Glwp'} />
                <Confused color={color} type={'Glwp'} />
            </div >
            <style jsx>{`
        // .CircuitHeader{
        //   width: 95%;
        //   align-items: center;
        //   align-content: center;
        // }
        // .CircuitHeader2 {
        //   align-items: center;
        //   align-content: center;
        // }
        .div-c {
          // display: flex;
          // flex-direction: column;
          // width: 90%;
        }
      `}</style>
        </>
    );
};

export default GWLP;
