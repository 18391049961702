
import Header from "../components/header2";
import DidIt from "../components/didIt2";
import Confused from "../components/confused";
import CrazyOffer from "../components/crazyOffer3";
import ConceptHeader from "../components/conceptHeader";
import BookOffer2 from "../components/bookOffer2";
import { FAQSection2 } from "../components/faq"
import WhyConcept from "../components/whyConcept";
import WhyConcept2 from "../components/whyConcept2";
import Concept from "../components/concept";
import Say from "../components/say4";
import { ConceptHerTrack } from "./fbTrack";
import React, { useState, useEffect } from 'react';

// ...


const ConceptHer = () => {

  const color = 'rgba(255, 0, 203, 0.85)';
  const [imageGardenUrl, setImageGardenUrl] = useState('');
  const [imageYoussefUrl, setImageYoussefUrl] = useState('');
  const [imageGymUrl, setImageGymUrl] = useState('');
  const [imageAssesmentUrl, setImageAssesmentUrl] = useState('');
  const [imageJumpUrl, setImageJumpUrl] = useState('');
  const [imageAllPeopleUrl, setImageAllPeopleUrl] = useState('');
  const [imageGym1Url, setImageGym1Url] = useState('');
  const [imageReviews1Url, setImageReviews1Url] = useState('');
  const [imageCrazyOffer1Url, setImageCrazyOffer1Url] = useState('');
  const [enteredSpot, setEnteredSpot] = useState(0);
  const [gymDay, setGymDay] = useState(0);
  const [gymHours, setGymHours] = useState(0);
  const [gymMinutes, setGymMinutes] = useState(0);
  const [wholeDate, setWholeDate] = useState(null);
  const [youtubeUrl1, setYoutubeUrl1] = useState('');
  const [youtubeUrl2, setYoutubeUrl2] = useState('');
  const [youtubeUrl5, setYoutubeUrl5] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl1 = `https://www.aos.fit/api/getalldata`;
        let allData = await fetch(apiUrl1);
        const allDataResult = await allData.json();
        setEnteredSpot(allDataResult['conceptSpot'])
        setImageAssesmentUrl(`https://www.aos.fit/api/public/${allDataResult['concept3']}`)
        setImageAllPeopleUrl(`https://www.aos.fit/api/public/${allDataResult['concept1']}`)
        setImageJumpUrl(`https://www.aos.fit/api/public/${allDataResult['concept2']}`)
        setImageCrazyOffer1Url(`https://www.aos.fit/api/public/${allDataResult['conceptcrazyOffer1']}`)
        setImageReviews1Url(`https://www.aos.fit/api/public/${allDataResult['conceptreviews1']}`)
        setYoutubeUrl1(`${allDataResult['conceptyoutubeUrl1']}`)
        setYoutubeUrl2(`${allDataResult['conceptyoutubeUrl2']}`)
        setYoutubeUrl5(`${allDataResult['conceptyoutubeUrl5']}`)
        setGymDay(allDataResult['conceptDay'])
        setGymHours(allDataResult['conceptHour'])
        setGymMinutes(allDataResult['conceptMinute'])
        setWholeDate(allDataResult['conceptTimestamp'])
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    ConceptHerTrack()
  }, [])

  return (
    <>
      <div className="div-c">
        <Header />
        <ConceptHeader />
        <div className="videogroup">
          <iframe src={youtubeUrl5} frameborder="0" allowfullscreen="" wmode="opaque" id="fitvid298745"></iframe>
          <BookOffer2 buttonString='true' type={'Concept Her'} spot={enteredSpot} wholeDate={wholeDate} />
        </div>
        <WhyConcept />
        <WhyConcept2 />
        <Concept type={'Concept Her'} />
        <DidIt type={'Concept Her'} img1={imageAllPeopleUrl} img2={imageJumpUrl} img3={imageAssesmentUrl} />
        <Say video1={youtubeUrl1} video2={youtubeUrl2} reviews={imageReviews1Url} />
        <FAQSection2 />
        <CrazyOffer img1={imageCrazyOffer1Url} type={'Concept Her'} />
        <div className="bg-[url('./images/backgroundConcept.jpg')]  grid place-items-center">
          <br />
          <br />
          <BookOffer2 type={'Concept Her'} wholeDate={wholeDate} spot={enteredSpot} />
          <br />
          <br />
          <br />
        </div>
        <Confused color={color} type={'Concept Her'} />
      </div >
      <style jsx>{`
        .div-c {
          display: flex;
          flex-direction: column;
        }
        .videogroup {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // height: 100vh; 
            margin-bottom: 20px;
            margin-top: 20px;
          }
          
          .videogroup iframe {
            width: 500px;
            height: 300px;
          }
          @media only screen and (max-width: 767px) {
            .videogroup iframe {
              width: 90%; 
              height: 300px; /* Preserve the aspect ratio */
            }
          }
      `}</style>
    </>
  );
};

export default ConceptHer;
