import React, { useState } from 'react';
import './confused.css'
import Form from './form';
const CustomButton = ({ onClick, color }) => (
  < button onClick={onClick} style={{ backgroundColor: color }} className="button" > BOOK YOUR FREE CONSULTATION</button >
);

export default function Confused({ color, type }) {
  return (
    <>
      <div className="div-109">
        <div className="div-110">
          <br />
          <a href="#" className="div-111">STILL CONFUSED ?</a>
          <a href="#" className="div-112">LET AOS TEAM HELP YOU</a>
          <a href="#" className="div-113">
            Claim Your FREE No-Obligation 30-Minute Consultation
          </a>
          <div className="div-114">
            During this no-obligation call, our experts will discuss your
            specific situation and
          </div>
          <div className="div-115">
            how we can help you achieve desired Goal in the shortest time
            possible.
          </div>
          <br />

          {type === 'ramadan' ? <br /> : <Form type={type} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} color={color} />} />}
          <br />
        </div>
      </div>
    </>
  );
}