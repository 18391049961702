import React, { useEffect, useRef, useState } from 'react';
import Form from '../form';


const CustomButton2 = ({ onClick, buttonString }) => (
  <button className="fut-button94" onClick={onClick}>
    {buttonString}
  </button>
);

export default function Component({ buttonString, spot, type, wholeDate }) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [wholeDates, setWholeDates] = useState(0);
  useEffect(() => {
    console.log(wholeDate, 'whole date222222')
    const currentDate = new Date();
    const differenceInMilliseconds = wholeDate - currentDate;
    let day = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    let hour = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minute = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    day = Math.max(day, 0);
    hour = Math.max(hour, 0);
    minute = Math.max(minute, 0);
    console.log(day, hour, minute)
    setDays(day)
    setHours(hour)
    setMinutes(minute)
    setWholeDates(wholeDate)
  }, [wholeDate]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setMinutes(prevMinutes => {
        if (prevMinutes > 0) {
          return prevMinutes - 1;
        } else {
          setHours(prevHours => {
            if (prevHours > 0) {
              setMinutes(59);
              return prevHours - 1;
            } else {
              setDays(prevDays => {
                if (prevDays > 0) {
                  setHours(23);
                  setMinutes(59);
                  return prevDays - 1;
                } else {
                  clearInterval(timerInterval);
                  return 0;
                }
              });
            }
          });
        }
      });
    }, 60000);

    return () => clearInterval(timerInterval);
  }, [days, hours, minutes]);
  return (
    <>
      <div className="container">
        <div className="content">
          <p className="nehader2">REAL CHANGE OFFER ENDS IN</p>
          <div className="timer1">
              <span>{days >= 10 ? days : `0${days || 0}`}</span> : <span>{hours >= 10 ? hours : `0${hours || 0}`}</span> : <span>{minutes >= 10 ? minutes : `0${minutes || 0}`}</span>
            </div>
          <div className="form-container">
            <Form 
              type={type} 
              buttonComponent={(toggleModal) => (
                <CustomButton2 buttonString="BOOK YOUR SPOT" onClick={toggleModal} />
              )}
            />
          </div>
        </div>
      </div>

      <style jsx>{`
        .container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 20px 15px 0 15px;
          margin: 0 auto;
          position: relative;
          z-index: 0;
          box-sizing: border-box;
        }

        .content {
          width: 100%;
          padding: 0 15px;
          text-align: center;
          box-sizing: border-box;
        }

        .fut-button94 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 350px;
          height: 100px;
          text-align: center;
          margin: -10px 0 50px;
          background-color: rgb(47, 110, 186);
          color: white;
          font-family: "Futura LT Condensed", sans-serif;
          font-weight: 900;
          font-size: 3rem;
          border: none;
          cursor: pointer;
        }

        .nehader2 {
          margin-top: 0;
          font-size: 4rem;
          font-weight: 900;
          text-transform: uppercase;
          letter-spacing: -0.08em;
          box-sizing: border-box;
        }

        .timer1 {
          font-family: "Futura LT Condensed", sans-serif;
          font-size: 4rem;
          color: rgb(47, 110, 186);
          padding: 10px;
          font-weight: 900;
          text-align: center;
        }

        .form-container {
          display: flex;
          justify-content: center;
        }

        @media (max-width: 768px) {
          .fut-button94 {
            width: 100%;
            height: 60px;
            font-size: 1.5rem;
          }

          .timer1 {
            font-size: 2rem;
            font-weight: 700;
          }

          .nehader2 {
            font-size: 2rem;
          }
        }


        @media (min-width: 768px) {
          .familyf, .familyf2, .p2 {
            color: rgb(47, 110, 186);
            box-sizing: border-box;
            margin-top: 0;
            font-weight: 900;
            font-family: "Futura LT Condensed", sans-serif;
            text-transform: uppercase;
            text-align: center;
            line-height: 0.94;
            margin-bottom: 2rem;
          }

          .container {
          padding: 120px 15px 0 15px;
          }

          .familyf {
            font-size: 4rem;
            letter-spacing: -0.04em;
          }

          .familyf2 {
            font-size: 4rem;
            font-weight: 600;
            line-height: 1.2;
          }

          .p2 {
            font-size: 3rem;
            letter-spacing: -0.04em;
          }

          .content {
          max-width: 60%;
          }
        }
      `}</style>
    </>
  );
}
