function FitnessProgram({ data }) {
    return (
        <div className="container mx-auto max-w-2xl">
            <div className="flex flex-col items-center py-12">
                <div class="text-center text-black  text-base " dangerouslySetInnerHTML={{ __html: data }} >
                </div>
            </div>
        </div>
    );
}

export default FitnessProgram;