import React from "react";
import Form from '../form';

const CustomButton2 = ({ onClick, buttonString }) => (
  <button className='fut-button' onClick={onClick}>{buttonString}</button>
);

export default function Component({ image1, image2, image3}) {
  return (
    <>
    <div className="component-container">
      <div className="content">
        <p className="familyf">
          After Experiencing All This Failure And Frustration I Decided To Do Something About It!!
        </p>
        <p className="main-paragraph">
          For 4 years, I immersed myself in studying and reading tons of research, articles, and books.
          <strong> Until I finally understood </strong>
          <strong className='p56'>THE ONLY REAL SYSTEM</strong> that helps you lose weight while eating everything you want, making you happy while on a diet. And oh boy, when I started to implement these strategies, I was able to make a huge transformation with 50% less effort than I used to.
        </p>
        <p className="main-paragraph">
          P.S I did this transformation 100% Natural with ZERO Supplements
        </p>
      </div>

      <div className="image-container">
        <img src={image1} alt="Image 1" className="image-item" />
        <img src={image2} alt="Image 2" className="image-item" />
        <img src={image3} alt="Image 3" className="image-item" />
      </div>

      <div className="content">
        <p className="familyf">
          Having Overcome This Challenge,
        </p>
        <p className="familyf234">
          I Thought, Why Not Make This Ability To Lose Weight, Get In Shape, And Tone Up While Eating Everything You Want Accessible To Everyone?
        </p>
        <p className="secondary-paragraph">
          So, I combined all my researches, years of coaching, and countless hours of learning into one solution, making fitness goals achievable and straightforward. By investing in this program, you’ll receive all the tips and techniques you need to lose weight, get fit, and tone up effortlessly.
        </p>
        <p className="secondary-paragraph">
          For the past 10 years, I have tried the system with thousands of people who wanted to transform, and it worked every single time. Today, I am revealing all my secrets in my new 21-day online program..
        </p>
      </div>
    </div>
 <style jsx>{`
.component-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 15px 0;
  box-sizing: border-box;
  z-index: 0;
}


.content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  padding: 0 20px;
  text-align: center;
}

.familyf, .familyf234, .p56 {
  font-family: "Futura LT Condensed", sans-serif;
  color: rgb(47,110,186);
  text-transform: uppercase;
  text-align: center;
}
  .secondary-paragraph {
  font-size: 2rem;
  width: 75%;
  }

.fut-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 100px;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 50px;
  background-color: rgb(47, 110, 186);
  color: white;
  font-family: "Futura LT Condensed", sans-serif;
  font-weight: 900;
  font-size: 3rem;
  border: none;
  cursor: pointer;
}

.image-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin: 20px 0;
}

.image-item {
  max-height: 500px;
  height: auto;
  margin: 0 10px;
}

.timer {
  text-align: center;
  font-family: "Futura LT Condensed", sans-serif;
  font-size: 4rem;
  color: rgb(47, 110, 186);
  padding: 10px;
  font-weight: 900;
}
      .main-paragraph {
    font-size: 2rem;
    width: 75%;
  }

@media (max-width: 768px) {
  .fut-button {
    width: 100%;
    height: 60px;
    font-size: 1rem;
  }


  .image-container {
    flex-direction: column;
    align-items: center;
  }

  .image-item {
    max-width: 250px;
    padding: 0px 20px;
    margin-bottom: 20px;
  }

  .familyf {
    font-size: 2.6rem;
    margin-bottom: 2rem;
  }

  .familyf234 {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }

  .p56 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

    .main-paragraph {
    font-size: 1.4rem;
    width: 100%;
  }

    .secondary-paragraph {
    font-size: 1.4rem;
    width: 100%;
  }


  p {
    font-size: 1.4rem;
  }
}

@media (min-width: 768px) {
  .familyf {
    width: 80%;
    font-size: 4rem;
    letter-spacing: -0.04em;
    margin-bottom: 2rem;
  }

  .familyf234 {
    width: 70%;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  p {
    font-size: 3rem;
  }

  .p56 {
    letter-spacing: -0.04em;
    margin-bottom: 2rem;
  }
}

  `}</style>

    </>
  );
}
