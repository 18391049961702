import Form from './form';
const CustomButton = ({ onClick }) => (
  <button className="button"  onClick={onClick}>BOOK your FREE ASSESSMENT</button>

);
export default function Atheletes({type , youtubeUrl3, youtubeUrl4 , youtubeUrl5})  {
  return (
    <>
    <div className="div-79">
          <div className="div-80">
            <div className="div-81">WHAT OUR ATHELETS HAVE TO SAY ABOUT US</div>{" "}
            <div className="div-82">
              <div className="div-83">
                <div className="column-8">
                <div className="div-84">
                <iframe src={youtubeUrl3} frameBorder="0" allowFullScreen="" wmode="opaque"  id="fitvid334380"  className="youtube" ></iframe>
                </div>
                </div>
                <div className="column-8">
                  <div className="div-84">
                  <iframe src={youtubeUrl4} frameBorder="0" allowFullScreen="" wmode="opaque" id="fitvid564155"  className="youtube" ></iframe>
                  </div>
                </div>
                <div className="column-8">
                <div className="div-84">
                <iframe src={youtubeUrl5} frameBorder="0" allowFullScreen="" wmode="opaque" id="fitvid327112"  className="youtube" ></iframe>
                </div>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <Form type={type} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
          </div>
        </div>
      <style jsx>{`
        .div-79 {
          justify-content: center;
          align-items: center;
          background-color: #e1e1e1;
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          margin: 40px 0px 0px 0px;
          padding: 48px 0px 40px;
        }
        @media (max-width: 991px) {
          .div-79 {
            max-width: 100%;
          }
        }
        .div-80 {
          display: flex;
          width: 1100px;
          max-width: 100%;
          flex-direction: column;
          align-items: center;
        }
        .div-81 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          white-space: nowrap;
          font: 700 28px/140% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-81 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .div-82 
          margin-top: 50px;
        }
        @media (max-width: 991px) {
          .div-82 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-83 {
          margin-top: 20px;
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-83 {
            flex-direction: column;
            align-items: center;
            gap: 0px;
          }
        }
        .column-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-7 {
            width: 100%;
          }
        }
        .img-11 {
          aspect-ratio: 1.78;
          object-fit: contain;
          object-position: center;
          width: 100%;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
        @media (max-width: 991px) {
          .img-11 {
            margin-top: 40px;
          }
        }
        .column-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 35%;
          margin-left:0px;
          padding: 0px 16px;
        }
        @media (max-width: 991px) {
          .column-8 {
            width: 100%;
          }
        }
        @media (max-width: 400px) {
          .column-8 {
            padding: 0px 0px 0px 0px;
          }
        .div-84 {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          align-items: center;
        }
        @media (max-width: 991px) {
          .div-84 {
            margin-top: 40px;
          }
        }
        @media (max-width: 300px) {
          .div-84 {
            display: auto;
            margin-top: 40px;
            width:200px
          }
        }
        @media (max-width: 300px) {
          .youtube {
            display: auto;
            width:200px
          }
        }

        .img-12 {
          aspect-ratio: 1.78;
          object-fit: contain;
          object-position: center;
          width: 100%;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
        .div-85 {
          justify-content: center;
          color: #fff;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          border-radius: 3px;
          // border: 1px solid rgba(0, 0, 0, 0.2);
          box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
          background-color: #00e;
          align-self: stretch;
          margin-top: 40px;
          padding: 19px 0px;
          font: 700 19px/151% Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-85 {
            white-space: initial;
          }
        }
      `}</style>
    </>
  );
}