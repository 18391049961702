import Form from './form';
const CustomButton = ({ onClick, buttonString }) => (
    <button onClick={onClick} className="div-gym-28">{buttonString}</button>
);

const CustomButton2 = ({ onClick, buttonString }) => (
    <button onClick={onClick} className="div-gym-29">{buttonString}</button>
);
function Concept({ type }) {
    return (
        <div className="items-center self-stretch flex flex-col pl-10 pr-10 max-md:px-5">
            <div className="text-purplebg text-center text-20xl-7 font-bold leading-[55px] mt-10 self-center ">
                WHAT YOU GET
            </div>
            <div className="text-zinc-800 text-center text-5xl-2 font-bold leading-8 self-center  mt-5">
                TRAINING SESSIONS
            </div>
            <div className="text-zinc-600 text-center text-base leading-6 self-center  mt-2">
                12 Training sessions
            </div>
            <div className="text-zinc-800 text-center text-5xl-2 font-bold leading-8 self-center  mt-9 max-md:max-w-full max-md:mt-10">
                PERSONALIZED NUTRITION PLAN
            </div>
            <div className="text-zinc-600 text-center text-base leading-6 self-center  mt-5">
                •  More than 50 customized meals for you
            </div>
            <div className="text-zinc-600 text-center text-base leading-6 self-center ">
                • Extremely easy yet tasty recipes to follow
            </div>
            <div className="text-zinc-600 text-center text-base leading-6 self-center ">
                • No food is forbidden
            </div>
            <div className="text-zinc-800 text-center text-5xl-2 font-bold leading-8 self-center  mt-7 max-md:max-w-full max-md:mt-10">
                LIFE CHANGING NUTRITION/WORKOUT SEMINAR
            </div>
            <div className="text-zinc-600 text-center text-base leading-6 self-center  mt-5 max-md:max-w-full">
                • You will learn how to lose weight while eating everything you want
            </div>
            <div className="text-zinc-600 text-center text-base leading-6 self-center   max-md:max-w-full">
                • The more you starve yourself the more you lose weight, right? Wrong!
                You will understand why
            </div>
            <div className="text-zinc-600 text-center text-base leading-6 self-center ">
                • Eating at night makes you fat, right ? Wrong again !
            </div>
            <div className="text-zinc-600 text-center text-base leading-6 self-stretch  max-md:max-w-full">
                • The truth about fade diet methods such as cutting carbs, fats, high
                protein diet, not eating at night, etc…
            </div>
            <div className="text-zinc-600 text-center text-base leading-6 self-stretch  max-md:max-w-full">
                And much much more!
            </div>
            <div className="text-zinc-800 text-center text-5xl-2 font-bold leading-8 self-center  mt-5 max-md:max-w-full max-md:mt-10">
                BEFORE AND AFTER MEASUREMENTS
            </div>
            <div className="text-zinc-600 text-center text-base leading-6 self-center  mt-2">
                • InBody measurements to keep track of your progress
            </div>
            <div className="text-zinc-800 text-center text-5xl-2 font-bold leading-8 self-center  mt-5">
                COACHES SUPPORT
            </div>
            <div className="text-zinc-600 text-center text-base leading-6 self-center  mt-4 max-md:max-w-full">
                • Whatsapp group with the coaches and all participants for ongoing support
            </div>
            <div className="text-zinc-600 text-center text-base leading-6 self-center  mt-4 max-md:max-w-full">
                • Coaches are available 24/7 if you need anything
            </div>
            <Form type={type} buttonComponent={(toggleModal) => <CustomButton2 buttonString="BOOK YOUR SPOT FOR FREE" onClick={toggleModal} />} />
            {/* <div className="text-white text-center text-xl font-bold leading-7 uppercase  justify-center items-center border border-[color:var(--www-aos-fit-black-20,rgba(0,0,0,0.20))] shadow-sm bg-red-600 self-center w-[317px] max-w-full mt-11 px-5 py-3 rounded border-solid max-md:mt-10">
                <a href="#" target="_blank">
                    BOOK your spot for free
                </a>
            </div> */}
            <br />
        </div>
    );
}

export default Concept;