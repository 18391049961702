
import Form from './form';
const CustomButton = ({ onClick }) => (
  <button   onClick={onClick} className="button">BOOK your FREE ASSESSMENT</button>

);

export default function ThreeAssesments({type, img1, img2, img3 })  {
  return (
    <>
<div className="div-45">THE THREE ASSESSMENTS</div>{" "}
<div className="div-46">1- Biomechanical Assessment</div>{" "}
<div className="div-47">
  <div className="div-48">
    <div className="column-5">
      <img loading="lazy" src={img1} className="img-8"/>
    </div>
    <div className="column-2">
      <div className="div-49">
      <div className="div-50">
      Poor posture, unhealthy habits, and an unbalanced training routine can result in pain and restricted movement. However, with our assessment, we can identify any imbalances that may be causing these issues and create a personalized correction plan to
      address them. By correcting these imbalances, we  can help you alleviate pain and improve your overall movement and physical performance.
        </div>
      </div>
    </div>
  </div>
</div>
<div className="div-58">2- Performance Assessment</div>{" "}
<div className="div-47">
  <div className="div-48">
    <div className="column-5">
    <img loading="lazy" src={img2} className="img-8"/>
    </div>
    <div className="column-4">
      <div className="div-61">
        <div className="div-62">
          This tests your strength, endurance, mobility, and flexibility through exercises and measurements, revealing areas where you can make the greatest functional gains.
        </div>
      </div>
    </div>
  </div>
</div>
<div className="div-58">3- Body Composition Assessment</div>{" "}
<div className="div-47">
  <div className="div-48">
    <div className="column-5">
    <img loading="lazy" src={img3} className="img-8"/>
    </div>
    <div className="column-6">
      <div className="div-69">
        <div className="div-70">
          Body composition is used by health professionals to evaluate a person’s weight by breaking it down into its
          core components: fat, protein, minerals, and body water. Body composition analysis can accurately show 
          changes in fat mass, lean muscle mass, and body fat percentage.
        </div>
      </div>
    </div>
  </div>
</div>
<br/>
<br/>
<Form type={type}buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
      <style jsx>{`
  .div-45 {
    justify-content: center;
    color: #00e;
    text-align: center;
    align-self: center;
    margin-top: 37px;
    white-space: nowrap;
    font: 700 28px/137% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .div-45 {
      white-space: initial;
    }
  }
  .div-46 {
    justify-content: center;
    color: #000;
    text-align: center;
    align-self: center;
    margin-top: 39px;
    white-space: nowrap;
    font: 500 20px/133% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .div-46 {
      white-space: initial;
    }
  }
  .div-47 {
    align-self: center;
    margin-top: 25px;
    width: 837px;
    max-width: 100%;
  }
  .div-48 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .div-48 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 52%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .column {
      width: 100%;
    }
  }
  .img-8 {
    object-fit: contain;
    object-position: center;
    width: 100%;
    overflow: hidden;
  }
  @media (max-width: 991px) {
    .img-8 {
      margin-top: 40px;
    }
  }
  .column-2 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 48%;
  }
  @media (max-width: 991px) {
    .column-2 {
      width: 100%;
    }
  }
  .div-49 {
    display: flex;
    margin-top: 11px;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .div-49 {
      margin-top: 40px;
    }
  }
  .div-50 {
    text-align: center;
    justify-content: center;
    color: #2f2f2f;
    text-align: center;
    align-self: stretch;
    // white-space: nowrap;
    font: 400 15.5px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .div-50 {
      white-space: initial;
    }
  }
  .div-58 {
    justify-content: center;
    color: #000;
    text-align: center;
    align-self: center;
    margin-top: 27px;
    white-space: nowrap;
    font: 500 23px/135% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .div-58 {
      white-space: initial;
    }
  }
  .div-59 {
    align-self: center;
    margin-top: 28px;
    width: 828px;
    max-width: 100%;
  }
  .div-60 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .div-60 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .column-3 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 53%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .column-3 {
      width: 100%;
    }
  }
  .column-4 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 47%;
    // margin-left: 20px;
  }
  @media (max-width: 991px) {
    .column-4 {
      width: 100%;
    }
  }
  .div-61 {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .div-61 {
      margin-top: 40px;
    }
  }
  .div-62 {
    justify-content: center;
    color: #2f2f2f;
    text-align: center;
    align-self: stretch;
    font: 400 15px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .div-62 {
      white-space: initial;
    }
  }
  .div-66 {
    justify-content: center;
    color: #000;
    text-align: center;
    align-self: center;
    margin-top: 28px;
    white-space: nowrap;
    font: 500 23px/135% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .div-66 {
      white-space: initial;
    }
  }
  .div-67 {
    align-self: center;
    margin-top: 23px;
    width: 847px;
    max-width: 100%;
  }
  .div-68 {
    gap: 20px;
    display: flex;
  }
  @media (max-width: 991px) {
    .div-68 {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }
  .column-5 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    .column-5 {
      width: 100%;
    }
  }
  .column-6 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    // margin-left: 20px;
  }
  @media (max-width: 991px) {
    .column-6 {
      width: 100%;
    }
  }
  .div-69 {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    .div-69 {
      margin-top: 40px;
    }
  }
  .div-70 {
    justify-content: center;
    color: #2f2f2f;
    text-align: center;
    align-self: stretch;
    font: 400 15px Inter, sans-serif;
  }
  @media (max-width: 991px) {
    .div-70 {
      white-space: initial;
    }
  }
  .div-78 {
    justify-content: center;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    align-items: center;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
    background-color: #00e;
    align-self: center;
    margin-top: 40px;
    width: 353px;
    max-width: 100%;
    padding: 19px 0px;
    font: 700 19px/151% Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .div-78 {
      white-space: initial;
    }
  }
      `}</style>
    </>
  );
}
