import Form from './form';
const CustomButton = ({ onClick }) => (
    <button
        onClick={onClick}
        className="justify-center text-white text-center text-xl font-bold leading-7 whitespace-nowrap items-center border shadow-sm bg-orange-500 self-center w-56 max-w-full  px-4 py-3 rounded border-solid border-black border-opacity-20 no-underline"
    >
        START FREE TRIAL
    </button>
);
export default function FreeTrial({ type }) {
    return (
        <form className="justify-center items-center bg-zinc-600 self-stretch flex w-full flex-col -mr-5 py-4 max-md:max-w-full">
            <Form type={type} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
        </form>

    );
}