import Form from './form';
const CustomButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="justify-center cursor-pointer text-white text-center text-xl font-bold leading-7 uppercase  items-center border shadow-sm bg-orange-500 w-[330px] max-w-full mt-14 px-4 py-3 rounded border-solid border-black border-opacity-20  max-md:mt-10"
  >
    BOOK YOUR SPOT FOR FREE
  </button>
);
export default function CrazyOffer({ type, imageCrazyOffer1Url }) {
  return (
    <>
      <div className="div-crazy">
        <div className="div-crazy-2">
          <div className="div-crazy-3">IF YOU ARE NOT COMPLETELY SATISFIED </div>
          <div className="div-crazy-4">WE DON'T GET PAID </div>{" "}
          <div className="div-crazy-5">
            <div className="div-crazy-6">.SERIOUSLY. </div>{" "}
          </div>
          <div className="div-crazy-8">
            <div className="div-crazy-9">
              <div className="column-crazyOffer2">
                <img
                  loading="lazy"
                  src={imageCrazyOffer1Url}
                  className="img-crazyoffer2"
                />
              </div>{" "}
              <div className="column-2">
                <div className="div-crazy-10">
                  <div className="div-crazy-11">
                    We are so confident in our ability to blow you away with our
                    world-class service that we offer a personal satisfaction guarantee. If you feel as though you did not
                    get the level of service that was equal or superior to the
                    investment you put in financially, You will get all your money back. Best-case scenario, we
                    change your life; worst case, you worked out for free. You have no risk.
                  </div>
                  <a href="#" className="div-crazy-18">
                    YOU LITERALLY HAVE NOTHING TO LOSE !
                  </a>
                  <Form type={type} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .div-crazy {
          justify-content: center;
          align-items: center;
          background-color: #f5f5f5;
          display: flex;
          flex-direction: column;
        }
        .div-crazy-2 {
          display: flex;
          width: 1007px;
          max-width: 100%;
          flex-direction: column;
          margin: 49px 0 40px;
        }
        @media (max-width: 991px) {
          .div-crazy-2 {
            margin-top: 40px;
          }
        }
        .div-crazy-3 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          align-self: center;
          max-width: 665px;
          font: 700 32px/42px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-crazy-3 {
            max-width: 100%;
          }
        }
        .div-crazy-4 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          align-self: center;
          margin-top: 7px;
          white-space: nowrap;
          font: 700 32px/42px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-crazy-4 {
            white-space: initial;
          }
        }
        .div-crazy-5 {
          align-self: center;
          display: flex;
          width: 196px;
          max-width: 100%;
          gap: 11px;
        }
        .div-crazy-6 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          font: 700 30px/42px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-crazy-7 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          align-self: start;
          margin-top: 9px;
          white-space: nowrap;
          font: 700 32px/42px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-crazy-7 {
            white-space: initial;
          }
        }
        .div-crazy-8 {
          align-self: stretch;
          margin-top: 49px;
        }
        @media (max-width: 991px) {
          .div-crazy-8 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-crazy-9 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-crazy-9 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-crazyOffer2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 32%;
          margin-right: 69px;
        }
        @media (max-width: 991px) {
          .column-crazyOffer2 {
            align-items: center;
            width: 100%;
          }
        }
        .img-crazyoffer2 {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 90%;
          overflow: hidden;
          flex-grow: 1;
        }

        .column-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 68%;
    
        }
        @media (max-width: 991px) {
          .column-2 {
            width: 100%;
          }
        }
        .div-crazy-10 {
            align-items: center;
          display: flex;
          margin-top: 16px;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-crazy-10 {
            align-items: center;
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-crazy-11 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          font: 400 15px/23px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-crazy-11 {
            max-width: 90%;
            white-space: initial;
          }
        }
        .div-crazy-12 {
          align-self: stretch;
          display: flex;
          justify-content: space-between;
          gap: 7px;
        }
        @media (max-width: 991px) {
          .div-crazy-12 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-crazy-13 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          font: 400 15px/23px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-crazy-14 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          white-space: nowrap;
          font: 400 15px/23px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-crazy-14 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .div-crazy-15 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          align-self: stretch;
          white-space: nowrap;
          font: 400 15px/23px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-crazy-15 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .div-crazy-16 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          align-self: stretch;
          white-space: nowrap;
          font: 400 15px/23px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-crazy-16 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .div-crazy-17 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          align-self: center;
          white-space: nowrap;
          font: 400 15px/23px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-crazy-17 {
            white-space: initial;
          }
        }
        .div-crazy-18 {
        text-decoration: none;
          justify-content: center;
          color: rgb(249,115,22);
          text-align: center;
          align-self: center;
          margin-top: 40px;
          white-space: nowrap;
          font: 450 21px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-crazy-18:hover {
            border-bottom: 3px solid rgb(249,115,22);
        }
        @media (max-width: 991px) {
          .div-crazy-18 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .div-crazy-19 {
          align-self: center;
          display: flex;
          margin-top: 51px;
          width: 213px;
          max-width: 100%;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-crazy-19 {
            margin-top: 40px;
          }
        }
        .div-crazy-20 {
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
        }
        .div-crazy-21 {
          display: flex;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-crazy-21 {
            justify-content: center;
          }
        }
        .div-crazy-22 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          font: 700 22px/39px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-crazy-23 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          align-self: start;
          font: 700 27px/39px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-crazy-24 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          font: 700 22px/39px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-crazy-25 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          align-self: start;
          white-space: nowrap;
          font: 700 27px/39px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-crazy-25 {
            white-space: initial;
          }
        }
        .div-crazy-26 {
          display: flex;
          margin-top: 15px;
          justify-content: space-between;
          gap: 20px;
        }
        .div-crazy-27 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          text-transform: uppercase;
          font: 700 10px/14px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-crazy-28 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          font: 700 10px/14px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-crazy-28 {
            white-space: initial;
          }
        }
        .div-crazy-29 {
          display: flex;
          flex-basis: 0%;
          flex-direction: column;
        }
        .div-crazy-30 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          white-space: nowrap;
          font: 700 22px/39px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-crazy-30 {
            white-space: initial;
          }
        }
        .div-crazy-31 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          text-transform: uppercase;
          margin-top: 15px;
          white-space: nowrap;
          font: 700 10px/14px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-crazy-31 {
            white-space: initial;
          }
        }
        .div-crazy-32 {
          align-self: center;
          display: flex;
          margin-top: 24px;
          width: 263px;
          max-width: 100%;
          gap: 7px;
        }
        @media (max-width: 991px) {
          .div-crazy-32 {
            justify-content: center;
          }
        }
        .div-crazy-33 {
          justify-content: center;
          color: #000;
          text-align: center;
          font: 700 18px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-crazy-34 {
          justify-content: center;
          color: #f31400;
          text-align: center;
          font: 700 19px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-crazy-35 {
          justify-content: center;
          color: #000;
          text-align: center;
          font: 700 19px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-crazy-36 {
          justify-content: center;
          color: #2f2f2f;
          text-align: center;
          white-space: nowrap;
          font: 700 20px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-crazy-36 {
            white-space: initial;
          }
        }
        .div-crazy-37 {
          justify-content: center;
          color: #fff;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          border-radius: 3px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
          background-color: #f31400;
          align-self: center;
          margin-top: 15px;
          width: 426px;
          max-width: 100%;
          padding: 19px 20px;
          font: 700 19px/29px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-crazy-37 {
            white-space: initial;
          }
        }
      `}</style>{" "}
    </>
  );
}

