import Form from './form';
import React, { useState, useEffect } from 'react';
const CustomButton = ({ onClick, buttonString }) => (
  <button onClick={onClick} className="div-gympre-28">{buttonString}</button>
);

const CustomButton2 = ({ onClick, buttonString }) => (
  <button onClick={onClick} className="div-gympre-29">{buttonString}</button>
);


export default function PregnantOffer({ buttonString, type, spot }) {


  return (
    <>

      {type === 'ramadan' ? <br /> :
        <div className="div-gympre-23">
          <div className="div-gympre-24">ONLY</div>
          <a href="#" className="div-gympre-25">{spot || 0} SPOTS</a>
          <div className="div-gympre-26">AVAILABLE</div>
          <div className="div-gympre-27"> PER MONTH !</div>
        </div >}

        <Form type={type} buttonComponent={(toggleModal) => <CustomButton buttonString="BOOK YOUR FREE ASSESSMENT" onClick={toggleModal} />} />
      <style jsx>{`
              .div-gympre-7 {
                justify-content: center;
                color: rgb(195 143 132);
                text-align: center;
                align-self: center;
                margin-top: 28px;
                white-space: nowrap;
                font: 600 15.5px/23px Inter, -apple-system, Roboto, Helvetica,
                  sans-serif;
              }
              @media (max-width: 991px) {
                .div-gympre-7 {
                  white-space: initial;
                }
              }
        .div-gympre-8 {
          justify-content: center;
          color:rgb(195 143 132);
          text-align: center;
          align-self: center;
          margin-top: 28px;
          white-space: nowrap;
          font: 700 15.5px/23px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gympre-8 {
            white-space: initial;
          }
        }
        .div-gympre-9 {
          text-align: center;
          align-self: center;
          display: flex;
          margin-left: 58px;
          max-width: 100%;
          justify-content: space-between;
        }
        @media (max-width: 300px) {
          .div-gympre-9 {
            margin-left: 0px;
          }
        }
        .div-gympre-10 {
          display: flex;
          flex-basis: 0%;
          flex-direction: column;
          margin-right: 15px;
        }
        .div-gympre-11 {
          display: flex;
          justify-content: space-between;
          gap: 20px;
        }
        .div-gympre-12 {
          justify-content: center;
          color: rgb(195 143 132);
          text-align: center;
          font: 700 22px/39px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gympre-13 {
          justify-content: center;
          color: rgb(195 143 132);
          text-align: center;
          align-self: start;
          white-space: nowrap;
          font: 700 27px/39px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gympre-13 {
            white-space: initial;
          }
        }
        .div-gympre-14 {
          justify-content: center;
          color: rgb(195 143 132);
          text-align: center;
          text-transform: uppercase;
          margin: 15px 25px 0px -3px;
          white-space: nowrap;
          font: 400 10px/14px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gympre-14 {
            white-space: initial;
          }
        }
        .div-gympre-15 {
          display: flex;
          flex-basis: 0%;
          flex-direction: column;
        }
        .div-gympre-16 {
          display: flex;
          align-items: start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-gympre-16 {
            justify-content: center;
          }
        }
        .div-gympre-17 {
          justify-content: center;
          color: rgb(195 143 132);
          text-align: center;
          align-self: stretch;
          font: 700 22px/39px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gympre-18 {
          justify-content: center;
          color: rgb(195 143 132);
          text-align: center;
          font: 700 27px/39px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gympre-19 {
          justify-content: center;
          color: rgb(195 143 132);
          text-align: center;
          align-self: stretch;
          white-space: nowrap;
          font: 700 22px/39px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gympre-19 {
            white-space: initial;
          }
        }
        .div-gympre-20 {
          display: flex;
          margin-top: 15px;
          justify-content: space-between;
        }
        .div-gympre-21 {
          justify-content: center;
          color: rgb(195 143 132);
          text-align: center;
          text-transform: uppercase;
          margin: 0px 0px 0px -5px;
          font: 400 10px/14px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gympre-22 {
          justify-content: center;
          color: rgb(195 143 132);
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          margin: 0px -5px 0px 0px;
          font: 400 10px/14px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gympre-22 {
            white-space: initial;
          }
        }
        .div-gympre-23 {
          align-self: center;
          display: flex;
          margin-top: 24px;
          width: 400px;
          max-width: 100%;
          gap: 6px;
        }
        @media (max-width: 991px) {
          .div-gympre-23 {
            justify-content: center;
          }
        }
        .div-gympre-24 {
          justify-content: center;
          color:rgb(195 143 132);
          text-align: center;
          font: 700 18px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gympre-25 {
          justify-content: center;
          color: rgb(195 143 132);
          text-align: center;
          padding-right: 1px;
          text-decoration: none;
          font: 700 19px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gympre-25:hover {
            border-bottom: 2px solid rgb(195 143 132);
        }
        .div-gympre-26 {
          justify-content: center;
          color:rgb(195 143 132);
          text-align: center;
          font: 700 19px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gympre-27 {
          justify-content: center;
          color:rgb(195 143 132);
          text-align: center;
          white-space: nowrap;
          font: 700 20px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gympre-27 {
            white-space: initial;
          }
        }
       @media (max-width: 991px) {
        .div-gympre-24 {
          font: 700 15px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gympre-25 {
          font: 700 15px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gympre-26 {
          font: 700 15px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        .div-gympre-27 {

          font: 700 15px/26px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        }
        .div-gympre-28 {
          cursor: pointer;
          justify-content: center;
          color: #fff;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          border-radius: 2px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
          background-color:rgb(195 143 132);
          align-self: center;
          margin-top: 26px;
          width: 520px;
          max-width: 100%;
          padding: 12px 20px;
          font: 700 19px/29px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gympre-28 {
            width: 280px;
            max-width: 100%;
            white-space: initial;
          }
        }
        .div-gympre-29 {
          cursor: pointer;
          justify-content: center;
          color: #fff;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          align-items: center;
          border-radius: 3px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
          background-color: rgb(195 143 132);
          align-self: center;
          margin-top: 26px;
          width: 284px;
          max-width: 100%;
          padding: 10px 20px;
          font: 700 17px/29px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .div-gympre-29 {
            white-space: initial;
          }
        }
      `}</style>
    </>
  );
}


