import Home from "../components/home";

const Circuit = () => {
  return (
    <>
      <div className="div">
        <Home />
      </div>
      <style jsx>{`
        .div {
          padding: 40px;
          display: flex;
          background-color: rgb(0,0,0);
          flex-direction: column;
        }
      `}</style>
    </>
  );
};

export default Circuit;
