import React, { useState } from 'react';
import Form from './form';
const CustomButton = ({ onClick }) => (
    <button
        onClick={onClick}
        className="justify-center text-white text-center text-xl font-bold leading-7 whitespace-nowrap items-center border shadow-sm bg-orange-500 self-center w-56 max-w-full mt-5  px-4 py-4 rounded border-solid border-black border-opacity-20 no-underline"
    >
        START FREE TRIAL
    </button>
);
const FaqItem = ({ question, answer }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpansion = () => {
        setExpanded(!expanded);
    };
    return (
        <div className="self-stretch flex items-stretch flex-col mt-14 max-md:max-w-full max-md:flex-wrap max-md:mt-10">
            <div className="flex items-center">
                <div className="text-black text-xl font-bold leading-7 grow shrink basis-auto">
                    {question}
                </div>
                <span
                    className={`ml-3 text-black text-2xl font-bold leading-4 whitespace-nowrap cursor-pointer`}
                    onClick={toggleExpansion}
                >
                    {expanded ? '-' : '+'}
                </span>
            </div>
            {expanded && (
                <div className="text-black text-xxs mt-6 text-center">
                    {answer}
                </div>
            )}
        </div>
    );
};

export const FAQSection = ({ type }) => {
    return (
        <div className="justify-center items-center bg-gray-200 flex flex-col px-5">
            <div className="flex w-[1065px] max-w-full flex-col mt-12 mb-10 max-md:mt-10">
                <div className="justify-center text-orange-500 text-center text-7xl-1 font-bold leading-10 self-center whitespace-nowrap">
                    FAQ's
                </div>
                <FaqItem
                    question="Can beginners train this program?"
                    answer="Definitely! This program suits all fitness levels whether you are beginner, intermediate or advanced"
                />
                <FaqItem
                    question="How long is the workout?"
                    answer="The sessions is one hour"
                />
                <FaqItem
                    question="How many times Should I train ?"
                    answer="You can train 3, 4 or 5 times a week"
                />
                <FaqItem
                    question="Are girls going to get bulky ?"
                    answer="NOOOO!! We guarantee you will never get bulky. On the contrary, this program will help you lose fats, get toned and have this curvy sexy feminine body."
                />
                <Form type={type} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
            </div>
        </div>
    );
};

export const FAQSection2 = () => {


    return (
        <div className="justify-center items-center bg-[url('./images/backgroundConcept.jpg')] flex flex-col px-5">
            <div className="flex w-[1065px] max-w-full flex-col mt-12 mb-10 max-md:mt-10">
                <div className="justify-center text-center text-7xl-1 font-bold leading-10 self-center whitespace-nowrap" style={{ color: 'rgba(255, 0, 203, 0.85)' }}>
                    FAQ's
                </div>
                <FaqItem
                    question="Can beginners train this program?"
                    answer="Definitely! This program suits all fitness levels whether you are beginner, intermediate or advanced"
                />
                <FaqItem
                    question="How long is the workout?"
                    answer="The sessions is one hour"
                />
                <FaqItem
                    question="How many times Should I train ?"
                    answer="You have unlimited access, however we recommend that you train minimum 3 times / week "
                />
                <FaqItem
                    question="Are girls going to get bulky ?"
                    answer="NOOOO!! We guarantee you will never get bulky. On the contrary, this program will help you lose fats, get toned and have this curvy sexy feminine body."
                />
            </div>
        </div>
    );
};

export const FAQSection3 = ({ type }) => {
    console.log(type);
    return (
        <div className="justify-center items-center bg-[url('./images/backgroundConcept.jpg')] flex flex-col px-5">
            <div className="flex w-[1065px] max-w-full flex-col mt-12 mb-10 max-md:mt-10">
                <div className="justify-center text-center text-7xl-1 font-bold leading-10 self-center whitespace-nowrap" style={{ color: type === 'ramadan' ? `rgb(255, 110, 0)` : `rgb(243, 20, 0)` }}>
                    FAQ's
                </div>
                <FaqItem
                    question="Can beginners train this program?"
                    answer="Definitely! This program suits all fitness levels whether you are beginner, intermediate or advanced"
                />
                <FaqItem
                    question="How long is the workout?"
                    answer="The sessions is one hour"
                />
                <FaqItem
                    question="How many times Should I train ?"
                    answer="You have unlimited access, however we recommend that you train minimum 3 times / week "
                />
                <FaqItem
                    question="Are girls going to get bulky ?"
                    answer="NOOOO!! We guarantee you will never get bulky. On the contrary, this program will help you lose fats, get toned and have this curvy sexy feminine body."
                />
            </div>
        </div>
    );
};