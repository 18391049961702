import React, { useState, useEffect } from 'react';
import './confused.css';
import './testmonials.css'


export default function Testimonial() {

  const testimonials = [
    {
      id: 1,
      name: 'Sarah',
      quote: `"Being a part of the pre & postnatal program has been a game-changer for me. I felt supported
and guided every step of the way, both during pregnancy and after giving birth. Thanks to this
program, I regained my strength, toned my body, and improved my overall well-being. I highly
recommend this program to any expecting or new mom out there!"`,
    },
    {
      id: 2,
      name: 'Mariam',
      quote: `"I cannot express how grateful I am for the pre & postnatal program. It provided me with the
tools and knowledge to stay healthy and fit throughout my pregnancy and beyond. Not only
did I have a smooth pregnancy, but I also managed to bounce back quickly postpartum. This
program empowered me and I couldn't recommend it enough." `,
    },
    {
      id: 3,
      name: 'Mai',
      quote: `"I had my doubts about getting back in shape after having a baby, but the pre & postnatal
program exceeded all my expectations. It was tailored specifically to my needs, considering my
physical changes and limitations. I not only regained my pre-pregnancy body but also felt
stronger and more confident than ever. I wholeheartedly endorse this program to all moms
looking to transform their bodies and lives!”`,
    },
    {
      id: 4,
      name: 'Salma',
      quote: `"The pre & postnatal program was a true lifeline for me during pregnancy and beyond. It
provided me with valuable guidance on nutrition, exercise, and self-care, which made all the
difference in my journey. Thanks to this program, I experienced increased energy levels,
improved mood, and a faster recovery postpartum. I highly recommend it to any mom-to-be or
new mom seeking a comprehensive and effective approach." `,
    },
    {
      id: 5,
      name: 'Lama',
      quote: `"I cannot speak highly enough of the pre & postnatal program. It not only helped me maintain
a healthy weight during pregnancy but also guided me in rebuilding my core strength and
regaining my pre-baby shape. The trainers were incredibly supportive, and the community of
other moms going through the same journey provided invaluable encouragement. I am
genuinely thrilled with the results and would recommend this program to every mom out
there!"`,
    },
  ];


  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  const testimonialsPerPage = isMobile ? 1 : 3;
  const maxIndex = Math.ceil(testimonials.length / testimonialsPerPage) - 1;

  const handleResize = () => {
    setIsMobile(window.innerWidth < 640);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex < maxIndex ? prevIndex + 1 : 0));
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : maxIndex));
  };
  function Testimonial({ imageUrl, altText, testimonialText, userName }) {
    return (
      <section  className="testimonial-card">
      <img loading="lazy" src={imageUrl} alt={altText} className="aspect-square w-[65px]" />
      <p className="mt-6 w-full text-base leading-6">{testimonialText}</p>
      <div className="flex justify-center items-end h-10"> 
        <span className="text-base font-bold leading-6">{userName}</span>
      </div>
    </section>
    );
  }
  
  

  const visibleTestimonials = testimonials.slice(activeIndex * testimonialsPerPage, (activeIndex + 1) * testimonialsPerPage);

  return (
    <section id="testimonials" aria-label="What our customers are saying" className="bg-slate-50 ">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-center text-20xl-2 font-bold leading-9 self-center  mt-5 leading-normal">Testimonials</h2>
        </div>
        <div className="relative mt-16 items-center justify-center">
          <button onClick={handlePrev} className="nav-button left z-10 hover:bg-gray-300">
            &lt;
          </button>
          <ul role="list" className="testimonial-list">
            {visibleTestimonials.map(testimonial => (
              <li key={testimonial.id} className="flex justify-center">
                <Testimonial
        imageUrl="quote.svg"
        testimonialText={testimonial.quote}
        userName={testimonial.name}
      />
              </li>
            ))}
          </ul>
          <button onClick={handleNext} className="nav-button right z-10 hover:bg-gray-300">
            &gt;
          </button>
        </div>
      </div>
    </section>
  );
}
