
import Header from "../components/header2";
import PregnantOffer from "../components/pregnantOffer"
import Form from '../components/form';
import Confused from "../components/pregnantConfused";
import Testimonial from "../components/testmonial";
const CustomButton = ({ onClick, buttonString }) => (
    <button onClick={onClick} className="div-gympre-28">{buttonString}</button>
  );

  const CustomButton2 = ({ onClick, buttonString }) => (

    <button onClick={onClick} className="div-gympre-but">{buttonString}</button>
  );
import React, { useState, useEffect } from 'react';
const Pregnant = () => {
    const color = 'red';
    const [imageGardenUrl, setImageGardenUrl] = useState('');
    const [imageYoussefUrl, setImageYoussefUrl] = useState('');
    const [imageGymUrl, setImageGymUrl] = useState('');
    const [imageAssesmentUrl, setImageAssesmentUrl] = useState('');
    const [imageJumpUrl, setImageJumpUrl] = useState('');
    const [imageAllPeopleUrl, setImageAllPeopleUrl] = useState('');
    const [imageGym1Url, setImageGym1Url] = useState('');
    const [imageReviews1Url, setImageReviews1Url] = useState('');
    const [imageCrazyOffer1Url, setImageCrazyOffer1Url] = useState('');
    const [enteredSpot, setEnteredSpot] = useState(0);
    const [gymDay, setGymDay] = useState(0);
    const [gymHours, setGymHours] = useState(0);
    const [gymMinutes, setGymMinutes] = useState(0);
    const [wholeDate, setWholeDate] = useState(null);
    const [youtubeUrl1, setYoutubeUrl1] = useState('');
    const [youtubeUrl2, setYoutubeUrl2] = useState('');
    const [youtubeUrl3, setYoutubeUrl3] = useState('');
    const [youtubeUrl4, setYoutubeUrl4] = useState('');
    const [youtubeUrl5, setYoutubeUrl5] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiUrl1 = `https://www.aos.fit/api/getalldata`;
                let allData = await fetch(apiUrl1);
                const allDataResult = await allData.json();
                setEnteredSpot(allDataResult['pregnantSpot'])
                setYoutubeUrl1(`${allDataResult['pregnantyoutubeUrl2']}`)
                setYoutubeUrl2(`${allDataResult['pregnantyoutubeUrl3']}`)
                setYoutubeUrl3(`${allDataResult['pregnantyoutubeUrl4']}`)
                setYoutubeUrl5(`${allDataResult['pregnantyoutubeUrl1']}`)

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    return (
        <>
            <div className="div-c">
                <Header />
                <div className="divTitlePre">
                    <div className="container divTitlePre">
                        <span style={{ color: "black" }}>Start Your Journey With Our  </span>
                    </div>
                </div>
                <div className="divTitlePre2" style={{ color: "rgb(195 143 132);" }}>
                    <div className="container divTitlePre2">
                        <span>Free Mama Body Assessment</span>
                    </div>
                    <div className="container divTitlePre3">
                        <div class="title">
                            Unlock Your Pregnancy Blueprint
                             <span class="mobile-line-break">In Just 30 Minutes: Avoid</span>
                        </div>
                        <div style={{ fontSize: '23px' }}>Frustrations, Weight Gain, Mood Swings & Tiredness</div>
                    </div>
                </div>

                <div className="videogroup">
                    <iframe src={youtubeUrl5} frameBorder="0" allowFullScreen="" wmode="opaque" id="fitvid146605"></iframe>
                    <PregnantOffer type={'pregnant'} buttonString='true' spot={enteredSpot} wholeDate={wholeDate} />
                </div>
                <div className='natal1'>
                    <div class="natalheader">
                        <span style={{ width: '50%', display: 'flex', justifyContent: 'end' }}>
                        <img className='natalimg' src='/natal.png' />
                        </span>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                            <span style={{ backgroundColor: 'rgb(101 170 195)', height: '150px', width: '4px', color: 'rgb(101 170 195)' }}>
                            </span>
                            <span style={{ backgroundColor: 'rgb(228 168 155)', height: '150px', width: '4px', color: 'rgb(228 168 155)' }}>
                            </span>
                        </div>
                        <div style={{ alignItems: 'center', width: '50%' }}>
                    <div class='boldtxt'>
                        Why are we giving this priceless Assessment for free ?
                    </div>
                    {/* <div class='boldtxt'>
                        Assessment for free ?
                            </div> */}
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="items-center self-stretch flex flex-col pl-10 pr-10 max-md:px-5">
            <div className="text-black-800 text-center text-5xl-2 font-bold leading-8 self-center  mt-5">
            We simply want to help !
            </div>
            <div className="text-black-800 text-center text-lg-9 leading-6 self-center  mt-2">
            In your pre or postnatal journey, challenges often arise for many women. Whether it's 
            </div>
            <div className="text-black-800 text-center text-lg-9 leading-6 self-center ">
            uncertainty about what steps to take, weight gain, fatigue, or mounting frustrations. 
            </div>
            <br/>
            <div className="text-black-800 text-center text-lg-9 leading-6 self-center">
            It can feel overwhelming without proper support and guidance.
            </div>
            <br/>  
            <div className="text-black-800 text-center text-lg-9 leading-6 self-center ">
            However, what many don't realize is that with the right tips and guidance, these issues can 
            </div>
            <div className="text-black-800 text-center text-lg-9 leading-6 self-center ">
            be prevented, allowing you to embrace the pre and postnatal phases fully.
            </div>
            <br/>
            <div className="text-black-800 text-center text-lg-9 leading-6 self-center  ">
            This transformative time presents an opportunity for you to not only navigate these
            </div>
            <div className="text-black-800 text-center text-lg-9 leading-6 self-center ">
            challenges but also to use them as motivation to cultivate a healthier, fitter version of
            yourself.
            </div>
            <br/>
            <div className="text-black-800 text-center text-5xl-2 font-bold leading-8 self-center  mt-5">
            Join us for a free assessment and let us guide you towards a more empowered journey!
            </div>
            <Form  type={'pregnant'} buttonComponent={(toggleModal) => <CustomButton buttonString="BOOK YOUR FREE ASSESSMENT" onClick={toggleModal} />} />
            <br />
        </div>
                </div>
                <br />
                <br />
                <div className="divpre-79">
                <div className="divpre-80">
                    <div className="divpre-81">But don't take Our word for it.</div>
                    <div className="divpre-81">Here's what our members have to say</div>

                <div className="divpre-82">
              <div className="divpre-83">
                <div className="columnpre-8">
                <div className="divpre-84">
                <iframe src={youtubeUrl1} frameBorder="0" allowFullScreen="" wmode="opaque"  id="fitvid334380"  className="youtubepre" ></iframe>
                </div>
                </div>
                <div className="columnpre-8">
                  <div className="divpre-84">
                  <iframe src={youtubeUrl2} frameBorder="0" allowFullScreen="" wmode="opaque" id="fitvid564155"  className="youtubepre" ></iframe>
                  </div>
                </div>
                <div className="columnpre-8">
                <div className="divpre-84">
                <iframe src={youtubeUrl3} frameBorder="0" allowFullScreen="" wmode="opaque" id="fitvid327112"  className="youtubepre" ></iframe>
                </div>
                </div>
              </div>
                </div>
                </div>
                </div>
                <br />
                <br />
                <div className='natal1'>

                    <div className="items-center self-stretch flex flex-col pl-10 pr-10 max-md:px-5">
            <div className="text-preBlue text-center text-20xl-2 font-bold leading-8 self-center  mt-5  leading-8  max-md:leading-10">
            What You Get From Our
            </div>
            <div className="text-preBlue text-center text-20xl-2 font-bold leading-8 self-center leading-normal ">
            FREE Assessment 
            </div>
            <br />
            <div className="text-black-800 text-center text-lg-9 leading-6 self-center  mt-2">
            1- Lots of women get neck and back pain, our <b> specialized biomechanical</b>
            </div>
            <div className="text-black-800 text-center text-lg-9 leading-6 self-center ">
            <b>assessment</b> will identify all the muscle imbalances that will make us know your exact week 
            </div>
            <div className="text-black-800 text-center text-lg-9 leading-6 self-center">
            points and take preventive actions and strengthening the weak area 
            </div>
            <br/>  
            <div className="text-black-800 text-center text-lg-9 leading-6 self-center  mt-2">
            2- <b> Body Composition assessment</b> to identify the amount of fats using the caliper 
            </div>
            <div className="text-black-800 text-center text-lg-9 leading-6 self-center ">
            measurement, if you are still in the first trimester. After that weight management control will be safer 
            </div>
            <br/>  
            <div className="text-black-800 text-center text-lg-9 leading-6 self-center ">
            3- Revealing the <b> 5 Game-Changing Secrets</b> for an Amazing Pregnancy
            </div>
            <Form  type={'pregnant'} buttonComponent={(toggleModal) => <CustomButton2 buttonString="BOOK YOUR FREE ASSESSMENT" onClick={toggleModal} />} />
            <br />
                </div>
                <br/>
                </div>
                <div>
                <div className="divpre-801">You also Get</div>
                    <div className="divpre-81">2 FREE Bonuses Worth 6,000 LE</div>
                    <div className="divpre-81">if You Take Action Right NOW!</div>
                    <br/>
                    <div className="text-black-800 text-center text-lg-9 leading-6 self-center  mt-2">
                        <b> Free Bonus Gift #1:</b> Our life-changing Nutrition Course <b>Valued at 4,000 LE.</b>
                    </div>
                    <br/>
                    <div className="text-black-800 text-center text-lg-9 leading-6 self-center ">
                        <b>Free Bonus Gift #2:</b> Our state-of-the-art Safety Movement Session,  <b>Valued at 2,000 LE.</b>
                    </div>
                    <br/>
                    <br/>
                    <div className="text-black-800 text-center text-lg-9 leading-6 self-center">
                        <b>You will learn</b>
                    </div>
                    <br/>  
                    <div className="text-black-800 text-center text-lg-9 leading-6 self-center">
                    Weight management During Pregnancy
                    </div>
                    <div className="text-black-800 text-center text-lg-9 leading-6 self-center">
                    Shredding the excess weight gained after pregnancy
                    </div>
                    <div className="text-black-800 text-center text-lg-9 leading-6 self-center">
                    Developing healthier sleeping pattern
                    </div>
                    <div className="text-black-800 text-center text-lg-9 leading-6 self-center">
                    Reducing Muscle Pain During Pregnancy
                    </div>
                    <div className="text-black-800 text-center text-lg-9 leading-6 self-center">
                    Accelerating Muscle Recovery After Pregnancy
                    </div>
                    <div className="text-black-800 text-center text-lg-9 leading-6 self-center">
                        Minimizing Morning Sickness
                    </div>
                    <div className="text-black-800 text-center text-lg-9 leading-6 self-center">
                    Maintaining Strength And Metabolism While Staying Safe
                    </div>
                <br/>
                <br/>
                </div>

                <div>

                <div className='natal1'>

<div className="items-center self-stretch flex flex-col pl-10 pr-10 max-md:px-5">
<div className="text-preBlue text-center text-20xl-2 font-bold leading-9 self-center  mt-5 leading-normal">
Our Certified Coaches
</div>
<div className="text-preBlue text-center text-20xl-2 font-bold leading-9 self-center  mt-2">
Got You Covered
</div>
<br />
<br />
<div className="mira">
<div className="miraContainer">
    <img className='miraimg'src='./mira.jpeg'></img>
</div>

<div className="miratext">
<div className="text-black-800 text-center text-2xl-6 leading-9 self-center">
Led by Coach Mira, a Medical Doctor turned
passionate fitness coach dedicated to transforming
individuals into their best selves. Drawing on years
of experience and a deep understanding of the
challenges faced by women during and after
pregnancy, she has committed herself to guiding
and empowering women through these
transformative phases. Certified by renowned
institutions, she has already impacted numerous
lives with her expertise and support. Join Coach
Mira on this journey to a healthier, stronger you.
</div>


</div>
</div>


</div>
<br/>
</div>
                <br/>
                <div className="divpre-81">It Really Is A</div>
                    <div className="divpre-81">No Brainer</div>
                    <br/>
                    <div className="text-black-800 text-center  text-2xl-6 leading-8 self-center  mt-2">
                    At AOS Team, we know that what we offer may sound too good to be true, and that 
                    </div>
                    <div className="text-black-800 text-center  text-2xl-6 leading-8 self-center ">
                    might make you doubt it a bit. But we genuinely want to help you as much as we can.
                    </div>
                    <div className="text-black-800 text-center text-2xl-6  leading-8 self-center">
                    If you decide to use the information we provide and go on your own journey, we'll
                    </div>
                    <div className="text-black-800 text-center text-2xl-6 leading-8 self-center">
                    be happy to have contributed to your success without any injuries. And if you choose to 
                    </div>
                    <div className="text-black-800 text-center text-2xl-6 leading-8 self-center">
                    stay with us, we'll appreciate your trust and support as we go through this phase 
                    </div>
                    <div className="text-black-800 text-center text-2xl-6  leading-8 self-center">
                    together. Your well-being means everything to us. 
                    </div>
                <br/>
                <br/>
                    <div className="text-black-800 text-center text-bold text-10xl-6 leading-6 self-center leading-normal">
                    You Literally Have Nothing To Lose
                    </div>
                <br/>
                <br/>
                <div style={{justifyContent: 'center',display: 'flex'}}>
                <Form type={'pregnant'} buttonComponent={(toggleModal) => <CustomButton buttonString="BOOK YOUR FREE ASSESSMENT" onClick={toggleModal} />} />
                </div>
                <br/>
                </div>
            <Testimonial/>
                <Confused color="black" type={'pregnant'} />
            </div >
            <style jsx>{`
                .div-c {
                    display: flex;
                    flex-direction: column;
                }
                .horizonalLine {
                    background-color: rgb(82 139 161);
                }
                .divTitlePre {
                    text-align: center;
                    align-self: center;
                    margin-top: 24px;
                    margin-bottom: 16px;
                    font: 800 45px/44px Inter, -apple-system, Roboto, Helvetica,
                    sans-serif;
                    font-weight: bold;
                }
                .divTitlePre3 {
                    text-align: center;
                    align-self: center;
                    margin-top: 24px;
                    font: 800 45px/35px Inter, -apple-system, Roboto, Helvetica,
                    sans-serif;
                    font-weight: bold;
                }
                @media (max-width: 501px) {
                    .divTitlePre3 {
                        max-width: 100%;
                    }
                }
                .natal1 {
                    background-color: rgb(242, 242, 242);
                }
                @media (max-width: 991px) {
                    .divTitlePre {
                        max-width: 100%;
                    }
                }
                .natalheader {
                margin-top: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                @media (max-width: 991px) {
                    .natalheader {
                          gap: 12px;
                    }
                }
                .boldtxt {
                    font-size: 30px;
                    font-weight: bold;
                    margin-left: 60px;
                    max-width: 500px;
                }
                @media (max-width: 991px) {
                    .boldtxt {
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                    margin-left: 8px;
                    }
                }
                .natalimg {
                    aspect-ratio: 4;
                    object-fit: contain;
                    object-position: center;
                    overflow: hidden;
                }
                .miratext {
                width: 40%;
                }
                @media (max-width: 991px) {
                    .miratext {
                        width: 100%;
                    }
                }
                .miraContainer {
                width: 50%
                }
                @media (max-width: 991px) {
                    .miraContainer {
                        width: 100%;
                    }
                }
                .miraimg {
                    aspect-ratio: 2.0;
                    object-fit: contain;
                    object-position: center;
                    width: 100%;
                    overflow: hidden;
                    }
                @media (max-width: 991px) {
                    .miraimg {
                      aspect-ratio: 1.5;
                        width: 100%;
                        margin-top: 18px;
                        margin-bottom: 18px;
                    }
                }
                @media (max-width: 991px) {
                    .natalimg {
                        width: 100%;
                    }
                }
                .divTitlePre2 {
                    text-align: center;
                    align-self: center;
                    margin-top: 5px;
                    color: rgb(228,168, 155);
                    font: 800 50px/44px Inter, -apple-system, Roboto, Helvetica,
                    sans-serif;
                    font-weight: bold;
                }
                @media (max-width: 991px) {
                    .divTitlePre2 {
                        max-width: 100%;
                    }
                }
                .mira {
                display: flex;
                }
              @media (max-width: 991px) {
                    .mira {
                        display:block;
                    }
                }
                .videogroup {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    // height: 100vh; 
                    margin-bottom: 20px;
                    margin-top: 20px;
                }
                .videogroup iframe {
                    width: 500px;
                    height: 300px;
                }
                @media only screen and (max-width: 767px) {
                    .videogroup iframe {
                        width: 90%;
                        height: 300px; /* Preserve the aspect ratio */
                    }
                }
                .divpre-80 {
                    display: flex;
                    width: 1100px;
                    max-width: 100%;
                    flex-direction: column;
                    align-items: center;
                }
                .divpre-81 {
                    justify-content: center;
                    color: rgb(195 143 132);
                    text-align: center;
                    white-space: nowrap;
                    font: 700 38px/140% Inter, -apple-system, Roboto, Helvetica;
                    sans-serif;
                }
                @media (max-width: 991px) {
                    .divpre-81 {
                        max-width: 100%;
                        white-space: initial;
                        font: 700 30px/140% Inter, -apple-system, Roboto, Helvetica
                    }
                }
                .divpre-801 {
                    justify-content: center;
                    color: rgb(228 168 155) ;
                    text-align: center;
                    white-space: nowrap;
                    font: 500 38px/140% Inter, -apple-system, Roboto, Helvetica,
                    sans-serif;
                }
                @media (max-width: 991px) {
                    .divpre-801 {
                        max-width: 100%;
                        white-space: initial;
                    }
                }
                .divpre-801 {
                    justify-content: center;
                    color: rgb(195 143 132);
                    text-align: center;
                    white-space: nowrap;
                    font: 700 38px/140% Inter, -apple-system, Roboto, Helvetica,
                    sans-serif;
                }
                @media (max-width: 991px) {
                    .divpre-801 {
                        max-width: 100%;
                        white-space: initial;
                    }
                }
                .divpre-79 {
                    justify-content: center;
                    align-items: center;
                    align-self: stretch;
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    // margin: 40px 0px 0px 0px;
                    // padding: 48px 0px 40px;
                }
                @media (max-width: 991px) {
                    .divpre-79 {
                        max-width: 100%;
                    }
                }
                .columnpre-8 {
                    display: flex;
                    flex-direction: column;
                    line-height: normal;
                    width: 35%;
                    margin-left:0px;
                    padding: 0px 16px;
                }
                @media (max-width: 991px) {
                    .columnpre-8 {
                        width: 100%;
                    }
                }
                @media (max-width: 400px) {
                    .columnpre-8 {
                        padding: 0px 0px 0px 0px;
                    }
                }
                .divpre-84 {
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                    align-items: center;
                }
                @media (max-width: 991px) {
                    .divpre-84 {
                        margin-top: 40px;
                    }
                }
                @media (max-width: 300px) {
                    .divpre-84 {
                        display: auto;
                        margin-top: 40px;
                        width:200px
                    }
                }
                .youtubepre {
                    display: auto;
                    width:370px;
                    height: 300px;
                }
                @media (max-width: 450px) {
                    .youtubepre {
                        display: auto;
                        width:250px;
                        // height: 100px;
                    }
                }
                .divpre-82{ 
                    margin-top: 50px;
                }
                @media (max-width: 991px) {
                    .divpre-82 {
                        max-width: 100%;
                        margin-top: 40px;
                    }
                }
                .divpre-83 {
                    margin-top: 20px;
                    gap: 20px;
                    display: flex;
                }
                @media (max-width: 991px) {
                  .divpre-83 {
                    flex-direction: column;
                    align-items: center;
                    gap: 0px;
                  }
                }
                .div-gympre-but {
                    cursor: pointer;
                    justify-content: center;
                    color: #fff;
                    text-align: center;
                    text-transform: uppercase;
                    white-space: nowrap;
                    align-items: center;
                    border-radius: 2px;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
                    background-color:rgb(101, 170, 195);
                    align-self: center;
                    margin-top: 26px;
                    width: 520px;
                    max-width: 100%;
                    padding: 12px 20px;
                    font: 700 19px/29px Inter, -apple-system, Roboto, Helvetica,
                      sans-serif;
                  }
                  @media (max-width: 991px) {
                    .div-gympre-but {
                      width: 280px;
                      max-width: 100%;
                      white-space: initial;
                    }
                  }
                        .title {
      color: black;
      font-size: 20px;
      font-weight: bold;
    }

    .mobile-line-break {
      display: inline;
    }

    @media (max-width: 600px) {
      .mobile-line-break {
        display: block;
      }
    }
            `}</style>
        </>
    );
};

export default Pregnant;
