
import Header from "../components/header2";
import BodyAnalysis from "../components/bodyAnalysis";
import ThreeAssesments from "../components/threeAssesments";
import PricelessOffers from "../components/pricelessOffers";
import Atheletes from "../components/atheletes";
import AOS from "../components/aos";
import TheyDid from "../components/theydid";
import Confused from "../components/confused";
import React, { useState, useEffect } from 'react';

const Fitness = () => {
  const [imageUrl1, setImageUrl1] = useState('');
  const [imageUrl2, setImageUrl2] = useState('');
  const [imageUrl3, setImageUrl3] = useState('');
  const [imageUrl4, setImageUrl4] = useState('');
  const [imageGardenUrl, setImageGardenUrl] = useState('');
  const [imageYoussefUrl, setImageYoussefUrl] = useState('');
  const [imageGymUrl, setImageGymUrl] = useState('');
  const [imageAssesmentUrl, setImageAssesmentUrl] = useState('');
  const [imageJumpUrl, setImageJumpUrl] = useState('');
  const [imageAllPeopleUrl, setImageAllPeopleUrl] = useState('');
  const [imageGym1Url, setImageGym1Url] = useState('');
  const [imageReviews1Url, setImageReviews1Url] = useState('');
  const [imageCrazyOffer1Url, setImageCrazyOffer1Url] = useState('');
  const [gymDay, setGymDay] = useState(0);
  const [gymHours, setGymHours] = useState(0);
  const [gymMinutes, setGymMinutes] = useState(0);
  const [wholeDate, setWholeDate] = useState(null);
  const [youtubeUrl1, setYoutubeUrl1] = useState('');
  const [youtubeUrl2, setYoutubeUrl2] = useState('');
  const [youtubeUrl3, setYoutubeUrl3] = useState('');
  const [youtubeUrl4, setYoutubeUrl4] = useState('');
  const [youtubeUrl5, setYoutubeUrl5] = useState('');
  const [youtubeUrl6, setYoutubeUrl6] = useState('');
  const [enteredSpot, setEnteredSpot] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl1 = `https://www.aos.fit/api/getalldata`;
        let allData = await fetch(apiUrl1);
        const allDataResult = await allData.json();
        setEnteredSpot(allDataResult['bodySpot'])

        setImageYoussefUrl(`https://www.aos.fit/api/public/${allDataResult['body10']}`)
        setImageGardenUrl(`https://www.aos.fit/api/public/${allDataResult['body1']}`)
        setImageGymUrl(`https://www.aos.fit/api/public/${allDataResult['body11']}`)
        setImageAssesmentUrl(`https://www.aos.fit/api/public/${allDataResult['body8']}`)
        setImageAllPeopleUrl(`https://www.aos.fit/api/public/${allDataResult['body6']}`)
        setImageGym1Url(`https://www.aos.fit/api/public/${allDataResult['body9']}`)
        setImageJumpUrl(`https://www.aos.fit/api/public/${allDataResult['body7']}`)
        setImageUrl1(`https://www.aos.fit/api/public/${allDataResult['body2']}`)
        setImageUrl2(`https://www.aos.fit/api/public/${allDataResult['body3']}`)
        setImageUrl3(`https://www.aos.fit/api/public/${allDataResult['body4']}`)
        setImageUrl4(`https://www.aos.fit/api/public/${allDataResult['body5']}`)
        setImageCrazyOffer1Url(`https://www.aos.fit/api/public/${allDataResult['bodycrazyOffer1']}`)
        setImageReviews1Url(`https://www.aos.fit/api/public/${allDataResult['bodyreviews1']}`)
        setYoutubeUrl1(`${allDataResult['bodyyoutubeUrl1']}`)
        setYoutubeUrl2(`${allDataResult['bodyyoutubeUrl2']}`)
        setYoutubeUrl3(`${allDataResult['bodyyoutubeUrl3']}`)
        setYoutubeUrl4(`${allDataResult['bodyyoutubeUrl4']}`)
        setYoutubeUrl5(`${allDataResult['bodyyoutubeUrl5']}`)
        setYoutubeUrl6(`${allDataResult['bodyyoutubeUrl6']}`)
        setGymDay(allDataResult['bodyDay'])
        setGymHours(allDataResult['bodyHour'])
        setGymMinutes(allDataResult['bodyMinute'])
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  const color = 'blue';
  return (
    <>
      <div className="div-a">
        <Header />
        <BodyAnalysis type={'Body Analysis'} youtubeUrl1={youtubeUrl1} spot={enteredSpot} />
        <PricelessOffers />
        <ThreeAssesments type={'Body Analysis'} img1={imageUrl1} img2={imageUrl2} img3={imageUrl3} />
        <Atheletes type={'Body Analysis'} youtubeUrl3={youtubeUrl3} youtubeUrl4={youtubeUrl4} youtubeUrl5={youtubeUrl5} />
        <AOS img1={imageGardenUrl} img2={imageCrazyOffer1Url} />
        <TheyDid type={'Body Analysis'} img1={imageAllPeopleUrl} img2={imageJumpUrl} img3={imageAssesmentUrl} img4={imageGym1Url} img5={imageYoussefUrl} img6={imageGymUrl} />
        <img
          loading="lazy"
          src={imageReviews1Url}
          className="img-23"
        />
        <br />
        <br />
        <Confused color={color} type={'Body Analysis'} />

      </div>
      <style jsx>{`
        .div-a {
          display: flex;
          flex-direction: column;
        }
        .img-23 {
          aspect-ratio: 1.98;
          object-fit: contain;
          object-position: center;
          width: 1100px;
          overflow: hidden;
          align-self: center;
          margin-top: 40px;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .img-23 {
            max-width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default Fitness;
