
import Form from './form';
const CustomButton = ({ onClick }) => (
  <a className="div-107" onClick={onClick}>START FREE TRIAL</a>
);

const CustomButton2 = ({ onClick, buttonString }) => (
  <button onClick={onClick} className="div-gym-1033">{buttonString}</button>
);

export default function TheyDid({ buttonString, type, img1, img2, img3, img4, img5, img6 }) {
  return (
    <>
      <div className="div-96">
        <div className="div-97">
          <div className="div-99">
            <div className="div-100">THEY DID IT, SO CAN YOU</div>
            <div className="div-101" >
              <div className="div-102">
                <div className="column-13">
                  <img
                    loading="lazy"
                    src={img1}
                    className="img-18"
                  />
                </div>
                <div className="column-13">
                  <img
                    loading="lazy"
                    src={img2}
                    className="img-18"
                  />
                </div>
              </div>
              <div className="div-102">

                <div className="column-13">
                  <img
                    loading="lazy"
                    src={img3}
                    className="img-19"
                  />
                </div>
                <div className="column-13">
                  <img
                    loading="lazy"
                    src={img4}
                    className="img-20"
                  />
                </div>
              </div>
              <div className="div-102">
                <div className="column-13">
                  <img
                    loading="lazy"
                    src={img5}
                    className="img-21"
                  />
                </div>
                <div className="column-13">
                  <img
                    loading="lazy"
                    src={img6}
                    className="img-22"
                  />
                </div>
              </div>
            </div>
            {buttonString === 'true' ? (
              <Form type={type} buttonComponent={(toggleModal) => <CustomButton2 buttonString="BOOK YOUR SPOT FOR FREE" onClick={toggleModal} />} />
            ) : (
              <Form type={type} buttonComponent={(toggleModal) => <CustomButton buttonString="START FREE TRIAL" onClick={toggleModal} />} />
            )}
          </div>
          <br />
        </div>
      </div>

      <style jsx>{`
      .img-18, .img-19, .img-20, .img-21, .img-22 {
        max-width: 100%; /* Set a maximum width for the images */
        max-height: 100%; /* Set a maximum height for the images */
      }
        .div-96 {
            justify-content: center;
            align-items: center;
            background-color: #2d2d2d;
            align-self: stretch;
            display: flex;
            // margin-right: -20px;
            width: 100%;
            flex-direction: column;
            // padding: 51px 0px 0px 40px;
          }
          @media (max-width: 991px) {
            .div-96 {
              max-width: 100%;
            }
          }
          .div-97 {
            display: flex;
            width: 1167px;
            max-width: 100%;
            justify-content: space-between;
            gap: 20px;
          }
          @media (max-width: 991px) {
            .div-97 {
              flex-wrap: wrap;
              justify-content: center;
            }
          }
          .div-98 {
            justify-content: center;
            color: #fff;
            text-align: center;
            align-self: start;
            margin-top: 37px;
            font: 900 30px/100% Font Awesome 5 Free, -apple-system, Roboto,
              Helvetica, sans-serif;
          }
          .div-99 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          @media (max-width: 991px) {
            .div-99 {
              max-width: 100%;
            }
          }
          .div-100 {
            margin-top: 20px;
            justify-content: center;
            color: #fff;
            text-align: center;
            white-space: nowrap;
            font: 700 40px/131% Inter, -apple-system, Roboto, Helvetica,
              sans-serif;
          }
          @media (max-width: 991px) {
            .div-100 {
              max-width: 100%;
              white-space: initial;
            }
          }
          .div-101 {
            // align-self: stretch;
            align-items: center !important;
            justify-content: center !important;
          }
          @media (max-width: 991px) {
            .div-101 {
              // align-items: center !important;
              max-width: 90%;
              margin-top: 40px;
            }
          }
          .div-102 {
            margin-top: 40px;
            gap: 80px;
            display: flex;
            justify-content: center;
          }
          @media (max-width: 991px) {
            .div-102 {
              flex-direction: column;
              align-items: stretch;
              gap: 0px;
            }
          }
          .column-12 {
            display: flex;
            flex-direction: column;
            line-height: normal;
            width: 54%;
            margin-left: 0px;
          }
          @media (max-width: 991px) {
            .column-12 {
              width: 100%;
            }
          }
          .img-17 {
            aspect-ratio: 0.8;
            object-fit: contain;
            object-position: center;
            width: 100%;
            overflow: hidden;
          }
          @media (max-width: 991px) {
            .img-17 {
              margin-top: 40px;
            }
          }
          .column-13 {
            display: flex;
            // flex: 1;
            flex-direction: column;
            line-height: normal;
            width: 35%;
            margin-left: 0px;
          }
          @media (max-width: 991px) {
            .column-13 {
              width: 100%;
            }
          }
          .img-18 {
            aspect-ratio: 0.8;
            object-fit: contain;
            object-position: center;
            width: 100%;
            overflow: hidden;
          }
          @media (max-width: 991px) {
            .img-18 {
              margin-top: 40px;
            }
          }
          .img-19 {
            aspect-ratio: 0.8;
            object-fit: contain;
            object-position: center;
            width: 100%;
            overflow: hidden;
          }
          @media (max-width: 991px) {
            .img-19 {
              margin-top: 40px;
            }
          }
          .img-20 {
            aspect-ratio: 0.8;
            object-fit: contain;
            object-position: center;
            width: 100%;
            overflow: hidden;
          }
          @media (max-width: 991px) {
            .img-20 {
              margin-top: 40px;
            }
          }
          .div-106 {
            gap: 20px;
            display: flex;
          }
          @media (max-width: 991px) {
            .div-106 {
              flex-direction: column;
              align-items: stretch;
              gap: 0px;
            }
          }
          .img-21 {
            aspect-ratio: 0.8;
            object-fit: contain;
            object-position: center;
            width: 100%;
            overflow: hidden;
          }
          @media (max-width: 991px) {
            .img-21 {
              margin-top: 40px;
            }
          }
          .img-22 {
            aspect-ratio: 0.8;
            object-fit: contain;
            object-position: center;
            width: 100%;
            overflow: hidden;
          }
          @media (max-width: 991px) {
            .img-22 {
              margin-top: 40px;
            }
          }
          .div-107 {
            justify-content: center;
            color: #fff;
            
            text-align: center;
            text-transform: uppercase;
            white-space: nowrap;
            align-items: center;
            border-radius: 3px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
            background-color: ${type == 'ramadan' ? 'rgb(255, 110, 0)' : '#004aad;'};
            margin: 40px 0px;
            width: 224px;
            max-width: 100%;
            padding: 10px 0px;
            font: 700 20px/143% Roboto, -apple-system, Roboto, Helvetica,
              sans-serif;
              cursor: pointer;
          }
          @media (max-width: 991px) {
            .div-107 {
              white-space: initial;
              margin-top: 40px;
            }
          }
          .div-gym-1033 {
            cursor: pointer;
            justify-content: center;
            color: #fff;
            text-align: center;
            text-transform: uppercase;
            white-space: nowrap;
            align-items: center;
            border-radius: 3px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
            background-color: ${type == 'ramadan' ? 'rgb(255, 110, 0)' : 'rgb(243, 20, 0);'};
            align-self: center;
            margin-top: 26px;
            margin-bottom: 26px;
            width: 284px;
            max-width: 100%;
            padding: 9px 0px;
            font: 700 16px/29px Inter, -apple-system, Roboto, Helvetica,
              sans-serif;
          }
          @media (max-width: 991px) {
            .div-gym-1033 {
              white-space: initial;
            }
          }
      `}</style>
    </>
  );
}