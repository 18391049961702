import Form from './form';
const CustomButton = ({ onClick, percent }) => (
    <button
        onClick={onClick}
        className="justify-center text-white text-center text-xl font-bold leading-7 uppercase whitespace-nowrap items-center border shadow-sm bg-red-600 self-center w-[284px] max-w-full mt-8 px-5 py-4 rounded border-solid border-black border-opacity-20 hover:cursor-pointer"
    >
        BOOK your {percent || 35}% OFFER
    </button>
);

export default function Gym({ type, percent, allPeople, gym1, assesment, jump, reviews, youtubeUrl1, youtubeUrl2, youtubeUrl3, youtubeUrl4 }) {
    return (
        <div className="flex flex-col px-5">

            <div className="self-center w-[843px] max-w-full mt-10">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[51%] max-md:w-full max-md:ml-0">
                        <img
                            loading="lazy"
                            src={allPeople}
                            className="aspect-[1.11] object-contain object-center w-full overflow-hidden max-md:mt-10"
                        />
                    </div>
                    <div className="flex flex-col items-stretch w-[49%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex grow flex-col mt-1.5 max-md:mt-10">
                            <div className="justify-center text-blue-800 text-center font-bold text-2xl-6 leading-7 self-center">
                                WE BECAME THE DESTINATION FOR ANYONE WHO WANTS TO LOSE WEIGHT
                            </div>
                            <div className="justify-center text-zinc-800 text-center text-base leading-5 self-center  mt-11 max-md:mt-10">
                                AOS, which was established in 2016 by Youssef Salem, an award-winning athlete. The gym knew that
                                it couldn't compete with larger gyms' investment or  equipment, so it focused on marketing its services,
                                specifically its weight loss program. Through social media, AOS created a weight loss program that
                                guaranteed results in 30 days, which attracted 15 people to try it initially. The program proved
                                successful, leading AOS to continue to create and  improve its weight loss programs and become known
                                in the market through thousands of transformations. This weight loss program became AOS's edge over
                                other gyms.
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="self-center w-[848px] max-w-full mt-14 max-md:mt-10">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
                        <img
                            loading="lazy"
                            src={jump}
                            className="aspect-[1.5] object-contain object-center w-full overflow-hidden mt-10 max-md:mt-10"
                        />
                    </div>
                    <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex grow flex-col items-center max-md:mt-10">
                            <div className="justify-center text-blue-800  font-bold text-center text-2xl-6 leading-8 ">
                                WE CREATED OUR OWN CERTIFICATE
                            </div>
                            <div className="justify-center text-zinc-800 text-center text-base leading-5 self-stretch  mt-7">
                                we all know that to build a great entity, you need to   have a great team. This was challenging because it
                                was extremely hard to find coaches of very good caliber in Egypt who possessed the combination of
                                education, representability, and faithfulness in their work. These were the traits that AOS was looking for in
                                our coaches.
                            </div>

                            <div className="justify-center text-zinc-800 text-center text-base leading-5  mt-8">
                                To overcome this problem, the founder of AOS, Youssef, worked for three years from 2016 to 2018 to
                                create AOS's own certificate. The AOS certificate is the  main reason for AOS's success today, as it allows AOS
                                to create very high caliber coaches ensuring a high standard coaching .  AOS has created more than 20
                                coaches to date.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="self-center w-[843px] max-w-full mt-10">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[51%] max-md:w-full max-md:ml-0">
                        <img
                            loading="lazy"
                            srcSet={assesment}
                            className="aspect-[1.54] object-contain object-center w-full overflow-hidden"
                        />
                    </div>
                    <div className="flex flex-col items-stretch w-[49%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex grow flex-col mt-1.5 max-md:mt-10">
                            <div className="justify-center text-blue-800 text-center font-bold text-center   text-2xl-6 leading-8 self-stretch ">
                                TOP NOTCH ASSESSMENT SYSTEM
                            </div>

                            <div className="justify-center text-zinc-800 text-center text-base leading-5 self-stretch  mt-7">
                                AOS have developed it' s own Biomechanical  assessment, where we can identify all muscle
                                imbalances a person may have that could be causing pain and problems. By correcting these imbalances,  we can help alleviate pain and improve overall
                                movement and physical performance
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="self-center w-[843px] max-w-full mt-10">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[51%] max-md:w-full max-md:ml-0">
                        <img
                            loading="lazy"
                            srcSet={gym1}
                            className="aspect-[1.5] object-contain object-center w-full overflow-hidden mt-10"
                        />
                    </div>
                    <div className="flex flex-col items-stretch w-[49%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex grow flex-col mt-1.5 max-md:mt-10">
                            <div className="justify-center text-blue-800 text-center font-bold text-center text-2xl-6 leading-8 self-center  mt-10 max-md:mt-10">
                                TOP OF THE LINE EQUIPMENT
                            </div>
                            <div className="justify-center text-zinc-800 text-center text-base leading-5 self-stretch  mt-7">
                                AOS Gym is fully equipped with Hammer Strength and  Life Fitness machines, which are one of the most
                                expensive and sophisticated machine lines in the gym world. Especially the MTS Line, which allows you to
                                work unilaterally and achieve balanced strength throughout your entire body.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="self-center w-[845px] max-w-full mt-11 max-md:mt-10"> */}
            {/* 
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[51%] max-md:w-full max-md:ml-0">
                        <div className="flex grow flex-col items-stretch max-md:mt-10">
                            <img
                                loading="lazy"
                                srcSet="./assesment.png"
                                className="aspect-[1.54] object-contain object-center w-full overflow-hidden"
                            />
                            <img
                                loading="lazy"
                                srcSet="./gym1.png"
                                className="aspect-[1.5] object-contain object-center w-full overflow-hidden mt-10"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col items-stretch w-[49%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex flex-col mt-1.5 max-md:mt-10">
                            <div className="justify-center text-blue-800 text-center font-bold text-center   text-2xl-6 leading-8 self-stretch ">
                                TOP NOTCH ASSESSMENT SYSTEM
                            </div>

                            <div className="justify-center text-zinc-800 text-center text-base leading-5 self-stretch  mt-7">
                                AOS have developed it' s own Biomechanical  assessment, where we can identify all muscle
                                imbalances a person may have that could be causing pain and problems. By correcting these imbalances,  we can help alleviate pain and improve overall
                                movement and physical performance
                            </div>
                            <div className="justify-center text-blue-800 text-center font-bold text-center text-2xl-6 leading-8 self-center  mt-32 max-md:mt-10">
                                TOP OF THE LINE EQUIPMENT
                            </div>
                            <div className="justify-center text-zinc-800 text-center text-base leading-5 self-stretch  mt-7">
                                AOS Gym is fully equipped with Hammer Strength and  Life Fitness machines, which are one of the most
                                expensive and sophisticated machine lines in the gym world. Especially the MTS Line, which allows you to
                                work unilaterally and achieve balanced strength throughout your entire body.
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* </div> */}
            <Form type={type} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} percent={percent} />} />

            <div className="justify-center text-zinc-800 text-center text-7xl-1 font-bold leading-[52px] self-center  mt-10 max-md:max-w-full max-md:mt-10">
                WHAT OUR ATHELETS HAVE TO SAY ABOUT US
            </div>
            <div className="self-center w-[1100px] max-w-full mt-10">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="justify-center items-stretch  flex-grow flex-col w-full mx-auto max-md:max-w-full max-md:mt-10">
                            <div className=" flex flex-col items-stretch max-md:max-w-full">
                                <div className="flex-col overflow-hidden relative flex min-h-[295px] w-full  max-md:max-w-full">
                                    <iframe
                                        src={youtubeUrl1}
                                        className="w-full h-full"
                                        style={{ minHeight: '300px', minWidth: '362px', width: '100%' }}
                                        allowFullScreen=""
                                        wmode="opaque"
                                        id="fitvid254987"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="justify-center items-stretch  flex grow flex-col w-full mx-auto max-md:max-w-full max-md:mt-10">
                            <div className=" flex flex-col items-stretch max-md:max-w-full">
                                <div className="flex-col overflow-hidden relative flex min-h-[295px] w-full  max-md:max-w-full">
                                    <iframe src={youtubeUrl2}
                                        style={{ minHeight: '250px', minWidth: '362px', width: '100%' }}
                                        className="w-full h-full" allowFullScreen="" wmode="opaque" id="fitvid703407"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="self-center w-[1100px] max-w-full  md:mt-10">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="justify-center items-stretch  flex grow flex-col w-full mx-auto max-md:max-w-full max-md:mt-10">
                            <div className=" flex flex-col w-full items-stretch max-md:max-w-full">
                                <div className="flex-col overflow-hidden relative flex min-h-[295px] w-full  max-md:max-w-full">
                                    <iframe src={youtubeUrl3}
                                        style={{ minHeight: '250px', minWidth: '362px', width: '100%' }}
                                        className="w-full h-full" allowFullScreen="" wmode="opaque" id="fitvid63661"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="justify-center items-stretch  flex grow flex-col w-full mx-auto max-md:max-w-full max-md:mt-10">
                            <div className=" flex flex-col items-stretch max-md:max-w-full">
                                <div className="flex-col overflow-hidden relative flex min-h-[295px] w-full  max-md:max-w-full">
                                    <iframe src={youtubeUrl4}
                                        style={{ minHeight: '250px', minWidth: '362px', width: '100%' }} allowFullScreen="" className="w-full h-full" wmode="opaque" id="fitvid219531"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img
                loading="lazy"
                src={reviews}
                className="aspect-[1.98] object-contain object-center w-[1100px] overflow-hidden self-center max-w-full mt-20 mb-20 max-md:mt-10"
            />
        </div>

    );
}

