import * as React from "react";

export default function RevivalHeader({ color, revivalTitle, revivalProgramName }) {
  if (!color) {
    color = 'rgba(255, 0, 203, 0.85)';
  }
  console.log(revivalTitle);
  return (
    <>
      <div>
        <div className="divTitle1005">
          <div className="container divTitle1005" dangerouslySetInnerHTML={{ __html: revivalTitle }}></div>
        </div>
      </div >
      <div className="divConcept1005">
        <div className="container divConcept1005" dangerouslySetInnerHTML={{ __html: revivalProgramName }}>
        </div>
      </div>
      <style jsx>{`
        .divTitle1005 {
            text-align: center;
            align-self: center;
            font: 500 33px/44px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
            font-weight: bold;
        }
        @media (max-width: 991px) {
            .divTitle1005 {
                max-width: 100%;
            }
        }
        .atag {
            color: ${color};
            text-decoration: none;
        }
        .atag:hover {
                border-bottom: 4px solid  ${color};
        }

        .divConcept {
          align-items: center;
          background-color: var(--www-aos-fit-mine-shaft, #2d2d2d);
          display: flex;
          width: 100%;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .divConcept {
            max-width: 100%;
            padding: 0 0px;
          }
        }
        .divConcept1005 {
            color: var(--www-aos-fit-nero, #fff);
            text-align: center;
            white-space: nowrap;
            align-items: center;
            // align-self: stretch;
            background-color:  ${color};
            width: 100%;
            font: 700 29.5px/42px Inter, -apple-system, Roboto, Helvetica,
              sans-serif;
          }
          @media (max-width: 991px) {
            .divConcept1005 {
              white-space: initial;
              max-width: 100%;
            }
          }
        .divConcept2 {
          color: var(--www-aos-fit-nero, #fff);
          text-align: center;
          white-space: nowrap;
          align-items: center;
          background-color: var(--www-aos-fit-mine-shaft, #2d2d2d);
          width: 1170px;
          max-width: 100%;
          margin-top: 25px;
          padding: 0px 0px;
          font: 500 26px/36px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .divConcept2 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .divConcept3 {
          color: ${color};
          text-align: center;
          white-space: nowrap;
          margin-top: 20px;
          font: 700 18px/29px Inter, -apple-system, Roboto, Helvetica,
            sans-serif;
        }
        @media (max-width: 991px) {
          .divConcept3 {
            max-width: 100%;
            white-space: initial;
          }
        }
        .container {
          width: 100%;
          margin: 0 auto;
        }
        
        @media only screen and (max-width: 767px) {
          .container {
            width: 95%;
          }
        }
      `}</style>
    </>
  );
}


