export default function Story({ youtubeUrl3 }) {
    return (
        <div className="justify-center items-center bg-black self-stretch flex w-full flex-col -mr-5 pt-12 pb-7  max-md:max-w-full">
            <div className="flex w-[1098px] max-w-full flex-col items-center">
                <div className="flex w-[350px] max-w-full items-stretch gap-2.5 max-md:justify-center">
                    <div className="justify-center text-orange-500 text-center text-12xl-9 italic font-bold leading-10">
                        CIRCUIT  GYM
                    </div>
                    <div className="justify-center text-stone-300 text-center text-12xl-9 font-bold leading-10 whitespace-nowrap">
                        STORY
                    </div>
                </div>
                <div className="self-stretch mt-12 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch bg-black flex flex-col w-full mx-auto max-md:max-w-full max-md:mt-10">
                                <div className="bg-black flex flex-col items-stretch max-md:max-w-full">
                                    <div className="flex-col overflow-hidden relative flex  w-full items-center pb-4 md:min-h-[295px] max-md:max-w-full">
                                        <iframe
                                            src={youtubeUrl3}
                                            allowFullScreen="" wmode="opaque" title="Circuit" id="fitvid136980"
                                            className="w-full h-full"
                                        ></iframe>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="flex flex-col items-center w-6/12 ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex grow flex-col mt-1.5 w-[95%] max-md:max-w-full max-md:mt-10">
                                <a href="#" className="justify-center text-orange-500 text-center text-basee leading-6 self-stretch font-bold  max-md:max-w-full no-underline hover:underline">
                                    THE ONLY GROUP WORKOUT IN THE WORLD ! WHERE YOU CAN LOSE WEIGHT GET FIT AND TONE UP WITHOUT DOING CARDIO OR KILLING
                                    YOURSELF WITH HIGH INTENSITY WORKOUTS
                                </a>
                                <div className="justify-center text-stone-300 text-center text-base leading-6 self-stretch mt-7 max-md:max-w-full">
                                    When we decided to do a gym, we asked ourselves why would people come train at AOS Gym and not any other gym?
                                    The market is very saturated,
                                    and gyms are everywhere. After deep and long analysis, we found out that it’s been centuries that all gyms have the same problems as listed above.
                                    So, we decided to be the first entity to address these problems and create a concept that solves them all.
                                    We believe that this concept will revolutionize the gym industry.
                                </div>

                                <a href="#" className="justify-center text-orange-500 text-center text-basee font-bold leading-6 self-center w mt-6 no-underline hover:underline">
                                    IF YOUR GOAL IS TO
                                </a>
                                <div className="justify-center text-stone-300 text-center text-base leading-6 self-center whitespace-nowrap mt-7">
                                    Build muscles
                                </div>
                                <div className="justify-center text-stone-300 text-center text-base leading-6 self-center whitespace-nowrap mt-1">
                                    Get toned and shredded
                                </div>
                                <div className="justify-center text-stone-300 text-center text-base leading-6 self-center whitespace-nowrap mt-1">
                                    Lose fats without HIIT & BURPEES
                                </div>
                                <div className="justify-center text-stone-300 text-center text-base leading-6 self-center ">
                                    Be assisted and have follow up by professional coaches
                                </div>
                                <a href="#" className="justify-center text-orange-500 text-center text-basee font-bold leading-6 self-center whitespace-nowrap mt-6 no-underline hover:underline">
                                    Then, this your go-to program
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


