export default function FitnessEntity({ youssef, gym }) {
    return (
        <section className="flex-col justify-center items-center overflow-hidden self-stretch relative flex min-h-[579px] w-full -mr-5 mt-10 pt-7 pb-5 max-md:max-w-full">
            <img loading="lazy" src="./backgroundaos.jpg"
                className="absolute z-[-1] h-full w-full object-cover object-center inset-0" alt="Background Image" />
            <div className="relative flex w-[1082px] max-w-full flex-col items-stretch" >
                <a
                    href="#"
                    className="justify-center text-blue-700 text-center text-12xl-9 font-bold leading-10 max-md:max-w-full no-underline hover:underline hover:cursor-pointer "
                >
                    How did A fitness entity with nothing more than a pull-up bar, in a place with ZERO flow of customers was able to become the most powerful fitness entity in Sheikh Zayed?
                </a>



                <div className="mt-16 p-2.5 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[47%] max-md:w-full max-md:ml-0">
                            <img loading="lazy"
                                src={youssef}
                                className="aspect-[1.34] object-contain object-center w-full overflow-hidden grow max-md:max-w-full max-md:mt-10" alt="Image 1" />
                        </div>
                        <div className="flex flex-col items-stretch w-[53%] ml-5 max-md:w-full max-md:ml-0">
                            <img loading="lazy"
                                src={gym}
                                className="aspect-[1.5] object-contain object-center w-full overflow-hidden grow max-md:max-w-full max-md:mt-10" alt="Image 2" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}