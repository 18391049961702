import Form from './form';
const CustomButton = ({ onClick }) => (
    <button
        onClick={onClick}
        className="justify-center cursor-pointer text-white text-center text-xl font-bold leading-7 uppercase items-center border shadow-sm bg-orange-500 w-[330px] max-w-full mt-14 px-4 py-3 rounded border-solid border-black border-opacity-20  max-md:mt-10"
    >
        BOOK YOUR SPOT FOR FREE
    </button>
);
export default function DidIt({ type, img1, img2, img3, img4, img5, img6 }) {
    return (
        <section className="justify-center items-center bg-black self-stretch flex w-full flex-col -mr-5 pt-12 pb-10 max-md:max-w-full">
            <div className="flex w-[983px] max-w-[90%] flex-col items-center">
                <div className="text-27xl-7 text-orange-500  text-center font-bold  md:[text-xl] max-md:max-w-full">
                    THEY DID IT, SO CAN YOU
                </div>


                <div className="self-stretch mt-11 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                            <img
                                loading="lazy"
                                src={img1}
                                className="aspect-[0.8] object-contain object-center w-full overflow-hidden grow max-md:mt-10"
                                alt=""
                            />
                        </div>
                        <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                            <img
                                loading="lazy"
                                src={img2}
                                className="aspect-[0.8] object-contain object-center w-full overflow-hidden grow max-md:mt-10"
                                alt=""
                            />
                        </div>
                        <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                            <img
                                loading="lazy"
                                src={img3}
                                className="aspect-[0.8] object-contain object-center w-full overflow-hidden grow max-md:mt-10"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div className="self-stretch mt-10 max-md:max-w-full">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                            <img
                                loading="lazy"
                                src={img4}
                                className="aspect-[0.8] object-contain object-center w-full overflow-hidden grow max-md:mt-10"
                                alt=""
                            />
                        </div>
                        <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                            <img
                                loading="lazy"
                                src={img5}
                                className="aspect-[0.8] object-contain object-center w-full overflow-hidden grow max-md:mt-10"
                                alt=""
                            />
                        </div>
                        <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                            <img
                                loading="lazy"
                                src={img5}
                                className="aspect-[0.8] object-contain object-center w-full overflow-hidden grow max-md:mt-10"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <Form type={type} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
            </div>
        </section>
    );
}