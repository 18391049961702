
import Header from "../components/header2";
import CircuitHeader from "../components/circuit";
import Confused from "../components/confused";
import HowItWorks from "../components/howItWorks";
import DidIt from "../components/didIt";
import FreeTrial from '../components/freeTrial';
import Community from '../components/community'
import Story from "../components/story";
import Say from "../components/say";
import { FAQSection } from "../components/faq"
import CrazyOffer from "../components/crazyOffer2";
import FitnessComponent from "../components/fintnessComponent"
import React, { useState, useEffect } from 'react';
const Circuit = () => {
  const [imageUrl1, setImageUrl1] = useState('');
  const [imageUrl2, setImageUrl2] = useState('');
  const [imageUrl3, setImageUrl3] = useState('');
  const [imageUrl4, setImageUrl4] = useState('');
  const [imageGardenUrl, setImageGardenUrl] = useState('');
  const [imageYoussefUrl, setImageYoussefUrl] = useState('');
  const [imageGymUrl, setImageGymUrl] = useState('');
  const [imageAssesmentUrl, setImageAssesmentUrl] = useState('');
  const [imageJumpUrl, setImageJumpUrl] = useState('');
  const [imageAllPeopleUrl, setImageAllPeopleUrl] = useState('');
  const [imageGym1Url, setImageGym1Url] = useState('');
  const [imageReviews1Url, setImageReviews1Url] = useState('');
  const [imageCrazyOffer1Url, setImageCrazyOffer1Url] = useState('');
  const [gymDay, setGymDay] = useState(0);
  const [gymHours, setGymHours] = useState(0);
  const [gymMinutes, setGymMinutes] = useState(0);
  const [wholeDate, setWholeDate] = useState(null);
  const [youtubeUrl1, setYoutubeUrl1] = useState('');
  const [youtubeUrl2, setYoutubeUrl2] = useState('');
  const [youtubeUrl3, setYoutubeUrl3] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl1 = `https://www.aos.fit/api/getalldata`;
        let allData = await fetch(apiUrl1);
        const allDataResult = await allData.json();
        setImageYoussefUrl(`https://www.aos.fit/api/public/${allDataResult['circuit10']}`)
        setImageGardenUrl(`https://www.aos.fit/api/public/${allDataResult['circuit1']}`)
        setImageGymUrl(`https://www.aos.fit/api/public/${allDataResult['circuit11']}`)
        setImageAssesmentUrl(`https://www.aos.fit/api/public/${allDataResult['circuit8']}`)
        setImageAllPeopleUrl(`https://www.aos.fit/api/public/${allDataResult['circuit6']}`)
        setImageGym1Url(`https://www.aos.fit/api/public/${allDataResult['circuit9']}`)
        setImageJumpUrl(`https://www.aos.fit/api/public/${allDataResult['circuit7']}`)
        setImageUrl1(`https://www.aos.fit/api/public/${allDataResult['circuit2']}`)
        setImageUrl2(`https://www.aos.fit/api/public/${allDataResult['circuit3']}`)
        setImageUrl3(`https://www.aos.fit/api/public/${allDataResult['circuit4']}`)
        setImageUrl4(`https://www.aos.fit/api/public/${allDataResult['circuit5']}`)
        setImageCrazyOffer1Url(`https://www.aos.fit/api/public/${allDataResult['circuitcrazyOffer1']}`)
        setImageReviews1Url(`https://www.aos.fit/api/public/${allDataResult['circuitreviews1']}`)
        setYoutubeUrl1(`${allDataResult['circuityoutubeUrl1']}`)
        setYoutubeUrl2(`${allDataResult['circuityoutubeUrl2']}`)
        setYoutubeUrl3(`${allDataResult['circuityoutubeUrl3']}`)
        setGymDay(allDataResult['circuitDay'])
        setGymHours(allDataResult['circuitHour'])
        setGymMinutes(allDataResult['circuitMinute'])
        setWholeDate(allDataResult['circuitTimestamp'])
        console.log(allDataResult['circuitTimestamp'], 'circuitTimestamp')
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  let color = 'rgb(249 115 22)'
  return (
    <>
      <div className="div-c">
        <Header />
        <CircuitHeader type={'Circuit'} youtubeUrl1={youtubeUrl1} wholeDate={wholeDate} />
        < HowItWorks />
        <Story youtubeUrl3={youtubeUrl3} />
        <FreeTrial type={'Circuit'} />
        <div className="justify-center items-center bg-black self-stretch flex w-full flex-col -mr-5 max-md:max-w-full">
          <img
            loading="lazy"
            src={imageGardenUrl}
            className="aspect-[1.73] object-contain object-center w-[1000px] overflow-hidden max-w-full mt-10 mb-10"
          />
        </div>
        <Community type={'Circuit'} img1={imageUrl1} img2={imageUrl2} img3={imageUrl3} img4={imageUrl4} />
        <DidIt type={'Circuit'} img1={imageAllPeopleUrl} img2={imageJumpUrl} img3={imageAssesmentUrl} img4={imageGym1Url} img5={imageYoussefUrl} img6={imageGymUrl} />
        <Say type={'Circuit'} youtubeUrl2={youtubeUrl2} />
        <FitnessComponent imageReviews1Url={imageReviews1Url} type={'Circuit'} />
        <CrazyOffer type={'Circuit'} imageCrazyOffer1Url={imageCrazyOffer1Url} />
        <FAQSection type={'Circuit'} />
        <Confused color={color} type={'Circuit'} />
      </div >
      <style jsx>{`
        // .CircuitHeader{
        //   width: 95%;
        //   align-items: center;
        //   align-content: center;
        // }
        // .CircuitHeader2 {
        //   align-items: center;
        //   align-content: center;
        // }
        .div-c {
          // display: flex;
          // flex-direction: column;
          // width: 90%;
        }
      `}</style>
    </>
  );
};

export default Circuit;
